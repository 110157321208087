import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import classes from './PoolingTableComp.module.css';
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { restRequest } from '../../serverAPI/restRequests';
import ConfirmationDialogComp from '../../components/DialogComp/ConfirmationDialogComp';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'vial_barcode', numeric: false, disablePadding: false, label: 'Vial barcode' },
  { id: 'collection_barcode', numeric: false, disablePadding: false, label: 'Collection Barcode' },
  { id: 'species', numeric: false, disablePadding: false, label: 'Species' },
  { id: 'virus_tested', numeric: false, disablePadding: false, label: 'Virus tested' },
  { id: 'virus_result', numeric: false, disablePadding: false, label: 'Result' },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'default'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel className={classes.root} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell key={'delete'} align={'left'} >Delete</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ setPooling, pooling, rowsPerPage = 7 }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('vialBarcode');
  const [page, setPage] = useState(0);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  async function handleRemove(labelId, _id, virus, result) {
    setDeleteConfirmationOpen(true);
    let newData = {
      labelId: labelId,
      vial_id: _id,
      virus: virus,
      result: result === 'Positive'
    }
    setData(newData);
  }
  async function handleYesDelete() {
    await restRequest('deleteTestFromVial', 'put', data);
    let newPoolingList = pooling;
    newPoolingList.splice(data.labelId, 1);
    setPooling(newPoolingList);
    setDeleteConfirmationOpen(false);
    setData({});
  }

  async function handleNoDelete() {
    setDeleteConfirmationOpen(false);
    setData({});
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={pooling.length} />
            <TableBody>
              {stableSort(pooling, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `${index}`;
                  return (
                    <TableRow tabIndex={-1} key={labelId}>
                      <TableCell className={classes.tableCell} align="left" id={labelId} >{row._id}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.collection_barcode}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.name}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.virus}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.result}</TableCell>
                      <TableCell className={classes.delete} align="left">
                        <IconButton size='small' onClick={() => handleRemove(labelId, row._id, row.virus, row.result)}>
                          <DeleteOutlineIcon />
                        </IconButton>
                      </TableCell>
                      <ConfirmationDialogComp message={"Are you sure you want to delete this result?"} open={deleteConfirmationOpen} handleYes={() => handleYesDelete()} handleNo={handleNoDelete} handleClose={() => { setDeleteConfirmationOpen(false); setLoading(false); }} />
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <TablePagination labelRowsPerPage={""} rowsPerPageOptions={[]} component="div" count={pooling.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} />
        </div>
      </Paper>
    </div>
  );
}