import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { restRequest } from '../../serverAPI/restRequests';

export default function AdjustedComboBox({className, testid, name, paramsList, effected, setEffectedList, value, setValue, isDisabled}) {
    
    async function getNewEffectedParamList(affectiveValue) {
        if (effected === "species") {
            if (affectiveValue != null) {
                const response = await restRequest('getAdjustedSpeciesList', 'post', {genus: affectiveValue});
                setEffectedList(response.data);
            }
            else {
                const response = await restRequest('InterestedInsects', 'get', {});
                setEffectedList(response.data.interest_species);           
            }
        }
        else if (effected === "genus") {
            if (affectiveValue != null) {
                const response = await restRequest('getAdjustedGenusList', 'post', {species: affectiveValue});
                setEffectedList(response.data);        
            }
            else {
                const response = await restRequest('InterestedInsects', 'get', {});
                setEffectedList(response.data.interest_genus);
            }
        }
        else if (effected === "type") {
            if (affectiveValue != null) {
                const response = await restRequest('getAdjustedRunsList', 'post', {type: affectiveValue});
                setEffectedList(response.data);        
            }
            else {
                const response = await restRequest('getCompanyRuns', 'get', {});
                setEffectedList(response.data);
            }
        }
    }

    return (
        <Autocomplete 
            data-testid={testid}
            disabled={isDisabled} 
            value={value} 
            onChange={(event, newValue) => {setValue(newValue);
                                            getNewEffectedParamList(newValue)}} 
            options={paramsList} 
            getOptionLabel={(option) => option} 
            className={className}
            renderInput={(params) => <TextField {...params} size='small' label={name} variant="outlined"/> }/>                
    );
}