import TextField from '@material-ui/core/TextField';
import { Typography } from '@material-ui/core';

export default function TextFieldComp({className, value, setValue, label, type, isDisabled, typeOfTextField, required}) {
  
    return (
        <TextField 
            className={className} 
            value={value} 
            onChange={(event) => setValue(event.target.value)} 
            label={<Typography variant="inherit" >{label}</Typography>}
            variant={typeOfTextField} 
            required={required}
            color="secondary"
            type={type}
            disabled={isDisabled}
        />
    );
}