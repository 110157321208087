import React, { useEffect, useState } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { MarkerClusterer, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import Search from './Components/Search';
import Locate from './Components/Locate';
import { InfoWindow } from "@react-google-maps/api";
import mapStyles from './CollectionsMapComp.module.css'

const libraries = ["places"];
const mapContainerStyle = {
    width: "110%",
    marginTop: "1.15%",
    height: "100%",
};
const center = {
    lat: 38.885086,
    lng: -121.969131,
};
const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

export default function MapComp({ selectedSite, setSelectedSite }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBYcFcT4RNo0gFqnYVBRRC8kGBJ2S1SKGI",
        libraries,
    });

    // the collections showing on the map 
    const [markers, setMarkers] = useState(new Map());

    // popUp
    const [showPopup, setShowPopup] = useState(false);

    // for avoiding re-renders we pass the mapRef
    const mapRef = React.useRef();

    async function getCompanyLocation() {
        const response = await restRequest(`getCompanyLocation`, 'get', {});
        mapRef.current.panTo({ lat: response.data.lat, lng: response.data.lng });
    }

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
        getCompanyLocation();
    }, []);

    // pin down when searching address
    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, []);

    async function getCompanySites() {
        const response = await restRequest('getCompanySites', 'get', {});
        const allSites = response.data;
        var allSitesByIds = new Map();
        for (let i = 0; i < allSites.length; i++) {
            allSitesByIds = allSitesByIds.set(allSites[i]._id, allSites[i]);
        }
        setMarkers(allSitesByIds);
    }

    useEffect(() => {
        getCompanySites();
    }, [])

    if (loadError) { return "Error loading maps" };
    if (!isLoaded) { return "Loading maps" };

    const get_icon = (marker) => {
        const selected = selectedSite === marker._id ? '_selected' : '';
        return `/deployed${selected}.svg`
    }
    const optionsImage = {
        imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    };

    let markersArray = Array.from(markers.values());
    return (
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={12} center={center} options={options} onLoad={onMapLoad}>
            <MarkerClusterer options={optionsImage}>
                {(clusterer) =>
                    // gets iterator return array
                    markersArray.map((marker, index) => {
                        let output = [<Marker
                            key={marker._id}
                            optimized={true}
                            clusterer={clusterer}
                            position={{ lat: marker.latitude, lng: marker.longitude }}
                            onClick={() => {
                                if (selectedSite === marker._id) {
                                    setShowPopup(true);
                                } else {
                                    setSelectedSite(marker._id);
                                    setShowPopup(false);
                                }
                            }}
                            icon={{
                                url: get_icon(marker),
                                origin: new window.google.maps.Point(0, 0),
                                anchor: new window.google.maps.Point(15, 15),
                                scaledSize: new window.google.maps.Size(30, 30),
                            }}
                        />];
                        if (showPopup && selectedSite === marker._id) {
                            output.push(
                                <InfoWindow position={{ lat: marker.latitude, lng: marker.longitude }} onCloseClick={() => { setShowPopup(false) }}>
                                    <div>
                                        <h1 className={mapStyles.siteInfo}>Id: {marker._id}</h1>
                                        <h1 className={mapStyles.siteInfo}>Name: {marker.name}</h1>
                                    </div>
                                </InfoWindow>
                            )
                        }
                        return output;
                    })}
            </MarkerClusterer>
            <div className={mapStyles.searchSite}>
                <Search panTo={panTo} />
            </div>
            <Locate className={mapStyles.location} panTo={panTo} />
        </GoogleMap>
    );
}