import React, { useState,useEffect } from 'react'
import Form from '../../components/Form/Form';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useHistory } from 'react-router-dom';
import logo from '../../images/logo.jpg';
import classes from './LoginPage.module.css';
import { restRequest } from '../../serverAPI/restRequests';

export default function LoginPage({ setUser, socket }) {

    const history = useHistory();
    const [errorMessage, setErrorMessage] = useState("");
    const [showAlert, setAlert] = useState(false);
    const [username, setName] = useState('');
    const [password, setPassword] = useState('');

    const onLogin = () => {
        if (username === "" || password === "") {
            setErrorMessage("Name and password are required");
            setAlert(true);
            setTimeout(() => {
                setAlert(false);
            }, 3000);
        }
        else {
            sessionStorage.setItem('socketId', socket.id);
            restRequest('login', 'post', {
                username: username,
                password: password,
                socket_id: socket.id,
            }, 'auth').then(function (response) {
                setUser(username);
                sessionStorage.setItem('token', response.data.access_token);
                sessionStorage.setItem('userName', username)
                if(response.data.company_id ==="6617ace9a8d3180e43112890"){
                    history.replace("roadPath");
                }
                else {
                    history.replace("home");
                }
            }).catch(function (error) {
                setErrorMessage("Name or password are incorrect");
                setAlert(true)
                setTimeout(() => {
                    setAlert(false)
                }, 3000);
            });
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.content}>
                <h2 className={classes.title}>LOGIN</h2>
                <Form name={username} password={password} setName={setName} setPassword={setPassword} validate={onLogin} />
                <img alt="" src={logo} className={classes.logo}></img>
            </div>
            {showAlert &&
                <Alert className={classes.alert} severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                </Alert>
            }
        </div>
    )
}