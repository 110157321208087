import React, { useEffect, useState } from "react";
import { restRequest } from '../../serverAPI/restRequests';
import { GoogleMap, DirectionsService, DirectionsRenderer, Marker, useLoadScript } from '@react-google-maps/api';
// import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import classes from './CollectionsMapComp.module.css'
import { IconButton, Tooltip, CircularProgress } from '@material-ui/core';
import RouteIcon from '@mui/icons-material/Route';
import AddIcon from '@mui/icons-material/Add';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { makeStyles } from '@material-ui/core/styles';
import FileUploadComp from '../../components/DialogComp/FileUploadComp';
import DirectionsPopUp from "../PopUpComp/DirectionsPopUp";
import CenterFocusStrongSharpIcon from '@material-ui/icons/CenterFocusStrongSharp';
import NewLandmarkComp from '../DialogComp/NewLandmarkComp';
import SearchIcon from '@mui/icons-material/Search';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import comboStyles from '../../components/ComboBoxComp/ComboBoxComp.module.css';
import 'jspdf-autotable';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DateRangeDialog from "../DialogComp/DateRangeDialog";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import ContactsIcon from '@mui/icons-material/Contacts';
import * as XLSX from 'xlsx';

const libraries = ["places"];
const mapContainerStyle = {
    width: "99%",
    marginTop: "1.15%",
    height: "100%",
};
const center = {
    lat: 32.1716224,
    lng: 34.9372416,
};
const options = {
    disableDefaultUI: false,
    zoomControl: true,
};

const useStyles = makeStyles({
    tooltip: { fontSize: "1em" }
});

export default function MapComp({ landmarks, setLandmarks, currentArea, checkedLandmarks, setCheckedLandmarks, addressList, clientsNames, setFlagNewLAndmarks, currentUserDate, areas, users, showDirections, setShowDirections }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBYcFcT4RNo0gFqnYVBRRC8kGBJ2S1SKGI",
        libraries,
    });

    const [loading, setLoading] = useState(false);
    const [directionsResponse, setDirectionsResponse] = useState(null);
    const [inputAddress, setInputAddress] = useState("");
    const [openDialogNewLandmark, setOpenDialogNewLandmark] = useState(false);
    const [open, setOpen] = useState(false);
    const [startingPoint, setStartingPoint] = useState(null);
    const [openSearch, setOpenSearch] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [allLandmarks, setAllLandmarks] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };
    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const getIconURL = (landmark) => {
        if (checkedLandmarks.includes(landmark._id)) {
            return '/locationIconSelected.svg';
            ; // URL to the icon when the landmark is checked
        } else {
            return '/locationIcon.svg'; // URL to the default icon
        }
    };

    function zoomToSelected() {
        const selected = landmarks.filter(value => checkedLandmarks.includes(value._id));
        const bounds = new window.google.maps.LatLngBounds();
        selected.map(x => {
            if (x.lat > -89 && x.lat < 89 && x.lng > -179 && x.lng < 179) {
                bounds.extend(new window.google.maps.LatLng(x.lat, x.lng));
            }
        });
        mapRef.current.fitBounds(bounds);
    }
    // for avoiding re-renders we pass the mapRef
    const mapRef = React.useRef();

    async function getCompanyLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const lat = (position.coords.latitude);
                const lng = (position.coords.longitude);
                mapRef.current.panTo({ lat: lat, lng: lng });
            }
        )
    }

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
        getCompanyLocation();
    }, []);

    const onToggleDirections = () => {
        if (showDirections === false) {
            getLocation()
            console.log(getLocation())
            setShowDirections(!showDirections);
        }
        else {
            setShowDirections(!showDirections);
            return () => {
                // Cleanup: Stop watching position when component unmounts
                navigator.geolocation.clearWatch();
            };
        }
    };

    const directionsCallback = (response) => {
        if (directionsResponse == null && response !== null) {
            setDirectionsResponse(response);
        }
    };

    function getDistanceFromLatLonInKm(startingPoint, landmark) {
        const R = 6371; // Radius of the Earth in km
        const dLat = deg2rad(landmark.lat - startingPoint.lat); // deg2rad below
        const dLon = deg2rad(landmark.lng - startingPoint.lng);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(startingPoint.lat)) * Math.cos(deg2rad(landmark.lat)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km
        return d;
    }

    // Function to convert degrees to radians
    function deg2rad(deg) {
        return deg * (Math.PI / 180);
    }

    const getLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const lat = (position.coords.latitude);
                    const lng = (position.coords.longitude);
                    let distance = getDistanceFromLatLonInKm({ lat: lat, lng: lng }, landmarks[0]);
                    if (distance > 250) {
                        setStartingPoint(null);
                    }
                    else {
                        setStartingPoint({ lat: lat, lng: lng });
                    }
                },
                (error) => {
                    console.error("Error getting geolocation:", error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    function addTocheckedLandmarks(_id) {
        const selectedIndex = checkedLandmarks.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedLandmarks, _id);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedLandmarks.slice(1));
        }
        else if (selectedIndex === checkedLandmarks.length - 1) {
            newSelected = newSelected.concat(checkedLandmarks.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                checkedLandmarks.slice(0, selectedIndex),
                checkedLandmarks.slice(selectedIndex + 1),
            );
        }
        setCheckedLandmarks(newSelected);
    }

    async function downloadSurveyResult() {
        let res = await restRequest('getSurveyResult', 'get');
        console.log(res);
    
        const wb = XLSX.utils.book_new();
    
        const wsData = [
            ['Name', 'Address', 'Date', 'Rate', 'Comments']
        ];
    
        // Flatten the array and map the data correctly
        res.data.flat().forEach(([name, address, surveyRes]) => {
            // Format surveyRes to be individual rows
            if (surveyRes !== null && surveyRes.length > 0) {
                surveyRes.forEach(s => {
                    wsData.push([name, address, s.date, s.rate, s.comments]);
                });
            }
        });
    
        const ws = XLSX.utils.aoa_to_sheet(wsData);
    
        XLSX.utils.book_append_sheet(wb, ws, 'Survey Result');
    
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    
        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }
    
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
    
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'surveyResults.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);  // Clean up the link element
    }
    
    async function downloadAllClients() {
        let response = await restRequest('getLandmarks', 'post', { areaName: 'All Areas' });
        const data = response.data;
        const wb = XLSX.utils.book_new();

        const wsData = [
            ['Active', 'Address', 'Cell Phone', 'Client Name', 'Last Date', 'Email', 'Rolls Number'] // Header row
        ];

        data.landMarks.forEach((landmark) => {
            const {
                active,
                address,
                cellPhone,
                clientName,
                dates,
                email,
                rollsNumber
            } = landmark;

            const lastDate = dates.length > 0 ? dates[dates.length - 1] : '';

            wsData.push([
                active,
                address,
                cellPhone,
                clientName,
                lastDate,
                email,
                rollsNumber
            ]);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);

        XLSX.utils.book_append_sheet(wb, ws, 'Landmarks');

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    }

    const generatePDF = async () => {
        const workbook = XLSX.utils.book_new();
        const worksheetData = [
            ['נקודה מספר', 'כתובת', 'פרטי לקוח', 'מספר מבחנות', 'הערות']
        ];

        directionsResponse.routes[0].legs.forEach((leg, legIndex) => {
            if (legIndex < landmarks.length) {
                let index = directionsResponse.routes[0].waypoint_order[legIndex];
                console.log(index, landmarks[index]);

                const rowData = [
                    `${legIndex + 1}`,
                    `${landmarks[index].address}`,
                    `${landmarks[index].clientName} טלפון - ${landmarks[index].cellPhone}`,
                    `${landmarks[index].rollsNumber}`,
                    landmarks[index].notes ? `${landmarks[index].notes}` : ''
                ];

                worksheetData.push(rowData);
            }
        });

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Directions');

        // Create Excel file
        const workbookBinary = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        }

        const fileName = 'directions.xlsx';
        const blob = new Blob([s2ab(workbookBinary)], { type: 'application/octet-stream' });

        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName); // Set the download file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link); // Clean up
    };

    function handleClickSearch() {
        if (openSearch === null) { setOpenSearch('address'); }
        else {
            if (openSearch === 'address') { setOpenSearch('name'); }
            else setOpenSearch(null);
        }
    }

    const toolTipClasses = useStyles();

    useEffect(() => {
        if (currentUserDate === null) {
            setShowDirections(false);
        }
    }, [currentUserDate])

    useEffect(() => {
        setDirectionsResponse(null);
        setCheckedLandmarks([]);
        setStartingPoint(null);
        setShowDirections(false);
    }, [landmarks])

    useEffect(() => {
        if (selectedAddress !== null) {
            if (allLandmarks.length > 0) {
                let landmark = allLandmarks.filter(l => l.address === selectedAddress);
                setLandmarks(landmark);
            }
            else {
                let landmark = landmarks.filter(l => l.address === selectedAddress);
                setLandmarks(landmark);
                setAllLandmarks(landmarks);
            }

        }
        else {
            if (landmarks.length === 1) {
                setLandmarks(allLandmarks);
            }
        }
    }, [selectedAddress]);

    useEffect(() => {
        if (selectedClient !== null) {
            if (allLandmarks.length > 0) {
                let landmark = allLandmarks.filter(l => l.clientName === selectedClient);
                setLandmarks(landmark);
            }
            else {
                let landmark = landmarks.filter(l => l.clientName === selectedClient);
                setLandmarks(landmark);
                setAllLandmarks(landmarks);
            }

        }
        else {
            if (landmarks.length === 1) {
                setLandmarks(allLandmarks);
            }
        }
    }, [selectedClient]);

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "Loading maps";

    return (
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={12} center={center} options={options} onLoad={onMapLoad}>
            {!showDirections && landmarks.map((landmark, index) => (
                <Marker
                    key={index}
                    position={landmark}
                    icon={{
                        url: (getIconURL(landmark)),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15),
                        scaledSize: new window.google.maps.Size(30, 30),
                    }}
                    onClick={() =>
                        addTocheckedLandmarks(landmark._id)
                    }
                />
            ))}
            {landmarks.length > 0 && showDirections && startingPoint && (
                <DirectionsService
                    options={{
                        origin: startingPoint,
                        destination: landmarks[landmarks.length - 1],
                        waypoints: landmarks.map(waypoint => ({ location: waypoint })),
                        travelMode: 'DRIVING',
                        optimizeWaypoints: true,  // Optimize the order of waypoints
                        language: 'he'
                    }}
                    callback={directionsCallback}
                />
            )}
            {landmarks.length > 0 && showDirections && directionsResponse && startingPoint && (
                <DirectionsRenderer
                    directions={{
                        ...directionsResponse,
                        routes: [
                            {
                                ...directionsResponse.routes[0],
                                legs: directionsResponse.routes[0].legs.slice(0, -1), // Remove the last leg (fake destination)
                            },
                        ],
                    }}></DirectionsRenderer>
            )}
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Search client address'} placement="left">
                <IconButton onClick={handleClickSearch} className={classes.searchIcon} disabled={landmarks.length === 0}>
                    <SearchIcon />
                </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Survey Results File'} placement="left">
                <IconButton onClick={downloadSurveyResult} className={classes.survey} >
                    <SystemUpdateAltIcon />
                </IconButton>
            </Tooltip>
            {openSearch === 'address' &&
                <div className={comboStyles.searchCombo}>
                    <ComboBoxComp name={"Address"} paramsList={addressList} value={selectedAddress} setValue={setSelectedAddress} />
                </div>
            }
            {openSearch === 'name' &&
                <div className={comboStyles.searchCombo}>
                    <ComboBoxComp name={"Client Name"} paramsList={clientsNames} value={selectedClient} setValue={setSelectedClient} />
                </div>
            }
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={showDirections ? "Hide Directions" : "Show Directions"}>
                <span className={classes.routeIcon}>
                    <IconButton onClick={onToggleDirections} disabled={currentUserDate === null}>
                        <RouteIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Upload File'} placement="right">
                <IconButton onClick={() => setOpen(true)} className={classes.landMarkIcon}>
                    <DriveFolderUploadIcon />
                </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Zoom to marked landmarks' placement="right">
                <IconButton onClick={zoomToSelected} className={classes.zoomSelected} disabled={checkedLandmarks.length === 0}>
                    <CenterFocusStrongSharpIcon />
                </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Add new landmark'} placement="right">
                <IconButton className={classes.newLandmark} onClick={() => setOpenDialogNewLandmark(true)}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title="Download File By Dates" placement="right">
                <IconButton onClick={() => setDialogOpen(!dialogOpen)} className={classes.download}>
                    <DateRangeIcon />
                </IconButton>
            </Tooltip>
            {dialogOpen && <DateRangeDialog className={classes.date} />}
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Download Directions File'} placement="right">
                <IconButton onClick={generatePDF} className={classes.downloadDirections} disabled={!showDirections}>
                    <FileDownloadIcon />
                </IconButton>
            </Tooltip>
            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Download All Clients File'} placement="right">
                <IconButton onClick={downloadAllClients} className={classes.downloadClients}>
                    <ContactsIcon />
                </IconButton>
            </Tooltip>
            <NewLandmarkComp open={openDialogNewLandmark} handleClose={() => setOpenDialogNewLandmark(false)} areaList={areas} setFlagNewLAndmarks={setFlagNewLAndmarks} users={users} />
            {directionsResponse !== null && <DirectionsPopUp directionsResponse={directionsResponse} landmarks={landmarks} />}
            <FileUploadComp open={open} setOpen={setOpen} setFlagNewLAndmarks={setFlagNewLAndmarks} areas={areas} users={users} />
        </GoogleMap>
    );
}
