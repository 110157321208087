import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function ComboBox({className, name, paramsList, value, setValue, isDisabled}) {

    return (
        <Autocomplete 
            className={className}
            disabled={isDisabled} 
            value={value}
            onChange={(event, newValue) => {setValue(newValue);}} 
            options={paramsList}
            getOptionLabel={(option) => option} 
            renderInput={(params) => <TextField InputProps={{ style: { fontSize: 300 } }} {...params} size='small' label={name} variant="outlined"/> }/>                
    );
}