import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { Alert } from '@material-ui/lab';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from "@material-ui/core";
import DateComp from '../../components/DateComp/DateComp';
import InsectsTableComp from '../../components/Tables/InsectsTableComp';
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import AdjustedComboBoxComp from '../../components/ComboBoxComp/AdjustedComboBoxComp';
import TextFieldComp from '../../components/TextFieldComp/TextFieldComp';
import ConfirmationPopupComp from '../../components/DialogComp/ConfirmationDialogComp'
import classes from '../ManualUploadPage/ManualUploadPage.module.css';

export default function TreatmentPage() {
    const [trapEvent_id, setTrapEvent_id] = useState(null);
    const [sex, setSex] = useState(null);
    const [genus, setGenus] = useState(null);
    const [species, setSpecies] = useState(null);
    const [amount, setAmount] = useState(null);

    const [trapsEvents_ids, setTrapsEvents_ids] = useState([]);
    const [genusList, setGenusList] = useState([]);
    const [speciesList, setSpeciesList] = useState([]);
    const [pickupDate, setPickupDate] = useState(new Date());

    const [insectsInvList, setInsectsInvList] = useState([]);
    const [checkedInsect, setCheckedInsect] = useState(null);

    const [ConfirmationOpen, setConfirmationOpen] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    function handleSex(val) {
        setSex(val);
    }

    function handleGenus(val) {
        setGenus(val);
    }

    function handleSpecies(val) {
        setSpecies(val);
    }

    function insectOnTheInvList() {
        for (let i = 0; i < insectsInvList.length; i++) {
            if (insectsInvList[i].genus === genus && insectsInvList[i].species === species && insectsInvList[i].sex === sex) {
                return true;
            }
        }
        return false;
    }

    function addInsect() {
        if (!insectOnTheInvList()) {
            let newInvList = insectsInvList;
            newInvList.push({ _id: insectsInvList.length, genus: genus, species: species, sex: sex, amount: amount });
            setInsectsInvList(newInvList);
            setGenus(null);
            setSpecies(null);
            setSex(null);
            setAmount("");
        }
        else {
            setConfirmationOpen(true);
        }
        getInterestedInsects();
    }

    async function handleSave() {
        await restRequest('AddManualInsects', 'post', { insectsList: insectsInvList, trapEvent_id: trapEvent_id, pickupDate: pickupDate });

        setSuccessAlert(true);
        setTimeout(() => {
            setSuccessAlert(false)
        }, 3000);
    }

    function handleEdit() {
        setGenus(checkedInsect.genus);
        setSpecies(checkedInsect.species);
        setSex(checkedInsect.sex);
        setAmount(checkedInsect.amount);
    }

    // user clicked no on the overwrite insect confirmation popup
    function handleNoOverwrite() {
        setConfirmationOpen(false);
    }

    // user clicked yes on the overwrite insect confirmation popup
    function handleYesOverwrite() {
        setConfirmationOpen(false);
        let tmpInvList = [];
        for (let i = 0; i < insectsInvList.length; i++) {
            if (insectsInvList[i].genus !== genus || insectsInvList[i].species !== species || insectsInvList[i].sex !== sex) {
                tmpInvList.push(insectsInvList[i]);
            }
        }
        tmpInvList.push({ _id: insectsInvList.length, genus: genus, species: species, sex: sex, amount: amount });
        setInsectsInvList(tmpInvList);
    }

    // get the params of the genus and species dropdown list
    async function getInterestedInsects() {
        const response = await restRequest('InterestedInsects', 'get', {});
        setGenusList(response.data.interest_genus);
        setSpeciesList(response.data.interest_species);
    }

    async function getTrapEventsIds() {
        const response = await restRequest('getTrapsEventsIdsOfCompany', 'get', {});
        setTrapsEvents_ids(response.data);
    }

    useEffect(() => {
        getInterestedInsects();
        getTrapEventsIds();
    }, []);

    return (
        <div>
            <div className={classes.frame}>
                <ComboBoxComp className={classes.trapEventId} name={"Trap event id:"} paramsList={trapsEvents_ids} value={trapEvent_id} setValue={setTrapEvent_id}/>
            </div>
            {trapEvent_id !== null &&
                <div>
                    <div className={classes.identificationFrame}>
                        <div className={classes.identification}>
                            <AdjustedComboBoxComp name={"Genus:"} paramsList={genusList} effected={"species"} setEffectedList={setSpeciesList} value={genus} setValue={handleGenus} isDisabled={trapEvent_id === null} />
                        </div>
                        <div className={classes.identification}>
                            <AdjustedComboBoxComp name={"Species:"} paramsList={speciesList} effected={"genus"} setEffectedList={setGenusList} value={species} setValue={handleSpecies} isDisabled={trapEvent_id === null} />
                        </div>
                        <div className={classes.identification}>
                            <ComboBoxComp name={"Sex:"} paramsList={["Female", "Male", "Unidentified"]} value={sex} setValue={handleSex} isDisabled={trapEvent_id === null} />
                        </div>
                        <div className={classes.insectsNum}>
                            <TextFieldComp value={amount} setValue={setAmount} label={"Amount:"} isDisabled={trapEvent_id === null} required={true} />
                        </div>
                        <div>
                            <IconButton className={classes.addInsects} onClick={addInsect} disabled={genus === null || species === null || sex === null || amount === null}>
                                <AddIcon />
                            </IconButton>
                        </div>
                    </div>
                    {insectsInvList && insectsInvList.length > 0 &&
                        <div className={classes.newInfo}>
                            <InsectsTableComp checkedInsect={checkedInsect} setCheckedInsect={setCheckedInsect} insects={insectsInvList} />
                            <DateComp className={classes.pickupDate} style="display: grid;" name={"PickUp Date"} value={pickupDate} setValue={setPickupDate} />
                        </div>
                    }
                    <div className={classes.wraper}>
                        <div className={classes.editFrame}>
                            <ButtonComp className={classes.edit} name={"Edit"} action={handleEdit} isDisabled={checkedInsect === null} />
                        </div>
                        <div className={classes.saveFrame}>
                            <ButtonComp className={classes.save} name={"Save"} action={handleSave} isDisabled={insectsInvList.length === 0} />
                        </div>
                    </div>
                </div>
            }
            <ConfirmationPopupComp message={"This insect already exist, do you want to overwrite it?"} open={ConfirmationOpen} handleYes={handleYesOverwrite} handleNo={handleNoOverwrite} handleClose={() => setConfirmationOpen(false)} />
            {successAlert &&
                <Alert severity="success">Insects saved successfully!</Alert>
            }
        </div>
    )
}