import React from 'react';
import { InfoWindow } from "@react-google-maps/api";
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import buttonStyles from '../../components/ButtonComp/ButtonComp.module.css';
import classes from './SitePopUpComp.module.css';

export default function SitePopUpComp({ selectedSite, setSelectedSite, filteredSites, setFilteredSites, setAddSite }) {

    function addSite() {
        setAddSite(selectedSite);
    }

    // check if the site is already at the sites table
    function onTable() {
        for (let i = 0; i < filteredSites.length; i++) {
            if (filteredSites[i]._id === selectedSite._id)
                return true;
        }
        return false;
    }

    return (
        selectedSite ?
            <InfoWindow position={{ lat: selectedSite.latitude, lng: selectedSite.longitude }} onCloseClick={() => { setSelectedSite(null); }}>
                <div>
                    <h1 className={classes.siteInfo}>Site id: {selectedSite.mapVision_id ? selectedSite.mapVision_id : selectedSite._id} </h1>
                    <h1 className={classes.siteInfo}>Site name: {selectedSite.name} </h1>
                    <h1 className={classes.siteInfo}>Position: {selectedSite.position_type} </h1>
                    <ButtonComp className={buttonStyles.addSite} name={"Add Site"} action={addSite} isDisabled={onTable()} />
                </div>
            </InfoWindow>
            : <div></div>
    )
}