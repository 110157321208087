import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import Button from '@material-ui/core/Button';
import { CircularProgress, DialogTitle } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { Alert, AlertTitle } from '@material-ui/lab';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import classes from '../DialogComp/NewSiteDialogComp.module.css';
import Grid from '@material-ui/core/Grid';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

export default function NewSiteDialogComp({ open, handleClose, siteLong, siteLat, companyId, setSiteId, setNewMapVisionId, setNewSiteFlag, setNewSiteName, setNewSiteAddress, setNewSiteDirections }) {
    const [siteName, setSiteName] = useState(null);
    const [siteAddress, setSiteAddress] = useState(null);
    const [siteDirections, setSiteDirections] = useState(null);
    const [mapVisionId, setMapVisionId] = useState(null);
    const [siteLongitude, setSiteLongitude] = useState(null);
    const [siteLatitude, setSiteLatitude] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorAlert, setErrorAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    
    async function handleCreate() {
        if (siteName === null || siteName === '' || siteName === ' ') {
            setErrorMessage("Name is required");
            setErrorAlert(true);
            setTimeout(() => {
                setErrorAlert(false);
            }, 3000);
        }
        else {
            setLoading(true);
            const data = {
                name: siteName,
                long: siteLong,
                lat: siteLat,
                address: siteAddress,
                directions: siteDirections,
                mapVision_id: mapVisionId
            }
            const response = await restRequest('createSite', 'put', data);
            setSiteId(response.data);
            setNewSiteName(siteName);
            setNewSiteAddress(siteAddress);
            setNewMapVisionId(mapVisionId)
            setNewSiteDirections(siteDirections);
            setLoading(false);
            handleClose();
            setNewSiteFlag(true);
        }
    }

    useEffect(() => {
        setSiteName(null);
        setSiteAddress(null);
        setSiteDirections(null);
        setMapVisionId(null);
    }, [open])

    return (
        <div>
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                <DialogTitle>Add Site</DialogTitle>
                <DialogContent>
                    <Grid container spacing={0}>
                        <Grid item xs={5}>
                            <TextFieldComp value={siteName} setValue={setSiteName} label={"Site Name"} required={true} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextFieldComp value={siteAddress} setValue={setSiteAddress} label={"Site Address"} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextFieldComp value={siteLat} setValue={setSiteLatitude} label={"Site Latitude"} isDisabled={true} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextFieldComp value={siteDirections} setValue={setSiteDirections} label={"Site Directions"} />
                        </Grid>
                        <Grid item xs={5} >
                            <TextFieldComp value={siteLong} setValue={setSiteLongitude} label={"Site Longitude"} isDisabled={true} />
                        </Grid>
                        {(companyId == '62a073' || companyId == '646cae3c89d8257530a11eda') &&
                            <Grid item xs={5}>
                                <TextFieldComp value={mapVisionId} setValue={setMapVisionId} label={"MapVision Id"} />
                            </Grid>
                        }
                    </Grid>

                </DialogContent>
                {errorAlert &&
                    <Alert className={classes.alert} severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {errorMessage}
                    </Alert>
                }
                <DialogActions>
                    {loading &&
                        <CircularProgress className={classes.spinner} size={30} disableShrink />
                    }
                    <Button autoFocus onClick={handleCreate} color="primary">
                        Create
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}