import React, { useEffect, useState, useCallback, useRef } from "react";
import { restRequest } from '../../serverAPI/restRequests';
import { Alert } from '@material-ui/lab';
import { useHistory, useLocation } from 'react-router-dom';
import 'jspdf-autotable';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import { IconButton, CircularProgress, Tooltip } from "@material-ui/core";
import { GetInsectDto, CreateIdentificationDto, FilterParamsDto } from "../../serverAPI/serverModels";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import AdjustedComboBoxComp from '../../components/ComboBoxComp/AdjustedComboBoxComp';
import CheckBoxComp from '../../components/CheckBoxComp/CheckBoxComp';
import PictureComp from '../../components/PictureComp/PictureComp';
import GuideComp from '../../components/PictureComp/GuideComp';
import PdfComp from '../../components/PdfComp/PdfFileComp';
import ConfirmationDialogComp from '../../components/DialogComp/ConfirmationDialogComp';
import InfoDialogComp from '../../components/DialogComp/InfoDialogComp';
import ButtonComp from "../../components/ButtonComp/ButtonComp";
import SwitchComp from '../../components/SwitchComp/SwitchComp';
import comboStyles from '../../components/ComboBoxComp/ComboBoxComp.module.css';
import adjustedComboBoxStyles from '../../components/ComboBoxComp/AdjustedComboBoxComp.module.css';
import buttonStyles from '../../components/ButtonComp/ButtonComp.module.css';
import classes from './IdentificationPage.module.css';
import { makeStyles } from '@material-ui/core/styles';
import { FollowTheSigns } from "@mui/icons-material";

export default function IdentificationPage({ checkedCollections, setCheckedCollections, filterParams, socket, setFilterParams, setCollectionOfInsect, StringToDate }) {
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [kind, setKind] = useState(null);
    const [kindsList, setKindsList] = useState([]);
    const [isGuide, setIsGuide] = useState(false);
    const [failAlertKind, setFailAlertKind] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);
    const [partialAlert, setPartialAlert] = useState(false);
    const [currentCollection, setCurrentCollection] = useState('');
    const [moveToNextCollection, setMoveToNextCollection] = useState(false);
    const [interestedGenuses, setInterestedGenuses] = useState([]);
    const [interestedSpecies, setInterestedSpecies] = useState([]);
    const [usersLoggedIn, setUsersLoggedIn] = useState([]);
    const [genusList, setGenusList] = useState([]);
    const [speciesList, setSpeciesList] = useState([]);
    const [guidePictures, setGuidePictures] = useState([]);
    const [sex, setSex] = useState('');
    const [genus, setGenus] = useState('');
    const [species, setSpecies] = useState('');
    const [isFed, setIsFed] = useState(false);

    // indicating if the user has entered identification (pressed plus button/started first identification)
    const [userIdentified, setUserIdentified] = useState(false);

    // user's identification
    const [userSex, setUserSex] = useState('');
    const [userGenus, setUserGenus] = useState('');
    const [userSpecies, setUserSpecies] = useState('');
    const [userIsFed, setUserIsFed] = useState(false);
    const [userSecondOpinion, setUserSecondOpinion] = useState(false);
    const [userHead, setUserHead] = useState(false);
    const [userThorax, setUserThorax] = useState(false);
    const [userAbdomen, setUserAbdomen] = useState(false);
    const [userNoise, setUserNoise] = useState(false);
    const [userMultiple, setUserMultiple] = useState(false);
    const [userCantIdentify, setUserCantIdentify] = useState(false);
    const [userValidSwitch, setUserValidSwitch] = useState("Valid");

    const [cantIdentify, setCantIdentify] = useState(false);
    const [secondOpinion, setSecondOpinion] = useState(false);
    const [head, setHead] = useState(false);
    const [thorax, setThorax] = useState(false);
    const [abdomen, setAabdomen] = useState(false);
    const [noise, setNoise] = useState(false);
    const [multiple, setMultiple] = useState(false);
    const [validSwitch, setValidSwitch] = useState("Valid");
    const [tagger, setTagger] = useState("");
    const [timeStamp, setTimeStamp] = useState("");
    const [imagesScales, setImagesScales] = useState([]);

    const [insectImages, setInsectImages] = useState([]);
    const [insectSiteName, setInsectSiteName] = useState("");
    const [insectSiteLat, setInsectSiteLat] = useState(0);
    const [insectSiteLong, setInsectSiteLong] = useState(0);
    const [insectId, setInsectId] = useState("");
    const [insectPlacementDate, setInsectPlacementDate] = useState("");
    const [insectPickupDate, setInsectPickupDate] = useState("");

    const [neededSecondOpinion, setNeededSecondOpinion] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [containsUnverified, setContainUnverified] = useState(false);

    // pointer to the current identification presented on the screen
    const [identificationsPtr, setIdentificationsPtr] = useState(-1);

    const [insectsArr, setInsectsArr] = useState([]);
    const [insectsPtr, setInsectsPtr] = useState(0);

    const [saveConfirmationOpen, setSaveConfirmationOpen] = useState(false);
    const [noInsectsLeftConfirmationOpen, setNoInsectsLeftExitConfirmationOpen] = useState(false);
    const [noInsectsAtAllConfirmationOpen, setNoInsectsAtAllConfirmationOpen] = useState(false);
    const [verifyConfirmationOpen, setVerifyConfirmationOpen] = useState(false);
    const [callbackState, setCallbackState] = useState(null);

    // for the progress bar
    const [insectNumber, setInsectNumber] = useState(0);
    const [totalInsectsNumber, setTotalInsectsNumber] = useState(0);

    const [totalPages, setTotalPages] = useState(0);

    // presenting two images
    const [twoImages, setTwoImages] = useState(false);
    // create pdf loading flag
    const [waitCreatePDF, setWaitCreatePDF] = useState(false);
    const handleKeyPressRef = useRef();
    const tooltipStyles = makeStyles({ tooltip: { fontSize: "1em" } });
    const toolTipClasses = tooltipStyles();

    function getCallbackState() {
        return callbackState;
    }

    function handleGenus(val) {
        if (val === null) {
            setGenus('');
        }
        else {
            setGenus(val);
            setUserGenus(val);
        }
    }

    function handleValidSwich(val) {
        if (val === null) {
            setValidSwitch("Valid");
        }
        else {
            setValidSwitch(val);
            setUserValidSwitch(val);
        }
    }

    function handleSpecies(val) {
        if (val === null) {
            setSpecies('');
        }
        else {
            setSpecies(val);
            setUserSpecies(val);
        }
    }

    function handleSex(val) {
        if (val !== "Female") {
            setIsFed(false);
        }
        if (val === null) {
            setSex('');
        }
        else {
            setSex(val);
            setUserSex(val);
        }
    }

    function handleCantIdentify(event) {
        setSex('');
        setGenus('');
        setSpecies('');
        setSecondOpinion(false);
        setCantIdentify(event.target.checked);
        setUserCantIdentify(event.target.checked);
    };

    function handleSecondOpinion(event) {
        setSecondOpinion(event.target.checked);
        setUserSecondOpinion(event.target.checked);
    }

    function handleFed(event) {
        setIsFed(event.target.checked);
        setUserIsFed(event.target.checked);
    }

    async function sendVerificationToServer() {
        setIsVerified(true);
        await restRequest(`AddVerification?insect_id=${insectsArr[insectsPtr].insect_id}`, 'post', {});
        var newInsectsArr = insectsArr;
        newInsectsArr[insectsPtr].verified = true;
        setInsectsArr(newInsectsArr);
    }

    async function getGuidePictures() {
        const response = await restRequest('getGuidePictures', 'get', {});
        setGuidePictures(response.data);
    }

    async function connectUserToCollection(coll_id) {
        await restRequest('connectUserToCollection', 'post', { socket: socket.id, userName: window.sessionStorage.getItem('userName'), collection_id: coll_id });
    }

    const removeUserFromCollection = async () => {
        try {
            await restRequest('removeUserFromCollection', 'post', {
                socket: socket.id, userName: window.sessionStorage.getItem('userName'),
                collection_id: currentCollection == '' ? checkedCollections[0] : currentCollection
            });
            console.log('User removed from collection');
        } catch (error) {
            console.error('Error removing user from collection:', error);
        }
    };

    async function getKindsNames() {
        const response = await restRequest('getAllKindsNames', 'get', {});
        // setKindsList(response.data);
        setKindsList(['Aedes albopictus', 'Anopheles freeborni', 'Culex pipiens', 'Culex tarsalis'])
    }

    function sendVerificationAllToServer() {
        setVerifyConfirmationOpen(true);
    }

    function secodWithPartial(newIdentification) {
        if (userSecondOpinion && (userSex === '' || userGenus === '' || userSpecies === '') &&
            (userSex !== '' || userGenus !== '' || userSpecies !== '')) {
            newIdentification.sex = '';
            newIdentification.genus = '';
            newIdentification.species = '';
            return true;
        }
        return false;
    }

    async function sendIdentificationToServer(paginationState) {
        try {
            var new_identification = new CreateIdentificationDto(userSpecies, userGenus, userSex, userSecondOpinion, userCantIdentify, userIsFed, (userValidSwitch === "Valid"), userHead, userThorax, userAbdomen, userNoise, userMultiple, window.sessionStorage.getItem('userName'), new Date());
            await restRequest(`AddIdentification?insect_id=${insectsArr[insectsPtr].insect_id}`, 'post', new_identification);

            if (secodWithPartial(new_identification)) {
                setPartialAlert(true);
                setTimeout(() => {
                    setPartialAlert(false)
                }, 3000);
            }
            else {
                setSuccessAlert(true);
                setTimeout(() => {
                    setSuccessAlert(false)
                }, 3000);
            }

            // Creat new identificaion in order to modify the insectsArr
            var newInsectsArr = insectsArr;
            newInsectsArr[insectsPtr].identifications.push(new_identification);
            setInsectsArr(newInsectsArr);

            setInsectNumber(insectNumber + 1);
            // continue according to the paginationState
            paginationHandler(paginationState);
        } catch (error) {
            setFailAlertKind(true);
            setTimeout(() => {
                setFailAlertKind(false)
            }, 3000);
            paginationHandler('NEXT')
        }
    }

    // save the identification of the insect entered by the user
    function saveInsect(paginationState) {
        // modify the collback- the "after" function that has been chosen by the user according to his pagination state
        setCallbackState(paginationState);

        // if the user hasn't identify, continue according to pagination
        if (!userIdentified || (userIdentified && inputEmpty()) || (insectsArr[insectsPtr].identifications.length === 0 && inputEmpty())) {
            paginationHandler(paginationState);
        }

        // if identified and in formar identifications- popup confirmation
        else if (hasNextIdentification()) {
            setSaveConfirmationOpen(true);
        }

        // identified and standing on the identification- save it
        else {
            sendIdentificationToServer(paginationState);
        }
    }

    // doing the action that has been made by the user according to the pagingatin state
    function paginationHandler(paginationState) {
        let newPtr = insectsPtr;
        switch (paginationState) {

            // next insect
            case ('NEXT'):
                newPtr = insectsPtr + 1;
                setInsectsPtr(newPtr);
                if (newPtr === insectsArr.length) {
                    getInsect();
                }
                else {
                    setLoading(false);
                }
                localStorage.setItem('insectsPtr', JSON.stringify(newPtr));
                setIdentificationsPtr(-1);
                break;

            // formar insect
            case ('PREV'):
                setIdentificationsPtr(-1);
                localStorage.setItem('insectsPtr', JSON.stringify(insectsPtr - 1));
                setInsectsPtr(insectsPtr - 1);
                setLoading(false);
                break;

            // remain at the same insect
            case ('STAY'):
                setLoading(false);
                break;

            // last unidentified insect
            case ('LAST'):
                let lastIndex = null;
                // go over the formar insects array
                for (let i = 0; i < insectsPtr; i++) {
                    if (!isIdentified(i)) {
                        lastIndex = i;
                        break;
                    }
                }
                setIdentificationsPtr(-1);
                setInsectsPtr(lastIndex);
                setLoading(false);
                break;
            default:
                break;
        }
    }

    // user clicked no on the save insect confirmation popup
    function handleNoSave() {
        setSaveConfirmationOpen(false);
        paginationHandler(getCallbackState())
    }

    // user clicked yes on the save insect confirmation popup
    function handleYesSave() {
        setSaveConfirmationOpen(false);
        sendIdentificationToServer(getCallbackState());
    }

    // user clicked no on the exit to home page confirmation popup
    function handleNoExit() {
        setNoInsectsLeftExitConfirmationOpen(false);
        setLoading(false);
        setIdentificationsPtr(0);
        setInsectsPtr(totalPages - 1);
    }

    // free the user's assigned insects if exists
    function freeAssigned() {
        restRequest('FreeUserAssigned', 'post');
    }

    function setupBeforeUnloadListener() {
        window.addEventListener("beforeunload", (ev) => {
            freeAssigned();
        });
    }

    // user clicked yes on the exit to home page confirmation popup
    function handleYesExit() {
        setNoInsectsLeftExitConfirmationOpen(false);
        history.replace("home");

        // free the assigned insects (if exists)
        freeAssigned();
    }

    // user clicked no on the verify all confirmation popup
    function handleNoVerify() {
        setVerifyConfirmationOpen(false);
    }

    function handleHead(event) {
        setHead(event.target.checked);
        setUserHead(event.target.checked);
    }

    function handleThorax(event) {
        setThorax(event.target.checked);
        setUserThorax(event.target.checked);
    }

    function handleAbdomen(event) {
        setAabdomen(event.target.checked);
        setUserAbdomen(event.target.checked);
    }

    function handleNoise(event) {
        setNoise(event.target.checked);
        setUserNoise(event.target.checked);
    }

    function handleMultiple(event) {
        setMultiple(event.target.checked);
        setUserMultiple(event.target.checked);
    }

    // user clicked yes on the save insect confirmation popup
    async function handleYesVerify() {
        setContainUnverified(false);
        setVerifyConfirmationOpen(false);
        const filterParamsTotal = new FilterParamsDto(filterParams, checkedCollections, []);
        await restRequest(`AddVerificationAll`, 'post', filterParamsTotal);
        var newInsectsArr = insectsArr;
        newInsectsArr.map(insect => {
            insect.verified = true;
            return insect;
        });
        setInsectsArr(newInsectsArr);
        setIsVerified(true);
    }

    // withdraw insects from the db
    async function getInsect() {
        const filterParamsTotal = new FilterParamsDto(filterParams, checkedCollections, insectsArr.map((insect) => insect.insect_id));
        const response = await restRequest('GetInsect', 'post', filterParamsTotal);
        // no more insects left
        if (!response.data) {
            setNoInsectsLeftExitConfirmationOpen(true);
            setInsectsPtr(insectsPtr);
            return;
        }

        const new_insect = new GetInsectDto(response.data);
        // first insect
        if (insectsArr.length === 0) {
            localStorage.setItem('identificationPage', 'true');
            localStorage.setItem('insectsPtr', 0);
            setCurrentCollection(new_insect.collection_id)
            connectUserToCollection(new_insect.collection_id);
            const response = await restRequest('InterestedInsects', 'get', {});
            setInterestedGenuses(response.data.interest_genus);
            setInterestedSpecies(response.data.interest_species);
            setGenusList(response.data.interest_genus);
            setSpeciesList(response.data.interest_species);
        }
        else {
            setGenusList(interestedGenuses);
            setSpeciesList(interestedSpecies);
        }
        setInsectsArr(insectsArr.concat(new_insect));
        localStorage.setItem('insectsArr', JSON.stringify(insectsArr.concat(new_insect)));
        localStorage.setItem('totalInsects', JSON.stringify(totalInsectsNumber));
        setLoading(false);
    }

    // get the params of the genus and species dropdown list
    async function getInterestedInsects() {
        const response = await restRequest('InterestedInsects', 'get', {});
        setInterestedGenuses(response.data.interest_genus);
        setInterestedSpecies(response.data.interest_species);
    }

    // retrieve the next unidentified insect
    function nextInsect() {
        setGenusList(interestedGenuses);
        setSpeciesList(interestedSpecies);
        setLoading(true);
        saveInsect("NEXT");
    }

    // withdraw the former insect
    function prevInsect() {
        if (insectsPtr === 0) {
            return;
        }
        setGenusList(interestedGenuses);
        setSpeciesList(interestedSpecies);
        setLoading(true);
        saveInsect('PREV');
    }

    function isIdentified(index) {
        return (insectsArr[index].identifications.length !== 0);
    }

    function getLast() {
        // go over the formar insects array
        for (let i = 0; i < insectsPtr; i++) {
            if (!isIdentified(i)) {
                return i;
            }
        }
        return null;
    }

    function firstUnidentifiedInsect() {
        setLoading(true);
        let lastIndex = getLast();

        // there is a formar unidentified insect
        if (lastIndex !== null) {
            saveInsect("LAST");
        }
    }

    async function getTotalInsectsToTag() {
        const response = await restRequest('NumberOfInsectsToTag', 'post', { collections_ids: checkedCollections });
        console.log('tag',response.data)
        setTotalInsectsNumber(response.data);
    }

    async function getTotalInsects() {
        const filterParamsTotal = new FilterParamsDto(filterParams, checkedCollections, insectsArr.map((insect) => insect.insect_id));
        const response = await restRequest('NumberOfInsects', 'post', filterParamsTotal);
        console.log('total pages',response.data)
        setTotalPages(response.data);
    }

    // check if has formar identification of the insect
    function hasPrev() {
        return identificationsPtr > 0;
    }

    // check if has next identification of the insect
    function hasNextIdentification() {
        if (insectsArr.length > 0 && insectsPtr < insectsArr.length && insectsPtr >= 0 && insectsArr[insectsPtr].identifications && identificationsPtr >= 0) {
            if (userIdentified) {
                return identificationsPtr < insectsArr[insectsPtr].identifications.length;
            }
            else {
                return identificationsPtr < insectsArr[insectsPtr].identifications.length - 1;
            }
        }
        return false;
    }

    function inputEmpty() {
        return (sex === "" && species === "" && genus === "" && !cantIdentify && !secondOpinion && !head && !thorax && !abdomen && !noise && !multiple)
    }

    // trime the timeStamp to only date
    function onlyDate() {
        try {
            return (timeStamp.toString().substr(0, 10));
        }
        catch (e) {
            return "";
        }
    }

    function addIdentification() {
        setUserIdentified(true);
        setUserGenus('');
        setUserSpecies('');
        setUserSex('');
        setUserIsFed(false);
        setUserCantIdentify(false);
        setUserSecondOpinion(false);
        setUserValidSwitch("Valid");
        setIdentificationsPtr(insectsArr[insectsPtr].identifications.length);
    }

    function formarIdentification() {
        setIdentificationsPtr(identificationsPtr - 1);
    }

    function nextIdenticiation() {
        setIdentificationsPtr(identificationsPtr + 1);
    }

    function cancelIdentification() {
        setGenus('');
        setSpecies('');
        setSex('');
        getInterestedInsects();
        setCantIdentify(false);
        setSecondOpinion(false);

        // if has more than one identification
        if (insectsArr[insectsPtr].identifications.length > 0) {
            setIdentificationsPtr(insectsArr[insectsPtr].identifications.length - 1);
            setUserIdentified(false);
        }
    }

    async function collectionsContainsUnverified() {
        const response = await restRequest('collectionsContainsUnverified', 'post', { collections_ids: checkedCollections });
        setContainUnverified(response.data);
    }

    function showIdentificationField(value) {
        // current identification window
        if (insectsArr[insectsPtr] && !(identificationsPtr === 0 && insectsArr[insectsPtr].identifications > 0)) {
            if (validSwitch !== "Not valid") {
                return (filterParams.viewUnidentified || (!filterParams.viewUnidentified && (value || identificationsPtr === insectsArr[insectsPtr].identifications.length)));
            }
        }

        // former identification window or not valid is on
        return false;
    }

    function showValidSwitch() {
        return (!userIdentified || insectsArr[insectsPtr]?.identifications?.length > 0 && identificationsPtr < insectsArr[insectsPtr]?.identifications?.length);
    }

    function atLeastTwoOrNoiseOrMultiple(part) {
        if (noise || multiple) {
            return true;
        }
        else if (head && thorax && part === "abdomen") {
            return true;
        }
        else if (head && abdomen && part === "thorax") {
            return true;
        }
        else if (thorax && abdomen && part === "head") {
            return true;
        }
        return false;
    }

    function atLeastOne() {
        return head || thorax || abdomen;
    }

    async function generatePdf() {
        setWaitCreatePDF(true);
        await PdfComp({ insectSiteLat, insectSiteLong, insectSiteName, insectId, insectPlacementDate, insectPickupDate, genus, species, sex, insectImages });
        setWaitCreatePDF(false);
    }

    function resetUserStates() {
        setUserSex('');
        setUserGenus('');
        setUserSpecies('');
        setUserIsFed(false);
        setUserSecondOpinion(false);
        setUserHead(false);
        setUserThorax(false);
        setUserAbdomen(false);
        setUserNoise(false);
        setUserMultiple(false);
        setUserCantIdentify(false);
        setUserValidSwitch("Valid");
    }

    async function jumpToSiteFromIdentification() {
        let response = await restRequest('getCollectionOfInsect', 'post', { insect_id: insectId });
        setCollectionOfInsect(response.data);
        history.replace("home");
    }

    async function getGenus() {
        let response = await restRequest('getGenusOfSpecies', 'post', { species: userSpecies });
        setGenus(response.data);
    }

    const handleKeyPress = useCallback((event) => {
        // Your logic to handle key presses
        if (event.key === 'ArrowRight') {
            nextInsect();
        }
        if (event.key === 'ArrowLeft') {
            prevInsect();
        }

    }, [insectsPtr, insectsArr]);

    useEffect(() => {
        handleKeyPressRef.current = handleKeyPress;
    }, [handleKeyPress]);

    useEffect(() => {
        if (checkedCollections.length === 0) {
            setCheckedCollections(JSON.parse(localStorage.getItem('checkedCollections')));
            setFilterParams(JSON.parse(localStorage.getItem('filterParams')));
            setInsectsArr(JSON.parse(localStorage.getItem('insectsArr')));
            setInsectsPtr(JSON.parse(localStorage.getItem('insectsPtr')));
            setTotalInsectsNumber(JSON.parse(localStorage.getItem('totalInsects')));
            setTotalPages(JSON.parse(localStorage.getItem('totalInsects')));
            setCurrentCollection(JSON.parse(localStorage.getItem('insectsArr'))[JSON.parse(localStorage.getItem('insectsPtr'))].collection_id);
            setUsersLoggedIn(JSON.parse(localStorage.getItem('users')));
        }
        else {
            localStorage.setItem('checkedCollections', JSON.stringify(checkedCollections));
            localStorage.setItem('filterParams', JSON.stringify(filterParams));
            getInsect();
            getTotalInsectsToTag();
            getTotalInsects();
        }
        collectionsContainsUnverified();
        setupBeforeUnloadListener();
        getKindsNames();
        getGuidePictures();
    }, [checkedCollections]);

    useEffect(() => {
        resetUserStates();
        if (insectsPtr < insectsArr.length) {
            // general info of the insect
            console.log(insectsArr, insectsPtr)
            if (currentCollection != '' && currentCollection !== insectsArr[insectsPtr].collection_id) {
                removeUserFromCollection();
                setCurrentCollection(insectsArr[insectsPtr].collection_id)
                setMoveToNextCollection(true);
                connectUserToCollection(insectsArr[insectsPtr].collection_id);
            }
            setIsVerified(insectsArr[insectsPtr].verified);
            setInsectImages(insectsArr[insectsPtr].urls);
            setInsectSiteName(insectsArr[insectsPtr].site_name);
            setInsectSiteLat(insectsArr[insectsPtr].site_lat);
            setInsectSiteLong(insectsArr[insectsPtr].site_long);
            setInsectPlacementDate(insectsArr[insectsPtr].placement_date);
            setInsectPickupDate(insectsArr[insectsPtr].pickup_date);
            setInsectId(insectsArr[insectsPtr].insect_id);
            setImagesScales(insectsArr[insectsPtr].urls.map((image) => image.images_scale));
            // insect has previous identifications
            if (insectsArr[insectsPtr].identifications.length > 0) {
                setIdentificationsPtr(insectsArr[insectsPtr].identifications.length - 1);
                setUserIdentified(false);
                setNeededSecondOpinion(insectsArr[insectsPtr].identifications[insectsArr[insectsPtr].identifications.length - 1].second_opinion);
            }
            // insect never been identified
            else {
                setUserIdentified(true);
                setIdentificationsPtr(0);
                setNeededSecondOpinion(false);
            }
        }
    }, [insectsPtr, insectsArr])

    useEffect(() => {
        if (insectsArr.length > 0 && identificationsPtr >= 0) {
            // if user goes back to the curr insect identification he started - retrieve what he done already
            if (identificationsPtr === insectsArr[insectsPtr].identifications.length) {
                setGenus(userGenus);
                setSex(userSex);
                setSpecies(userSpecies);
                setIsFed(userIsFed);
                setCantIdentify(userCantIdentify);
                setSecondOpinion(userSecondOpinion);
                setHead(userHead);
                setThorax(userThorax);
                setAabdomen(userAbdomen);
                setNoise(userNoise);
                setMultiple(userMultiple);
                setTagger(window.sessionStorage.getItem('userName'));
                setTimeStamp(new Date());
                setValidSwitch(userValidSwitch);
            }
            else {
                const identification = insectsArr[insectsPtr].identifications[identificationsPtr];
                setSex(identification.sex);
                setIsFed(identification.isFed);
                setGenus(identification.genus);
                setSpecies(identification.species);
                setCantIdentify(identification.cant_identify === null ? false : identification.cant_identify);
                setSecondOpinion(identification.second_opinion === null ? false : identification.second_opinion);
                setTagger(identification.tagger_username === null ? false : identification.tagger_username);
                setTimeStamp(identification.timestamp === null ? new Date() : identification.timestamp);
                setValidSwitch(identification.valid ? "Valid" : "Not valid");
                setHead(identification.head);
                setThorax(identification.thorax);
                setAabdomen(identification.abdomen);
                setNoise(identification.noise);
                setMultiple(identification.multiple);
            }
        }
    }, [identificationsPtr])

    useEffect(() => {
        setIsGuide(kind !== null);
    }, [kind]);

    useEffect(() => {
        if (userSpecies !== '') {
            getGenus();
        }
    }, [userSpecies])

    useEffect(() => {
        socket?.on("newUser", data => {
            setUsersLoggedIn(data.userNames.filter(user => user !== window.sessionStorage.getItem('userName')));
            sessionStorage.setItem("users", JSON.stringify(data.userNames));
        });
        socket?.on("noUsers", data => {
            setUsersLoggedIn([]);
            sessionStorage.setItem("users", []);

        });
    }, [socket])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (handleKeyPressRef.current) {
                handleKeyPressRef.current(event);
            }
        };

        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);

        if (localStorage.getItem('identificationPage') == 'true') {
            return () => {
                console.log('leaving');
                removeUserFromCollection();
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, []);

    return (
        <div>
            <div className={classes.wrapper + (loading ? " " + classes.disabled : "")}>
                <div className={classes.insectInfo}>
                    <div className={classes.basicInfo}>
                        <label> Site name: {insectSiteName} </label>
                        <label> Placement date: {StringToDate(insectPlacementDate)} </label>
                        <label> Pickup date: {StringToDate(insectPickupDate)} </label>
                        <label> Insect id: {insectId} </label>
                    </div>
                    <div className={isVerified ? classes.verifiedIdentification : classes.identification}>
                        <div>
                            {hasPrev() &&
                                <IconButton onClick={formarIdentification}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                            }
                        </div>
                        <div className={classes.identificationCenter}>
                            <label className={classes.identificationInfo}> Tagger: {tagger} </label>
                            <lable className={classes.identificationInfo}> Date: {onlyDate()} </lable>
                            {neededSecondOpinion &&
                                <label className={classes.neededSecondOpinion}> Needed Second opinion </label>
                            }
                            {showIdentificationField(sex) &&
                                <ComboBoxComp className={comboStyles.identification} name={"Sex:"} paramsList={["Female", "Male"]} value={sex} setValue={handleSex} isDisabled={cantIdentify || !userIdentified || hasNextIdentification()} />
                            }
                            {sex === "Female" && showIdentificationField(isFed) &&
                                <div className={classes.fed}>
                                    <CheckBoxComp label={"Fed"} checked={isFed} onChange={handleFed} isDisabled={cantIdentify || !userIdentified || hasNextIdentification()} />
                                </div>
                            }
                            {showIdentificationField(genus) &&
                                <AdjustedComboBoxComp className={adjustedComboBoxStyles.genus_identification} name={"Genus:"} paramsList={genusList} effected={"species"} setEffectedList={setSpeciesList} value={genus} setValue={handleGenus} isDisabled={cantIdentify || !userIdentified || hasNextIdentification()} />
                            }
                            {showIdentificationField(species) &&
                                <AdjustedComboBoxComp className={adjustedComboBoxStyles.species_identification} name={"Species:"} paramsList={speciesList} effected={"genus"} setEffectedList={setGenusList} value={species} setValue={handleSpecies} isDisabled={cantIdentify || !userIdentified || hasNextIdentification()} />
                            }
                            <div className={classes.checkBoxes}>
                                {showIdentificationField(cantIdentify) &&
                                    <CheckBoxComp label={"Can't identify"} checked={cantIdentify} onChange={handleCantIdentify} isDisabled={!userIdentified || hasNextIdentification()} />
                                }
                                {showIdentificationField(secondOpinion) &&
                                    <CheckBoxComp label={"Second opinion"} checked={secondOpinion} onChange={handleSecondOpinion} isDisabled={cantIdentify || !userIdentified || hasNextIdentification()} />
                                }
                                {validSwitch === "Not valid" &&
                                    <div>
                                        <CheckBoxComp label={"Head"} checked={head} onChange={handleHead} isDisabled={atLeastTwoOrNoiseOrMultiple("head") || !userIdentified || hasNextIdentification()} />
                                        <CheckBoxComp label={"Thorax"} checked={thorax} onChange={handleThorax} isDisabled={atLeastTwoOrNoiseOrMultiple("thorax") || !userIdentified || hasNextIdentification()} />
                                        <CheckBoxComp label={"Abdomen"} checked={abdomen} onChange={handleAbdomen} isDisabled={atLeastTwoOrNoiseOrMultiple("abdomen") || !userIdentified || hasNextIdentification()} />
                                        <CheckBoxComp label={"Noise"} checked={noise} onChange={handleNoise} isDisabled={atLeastOne() || !userIdentified || hasNextIdentification() || multiple} />
                                        <CheckBoxComp label={"Multiple"} checked={multiple} onChange={handleMultiple} isDisabled={atLeastOne() || !userIdentified || hasNextIdentification() || noise} />
                                    </div>
                                }
                                <SwitchComp label={validSwitch} setLabel={handleValidSwich} primaryLbl={"Valid"} secondaryLbl={"Not valid"} isDisabled={showValidSwitch()} />
                            </div>
                        </div>
                        <div className={classes.rightSideIdentification}>
                            {!userIdentified && !hasNextIdentification() &&
                                <IconButton onClick={addIdentification}>
                                    <AddBoxOutlinedIcon />
                                </IconButton>
                            }
                            {hasNextIdentification() &&
                                <IconButton className={classes.forward_identification} onClick={nextIdenticiation}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            }
                            {userIdentified && insectsArr[insectsPtr] && insectsArr[insectsPtr].identifications && insectsArr[insectsPtr].identifications.length > 0 && identificationsPtr === insectsArr[insectsPtr].identifications.length &&
                                <IconButton>
                                    <HighlightOffIcon onClick={cancelIdentification} />
                                </IconButton>
                            }
                        </div>
                    </div>
                    {failAlertKind &&
                        <Alert severity="error">Partial Identification wasn't saved</Alert>
                    }
                    {successAlert &&
                        <Alert severity="success">Identification completed</Alert>
                    }
                    {partialAlert &&
                        <Alert severity="warning">Second opinion saved without partial identification</Alert>
                    }
                    <div className={buttonStyles.hiddenButtons}>
                        {!isVerified && !filterParams.viewUnidentified && !userSecondOpinion && !neededSecondOpinion &&
                            <ButtonComp name={"Verify"} action={sendVerificationToServer} />
                        }
                        {!filterParams.viewUnidentified && containsUnverified && !userSecondOpinion && !neededSecondOpinion &&
                            <ButtonComp name={"Verify All"} action={sendVerificationAllToServer} />
                        }
                        {filterParams.viewUnidentified && getLast() !== null &&
                            <ButtonComp className={buttonStyles.firstUnidentifiedInsect} name={"First unidentified"} action={firstUnidentifiedInsect} />
                        }
                    </div>
                    <div className={classes.smartGuide}>
                        <ComboBoxComp name={"Guide:"} paramsList={kindsList} value={kind} setValue={setKind} isDisabled={twoImages === true} />
                    </div>
                </div>
                <div className={classes.rightPage}>
                    <div className={classes.images}>
                        {!loading &&
                            <div className={twoImages ? classes.firstImage : classes.oneImage}>
                                <PictureComp users={usersLoggedIn} images={insectImages} isGuide={isGuide} twoImages={twoImages} setTwoImages={setTwoImages} isLeft={true} imagesScales={imagesScales} longitude={insectSiteLong} latitude={insectSiteLat} filterParams={filterParams} insectNumber={insectNumber} totalInsectsNumber={totalInsectsNumber} />
                            </div>
                        }
                        {isGuide && !loading &&
                            <GuideComp kind={kind} setKind={setKind} images={guidePictures} setGenus={handleGenus} setSpecies={handleSpecies} userIdentified={userIdentified} identificationsPtr={identificationsPtr} insectsArr={insectsArr} insectsPtr={insectsPtr} />
                        }
                        {twoImages && !loading &&
                            <div className={classes.secondImage}>
                                <PictureComp images={insectImages} isGuide={false} twoImages={twoImages} setTwoImages={setTwoImages} isLeft={false} imagesScales={imagesScales} longitude={insectSiteLong} latitude={insectSiteLat} filterParams={filterParams} insectNumber={insectNumber} totalInsectsNumber={totalInsectsNumber} />
                            </div>
                        }
                    </div>
                    {totalPages && insectsPtr == totalPages &&
                        <ConfirmationDialogComp message={"No insects left in the chosen collections, do you want to exit to the home page?"} open={noInsectsLeftConfirmationOpen} handleYes={handleYesExit} handleNo={handleNoExit} handleClose={() => { setNoInsectsLeftExitConfirmationOpen(false); setLoading(false); }} />
                    }
                    {totalPages == 0 &&
                        <InfoDialogComp message={"No insects left in the chosen collections, returning to home page."} open={noInsectsAtAllConfirmationOpen} setOpen={setNoInsectsAtAllConfirmationOpen} setLoading={setLoading} />
                    }
                    {moveToNextCollection &&
                        <InfoDialogComp message={"You are about to access a different collection"} open={moveToNextCollection} handleClose={() => setMoveToNextCollection(false)} />
                    }
                    <ConfirmationDialogComp message={"Do you want to save your identification?"} open={saveConfirmationOpen} handleYes={handleYesSave} handleNo={handleNoSave} handleClose={() => { setSaveConfirmationOpen(false); setLoading(false); }} />
                    <ConfirmationDialogComp message={"Are you sure you want to verify ALL the insects at the chosen collections?"} open={verifyConfirmationOpen} handleYes={handleYesVerify} handleNo={handleNoVerify} handleClose={() => { setVerifyConfirmationOpen(false); setLoading(false); }} />
                    <div className={classes.lastRow}>
                        <div className={classes.navigateBetweenInsects}>
                            <IconButton onClick={prevInsect} disabled={insectsPtr === 0}>
                                <ArrowBackIcon />
                            </IconButton>
                            <div className={classes.pageIdx}>
                                <label>Page No. {insectsPtr + 1}/ {totalPages} </label>
                            </div>
                            <IconButton onClick={nextInsect} disabled={totalPages - 1 === insectsPtr}>
                                <ArrowForwardIcon />
                            </IconButton>
                        </div>
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Jump to collection location on the home page map'>
                            <IconButton className={classes.returnToCollection} onClick={jumpToSiteFromIdentification}>
                                <FollowTheSigns />
                            </IconButton>
                        </Tooltip>
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Download insect info pdf'>
                            <IconButton className={classes.pdf} onClick={generatePdf}>
                                <VerticalAlignBottom />
                            </IconButton>
                        </Tooltip>
                        {waitCreatePDF &&
                            <CircularProgress variant="indeterminate" shrinkAnimation={false} size={30} disableShrink />
                        }
                    </div>
                </div>
            </div>
            {loading &&
                <div className={classes.spinner} >
                    <CircularProgress shrinkAnimation={false} size={50} />
                </div>
            }
        </div>
    )
}