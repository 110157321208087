import { useEffect, useState } from 'react';
import RoadMapComp from '../../components/MapComp/RoadMapComp';
import { restRequest } from '../../serverAPI/restRequests';
import classes from './RoadPathPage.module.css';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import comboStyles from '../../components/ComboBoxComp/ComboBoxComp.module.css';
import LandmarksTableComp from '../../components/Tables/LandmarksTableComp';
import EditIcon from '@mui/icons-material/Edit';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, CircularProgress, DialogTitle, DialogContent, DialogActions, Button, IconButton, Tooltip } from '@material-ui/core';
import TextFieldComp from '../../components/TextFieldComp/TextFieldComp';
import ConfirmationDialogComp from '../../components/DialogComp/ConfirmationDialogComp';
import EditRoadIcon from '@mui/icons-material/EditRoad';
import EditSpreadsFile from '../../components/DialogComp/EditspreadsFile';

const useStyles = makeStyles({
    tooltip: { fontSize: "1em" }
});

export default function RoadPathPage({ checkedLandmarks, setCheckedLandmarks }) {
    const [landmarks, setLandmarks] = useState([]);
    const [user, setUser] = useState(null);
    const [userNames, setUserNames] = useState([]);
    const [userSelectedLandmarks, setUserSelectedLandmarks] = useState([]);
    const [areas, setAreas] = useState([]);
    const [area, setArea] = useState(null);
    const [currentArea, setCurrentArea] = useState(null);
    const [flagNewLAndmarks, setFlagNewLAndmarks] = useState(0);
    const [page, setPage] = useState(0);
    const [areaName, setAreaName] = useState(currentArea);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saveConfirmation, setSaveConfirmation] = useState(false);
    const [userDates, setUserDates] = useState([]);
    const [currentUserDate, setCurrentUserDate] = useState(null);
    const [areaId, setAreaId] = useState(null);
    const [spreadName, setSpreadName] = useState(null);
    const [openEditSpread, setOpenEditSpread] = useState(false);
    const [userList, setUserList] = useState([]);
    const [addressList, setAddressList] = useState([]);
    const [clientsNames, setClientsNames] = useState([]);
    const [showDirections, setShowDirections] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const toolTipClasses = useStyles();
    const handleClose = () => {
        setOpen(false);
        setAreaName(currentArea);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth < 900);
    };

    function changeLandmarks() {
        if (area !== null) {
            let spread = area.userSpreads;
            let currUserSpread = (spread.find(element => element.date === currentUserDate && element.userName === user))
            setSpreadName(currUserSpread.areaName)
            let idArray = currUserSpread.spreadsArray;
            let landmarks = area.landMarks;
            let newLandmarks = [];
            idArray.forEach(_id => {
                newLandmarks.push(landmarks.find(land => land._id === _id));
            });
            setLandmarks(newLandmarks)
        }
    }

    async function getCompanyUsers() {
        let response = await restRequest('getCompanyUsers', 'get');
        console.log(response.data);
        setUserList(response.data);
    }

    async function getAreas() {
        const response = await restRequest(`getAreas`, 'get', {});
        setAreas(response.data);
    }

    async function getLandmarks() {
        const response = await restRequest(`getLandmarks`, 'post', { areaName: currentArea });
        setAddressList(response.data.landMarks.map(l => l.address));
        setClientsNames(response.data.landMarks.map(l => l.clientName));
        setArea(response.data);
        console.log(response.data);
        setAreaId(response.data._id);
        let user_Names = [...new Set(response.data.userSpreads.map(spread => spread.userName))];
        setUserNames(user_Names);
        setLandmarks(response.data.landMarks);
    }

    async function editAreaName() {
        setSaveConfirmation(false);
        setLoading(true);

        await restRequest('editAreaName', 'post', { prevName: currentArea, newName: areaName });
        const indexOfOldName = areas.indexOf(currentArea);
        const updatedAreas = [...areas];
        updatedAreas[indexOfOldName] = areaName;
        setOpen(false);
        setLoading(false);
        setCurrentArea(areaName);
        setAreas(prev => updatedAreas);
    }

    useEffect(() => {
        getAreas();
    }, [flagNewLAndmarks])

    useEffect(() => {
        if (currentArea !== null) {
            getLandmarks();
        }
        else {
            setAreaId(null);
            setLandmarks([]);
            setUser(null);
            setUserNames([]);
        }
        setPage(0);
    }, [currentArea, flagNewLAndmarks])

    useEffect(() => {
        setCheckedLandmarks([]);
        getCompanyUsers();
    }, [])

    useEffect(() => {
        if (user !== null) {
            let spread = area.userSpreads;
            let dates = (spread.filter(element => element.userName === user)).map(elem => elem.date);
            const formattedDates = dates.map((dateString) => {
                const dateObject = new Date(dateString);
                return dateObject.toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                }) // Adjust the locale as needed
            });
            setCurrentUserDate(null);
            setUserDates(formattedDates);
        }
        else {
            if (area !== null) {
                setUser(null);
                setCurrentUserDate(null);
                setLandmarks(area.landMarks)
            }
        }
        setPage(0)
    }, [user])

    useEffect(() => {
        if (currentUserDate !== null && user !== null) {
            changeLandmarks();
        }
        else {
            if (area !== null) {
                setCurrentUserDate(null);
                setLandmarks(area.landMarks)
            }
        }
        setPage(0)
    }, [currentUserDate])

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={classes.wrapper}>
            {(!showDirections || !isMobile) &&
                <div style={isMobile ? { width: '100%', height: '85vh' } :
                    (landmarks && landmarks.length > 0 ? { width: '38%', height: '85vh' } : { width: '18%', height: '85vh' })}>
                    <div className={classes.wrapper1}>
                        <ComboBoxComp className={comboStyles.areaCombobox} name={"Spreading Line"} paramsList={areas} value={currentArea} setValue={setCurrentArea} />
                    </div>
                    {currentArea !== null &&
                        <div className={classes.areaDiv}>
                            <p className={classes.headline}>Area Name: {currentArea}</p>
                            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Edit Area Name'}>
                                <IconButton className={classes.editIcon} size='small' onClick={() => setOpen(true)}>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    }
                    <div className={isMobile ? classes.checkBoxsMobile : classes.checkBoxs}>
                        {userNames.length > 0 && <ComboBoxComp className={comboStyles.userDateComboBox} name={"users"} paramsList={userNames} value={user} setValue={setUser} />}
                        {user !== null && userDates.length > 0 &&
                            <ComboBoxComp className={comboStyles.userDateComboBox} name={"Date"} paramsList={userDates} value={currentUserDate} setValue={setCurrentUserDate} />}
                        {/* <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title={'Edit current spread'}>
                        <IconButton size='small' onClick={() => setOpenEditSpread(true)}>
                            <EditRoadIcon />
                        </IconButton>
                    </Tooltip> */}
                    </div>
                    {landmarks.length > 0 && ((user !== null && currentUserDate !== null) || user === null) && !isMobile &&
                        <LandmarksTableComp landmarks={landmarks} setLandmarks={setLandmarks} checkedLandmarks={checkedLandmarks} setCheckedLandmarks={setCheckedLandmarks} page={page} setPage={setPage} user={user} currentUserDate={currentUserDate} setFlagNewLAndmarks={setFlagNewLAndmarks} areaId={areaId} userList={userList} />}
                </div>}
            <div style={isMobile ? (showDirections ? { height: '88vh', width: '100%', marginTop: '0.7%' } : { height: '88vh', width: '20%', marginTop: '0.7%' }) : { height: '85vh', width: '90%', marginTop: '0.7%' }}>
                <RoadMapComp landmarks={landmarks} setLandmarks={setLandmarks} currentArea={currentArea} checkedLandmarks={checkedLandmarks} setCheckedLandmarks={setCheckedLandmarks} addressList={addressList} clientsNames={clientsNames} setFlagNewLAndmarks={setFlagNewLAndmarks} currentUserDate={currentUserDate}
                    areas={areas} users={userList} showDirections={showDirections} setShowDirections={setShowDirections} />
            </div>
            {/* <EditSpreadsFile open={openEditSpread} handleClose={() => setOpenEditSpread(false)} prevDate={currentUserDate} prevName={spreadName} prevUserName={user} userList={userList} /> */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit Area Name
                </DialogTitle>
                <DialogContent>
                    <TextFieldComp value={areaName} setValue={setAreaName} label={"New Area Name"} />
                </DialogContent>
                <DialogActions>
                    {loading &&
                        <CircularProgress className={classes.spinner} size={30} disableShrink />
                    }
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => setSaveConfirmation(true)} color="primary" disabled={areaName === null}>
                        Save
                    </Button>
                    <ConfirmationDialogComp message={`Are you sure you want to change the area name?`} open={saveConfirmation} handleYes={() => editAreaName()} handleNo={() => setSaveConfirmation(false)} handleClose={handleClose} />
                </DialogActions>
            </Dialog >
        </div>

    )
}