import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Typography } from '@material-ui/core';

export default function CheckboxLabels({className, label, checked, onChange, isDisabled}) {

  return (
    <div>
        <FormControlLabel
          control={<Checkbox className={className} icon={<CheckBoxOutlineBlankIcon fontSize="inherit" />} checkedIcon={<CheckBoxIcon fontSize="inherit" />} name="checkedI"  checked={checked} onChange={onChange}/>}
          label={<Typography variant="inherit" style={{margin:'2px'}} >{label}</Typography>}
          disabled={isDisabled}
        />
    </div>
  );
}