import React, { useEffect, useState, useRef, useCallback } from "react";
import { makeStyles } from '@material-ui/core/styles';
import CenterFocusStrongRoundedIcon from '@mui/icons-material/CenterFocusStrongRounded';
import CenterFocusWeakRoundedIcon from '@mui/icons-material/CenterFocusWeakRounded';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import RotateRight from '@material-ui/icons/RotateRight';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import { ContentCopySharp } from '@mui/icons-material';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import ImageGallery from 'react-image-gallery';
import PrismaZoom from 'react-prismazoom';
import ProgressBarComp from '../../components/ProgressBarComp/ProgressBarComp';
import LocationPopoverComp from '../PopoverComp/LocationPopoverComp';
import ScaleComp from '../../components/ScaleComp/ScaleComp';
import "react-image-gallery/styles/css/image-gallery.css";
import classes from './PictureComp.module.css';
import PersonIcon from '@mui/icons-material/Person';
import InfoDialogComp from '../../components/DialogComp/InfoDialogComp';

export default function PictureComp({ images, twoImages, users, setTwoImages, imagesScales, isLeft, isGuide, latitude, longitude, filterParams, insectNumber, totalInsectsNumber }) {
    const [renderGallery, setRenderGallery] = useState(false);
    const [imagesState, setImagesState] = useState(
        images.map(
            image => {
                return {
                    base: image.path,
                    focus_num: image.focus_num,
                    cur_focus: 1,
                    cur_state: ''
                }
            }))
    const [currIndex, setCurrIndex] = useState(0);
    const [fullScreen, setFullScreen] = useState(false);
    const imgWidthPixels = 2000.0; // CONST width of any image in [px]
    const firstScaleRatio = imgWidthPixels / parseFloat(imagesScales[0]);
    const [scaleRatio, setScaleRatio] = useState(firstScaleRatio); // zoom x1 = scale ratio [px/mm]
    const [savePrismaRef, setSavePrismaRef] = useState(null);
    const [rotateValue, setRotateValue] = useState(0);
    const [confirmInfoUsers, setConfirmInfoUsers] = useState(false);
    const [imageWidth, setImageWidth] = useState(null);
    const handleKeyPressRef = useRef();
    const prismaRefs = useRef([]); // Array of refs for each image
    const imgRefs = useRef([]); // Array of refs for each image

    const useStyles = makeStyles({
        image: {
            rotate: rotateValue + 'deg',
            objectFit: 'contain',
            transform: 'scale(1, 1)',
            zIndex: '0',
            position: 'relative',
            maxHeight: '500px',
            maxWidth: '1000px',
            overflow: 'hidden',
        },
        twoImages: {
            rotate: rotateValue + 'deg',
            objectFit: 'contain',
            transform: 'scale(0.8, 0.8)',
            zIndex: '0',
            position: 'relative',
            maxHeight: '500px',
            maxWidth: '450px',
            '@media (max-width: 1450px)': {
                maxWidth: '300px'
            },
            '@media (max-width: 1000px)': {
                maxWidth: '230px'
            },
            overflow: 'hidden',
        },
    });
    const imageClasses = useStyles();

    const tooltipStyles = makeStyles({
        tooltip: { fontSize: "1em" }

    });

    const toolTipClasses = tooltipStyles();

    const imagesToItems = (images) => {
        var output = [];
        for (var i = 0; i < images.length; i++) {
            var image = images[i];
            output.push({
                original: image.base.replace(new RegExp('\.jpg$'), `${image.cur_state}_f${image.cur_focus}.jpg`),
                thumbnail: image.base.replace(new RegExp('\.jpg$'), `${image.cur_state}_f${image.cur_focus}.jpg`),
                index: i
            })
        }
        return output;
    };

    function filter() {
        var image_arr = imagesState;
        const picTypes = ['', '_dark', '_bright', '_light'];
        const currType = picTypes.indexOf(image_arr[currIndex].cur_state);
        image_arr[currIndex].cur_state = picTypes[(currType + 1) % picTypes.length]
        setImagesState(image_arr);
        setRenderGallery(!renderGallery);
    }

    async function focusCloser() {
        const image_arr = [...imagesState];
        image_arr[currIndex].cur_focus = image_arr[currIndex].cur_focus + 1;
        await setImagesState(image_arr);
        setRenderGallery(!renderGallery);
    }

    async function focusFurther() {
        const image_arr = [...imagesState];
        image_arr[currIndex].cur_focus = image_arr[currIndex].cur_focus - 1;
        await setImagesState(image_arr);
        setRenderGallery(!renderGallery);
    }

    function handleTwoImages() {
        setTwoImages(!twoImages);
    }

    const zoomIn = () => {
        let curr_prisma = prismaRefs.current[currIndex];
        if (curr_prisma !== null) {
            const currZoom = curr_prisma.getZoom();
            if (currZoom < 12) {
                setScaleRatio((scaleRatio / currZoom) * (currZoom + 0.3));

                curr_prisma.zoomIn(0.3);
            }
        }
    };

    const zoomOut = () => {
        let curr_prisma = prismaRefs.current[currIndex];
        if (curr_prisma !== null) {
            const currZoom = curr_prisma.getZoom();
            if (currZoom > 1) {
                setScaleRatio((scaleRatio / currZoom) * (currZoom - 0.3));
                curr_prisma.zoomOut(0.3);
            }
        }
    };

    const handleKeyPress = useCallback((event) => {
        if (event.key === 'ArrowUp') {
            zoomIn();
        }
        if (event.key === 'ArrowDown') {
            zoomOut();
        }
        if (event.key === 's') {
            handleTwoImages();
        }
    }, [handleTwoImages]);

    useEffect(() => {
        handleKeyPressRef.current = handleKeyPress;
    }, [handleKeyPress]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (handleKeyPressRef.current) {
                handleKeyPressRef.current(event);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        setCurrIndex(0);
        setRotateValue(0);
        setImagesState(
            images.map(
                image => {
                    return {
                        base: image.path,
                        focus_num: image.focus_num,
                        cur_focus: 1,
                        cur_state: ''
                    }
                })
        )
    }, [images]);

    useEffect(() => {
        if (prismaRefs.current[currIndex] !== null) {
            // Reset scaleRatio and rotateValue when image changes
            setScaleRatio(imageWidth / parseFloat(imagesScales[currIndex]));
            setRotateValue(0);
        }
    }, [currIndex, imagesScales]);

    return (
        <div>
            <div className={twoImages ? classes.topDivTwoImages : isGuide ? classes.topDivWithGuide : classes.topDiv}>
                <div className={classes.focusLayout} >
                    <div>
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Focus closer'>
                            <span>
                                <IconButton aria-label="Focus-closer" onClick={focusCloser} disabled={imagesState.length === 0 || imagesState[currIndex].cur_focus === imagesState[currIndex].focus_num}>
                                    <CenterFocusStrongRoundedIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Focus further'>
                            <span>
                                <IconButton aria-label="Focus-further" onClick={focusFurther} disabled={imagesState.length === 0 || imagesState[currIndex].cur_focus === 1}>
                                    <CenterFocusWeakRoundedIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                    <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Filter'>
                        <IconButton aria-label="filter" onClick={filter}>
                            <AutoFixHighRoundedIcon />
                        </IconButton>
                    </Tooltip>

                </div>
                {isLeft &&
                    <div className={classes.sidebyside}>
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='View side by side'>
                            <IconButton onClick={handleTwoImages} disabled={isGuide}>
                                <ContentCopySharp />
                            </IconButton>
                        </Tooltip>
                        <LocationPopoverComp location={{ longitude, latitude }} />
                    </div>
                }

                {(!twoImages || (twoImages && !isLeft)) && filterParams.viewUnidentified &&
                    <div className={twoImages ? classes.progressBarTwoImages : isGuide ? classes.progressBarWithGuide : classes.progressBarOneImage}>
                        <ProgressBarComp current={insectNumber} total={totalInsectsNumber} />
                    </div>
                }
                {(!twoImages || (twoImages && !isLeft)) && users && users.length > 0 &&
                    <div className={classes.currentUsers}>
                        <IconButton className={classes.greenIcon} onClick={() => setConfirmInfoUsers(true)} >
                            <PersonIcon />
                        </IconButton>
                    </div>
                }
            </div>
            <ImageGallery
                renderItem={({ original, index }) => {
                    const onImageLoad = (event) => {
                        if (imgRefs.current[currIndex]) {
                            setImageWidth(imgRefs.current[currIndex].clientWidth);
                            setScaleRatio(imgRefs.current[currIndex].clientWidth / parseFloat(imagesScales[currIndex]));
                        }
                    };

                    const onDoubleClickOnCard = event => {
                        setScaleRatio(imgRefs.current[currIndex].clientWidth / parseFloat(imagesScales[index]));
                        event.preventDefault();
                        event.stopPropagation();
                        prismaRefs.current[index].reset();
                    };

                    return (
                        <div className={twoImages ? classes.galleryTwoImages : isGuide ? classes.galleryWithGuide : classes.gallery} key={index}>
                            <PrismaZoom ref={el => (prismaRefs.current[index] = el)} minZoom={1} maxZoom={12} scrollVelocity={0}>
                                <img ref={el => (imgRefs.current[index] = el)} className={(twoImages || isGuide) ? imageClasses.twoImages : imageClasses.image}
                                    src={original} onDoubleClick={onDoubleClickOnCard} onLoad={onImageLoad}
                                />
                            </PrismaZoom>
                            {!fullScreen &&
                                <div className={classes.zoomLayout}>
                                    <IconButton onClick={() => setRotateValue(rotateValue + 90)}>
                                        <RotateRight />
                                    </IconButton>
                                    <IconButton size="small" aria-label="zoom-in" onClick={() => zoomIn(index)}>
                                        <ZoomInRoundedIcon />
                                    </IconButton>
                                    <IconButton size="small" aria-label="zoom-out" onClick={() => zoomOut(index)}>
                                        <ZoomOutRoundedIcon />
                                    </IconButton>
                                </div>
                            }
                            {!fullScreen &&
                                <div className={(twoImages || isGuide) ? classes.twoScalesBar : classes.oneScaleBar}>
                                    <ScaleComp scaleRatio={scaleRatio} imageWidth={imageWidth} />
                                </div>}
                            {fullScreen &&
                                <div className={classes.fullScreenLogoAdjust} />
                            }
                        </div>
                    )
                }}
                className={classes.mainImage}
                items={imagesToItems(imagesState)}
                useWindowKeyDown={false}
                showFullscreenButton={!twoImages && !isGuide}
                onScreenChange={() => setFullScreen(!fullScreen)}
                useBrowserFullscreen={true}
                showPlayButton={false}
                thumbnailPosition={'right'}
                startIndex={currIndex}
                showNav={false}
                onBeforeSlide={(next) => {
                    if (savePrismaRef !== null) {
                        savePrismaRef.reset();
                        setSavePrismaRef(null);
                    }
                    setCurrIndex(next);
                }}
            />
            < InfoDialogComp message={`${users} is also working on this collection`} open={confirmInfoUsers} handleClose={() => setConfirmInfoUsers(false)} />
        </div >
    )
}