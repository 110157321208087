import TripOriginIcon from '@material-ui/icons/TripOrigin';
import classes from '../CollectionsMapComp.module.css';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tooltip: {fontSize: "1em"}
});

export default function Deployed({ selectDeployed, setSelectDeployed, disable }) {
    const toolTipClasses = useStyles();

    return (
        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Deployed' placement='right'>
            <IconButton
                size="medium"
                variant="contained"
                color={selectDeployed ? "primary" : "default"}
                className={classes.deployed}
                disabled={disable}
                onClick={() => { setSelectDeployed(!selectDeployed) }}>
                <TripOriginIcon />
            </IconButton>
        </Tooltip>
    )
}