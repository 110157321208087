import { ClickAwayListener, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Tooltip, CircularProgress } from "@material-ui/core";
import React, { useState, useRef, useEffect } from "react";
import { restRequest } from '../../../serverAPI/restRequests';
import { Link } from "react-router-dom";
import classes from "./Nav.module.css";
import MenuIcon from "@material-ui/icons/Menu";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import CircleNotificationsSharpIcon from '@material-ui/icons/NotificationsSharp';
import ButtonComp from "../../ButtonComp/ButtonComp";
import { useHistory } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import NewRuleDialogComp from "../../DialogComp/NewRuleDialogComp";
import ViewListIcon from '@mui/icons-material/ViewList';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InfoDialogComp from '../../DialogComp/InfoDialogComp';

export default function LoggedInNav({ name, socket, onLogOut, setCollectionOfCheckedVirus, setRefresh, siteFromPopUp, setSiteFromPopUp, kindsList, sitesId, companyRuns, siteFromRule, setSiteFromRule, userMessages, setUserMessages, userRules, setUserRules }) {
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [openCollectionManagement, setOpenCollectionManagement] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openDialogNewRule, setOpenDialogNewRule] = useState(false);
  const [openRules, setOpenRules] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const anchorRef = useRef(null);
  const useStyles = makeStyles({
    tooltip: { fontSize: "1em" }
  });
  const toolTipClasses = useStyles();

  const addNewRule = () => {
    setOpenDialogNewRule(true);
  }

  const handleCloseDialogNewRule = () => {
    setOpenDialogNewRule(false);
  };

  // when clicking on the hamburger menu
  function handleToggleMenu() {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  // when clicking on the collection management link
  function handleToggleCollectionManagement() {
    setOpenCollectionManagement((prevOpen) => !prevOpen);
  }

  // when choosing element from the hamburger menu
  async function handleMenuSelection() {
    restRequest('FreeUserAssigned', 'post');
    handleToggleMenu();
  }

  // when choosing element from the collection management options
  async function handleCollectionManagementSelection() {
    setOpenMenu(false);
    restRequest('FreeUserAssigned', 'post');
    handleToggleCollectionManagement();
  }

  // gets collection id from db according to vial id
  async function getCollectionIdFromVial(vial_id) {
    let response = await restRequest('getCollectionIdFromVial', 'post', { vial_id: vial_id });
    setCollectionOfCheckedVirus(response.data);
    history.replace("home");
  }

  function goToSite(site_id) {
    setSiteFromRule(site_id);
    setOpenNotifications(false);
  }

  async function handleRemove(r) {
    setLoading(true)
    let data = {};
    data.ruleName = r;
    data.userName = sessionStorage.getItem('userName');
    await restRequest('deleteRule', 'put', data);
    const newRules = userRules.filter((rule) => rule !== r);
    setUserRules(newRules);
    sessionStorage.setItem('userRules', JSON.stringify(newRules));
    setLoading(false);
    setDeleted(true);
  }

  function displayNotification(n) {
    if (n.test_name) {
      return (
        <div>
          <span className={classes.notification}>{`${n.test_name} detected in vial number `}
            <a onClick={() => getCollectionIdFromVial(n.vial_barcodes)}>{n.vial_barcodes}</a>
          </span>
        </div>
      )
    }
    else {
      const words = n.split(' ');
      if (words.length >= 3) {
        const thirdWord = words[2];
        const messageWithLink = words.map((word, index) => (
          <span key={index}>
            {index === 2 ? (
              <a href="#" onClick={() => goToSite(thirdWord)}>
                {word}
              </a>
            ) : (
              word
            )}{' '}
          </span>
        ));

        return (
          <div>
            <span className={classes.notification}>{messageWithLink}</span>
          </div>
        );
      }
    }
  }

  function displayRules(r) {
    if (userRules.length > 0) {
      return (
        <div style={{ marginBottom: '2px' }}>
          <span className={classes.notification} >{`${r}`}
            <IconButton style={{ right: '1%' }} size='medium' onClick={() => handleRemove(r)}>
              <DeleteOutlineIcon />
            </IconButton>
          </span>
        </div>
      )
    }
  }

  async function handleReadNotification() {
    setOpenNotifications(false);
    setUserMessages([]);
    await restRequest('deleteUserMessages', 'post', { userName: sessionStorage.getItem('userName') });
    sessionStorage.setItem('userMessages', JSON.stringify([]));
  }

  function handleClickNotifications() {
    if (userMessages.length > 0) {
      setSiteFromRule(null);
      setOpenNotifications(!openNotifications)
    }
  }

  function handleClickRules() {
    if (userRules.length > 0) {
      setOpenRules(!openRules)
    }
  }

  function PaperComponent(props) {
    return (
      <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
        <Paper {...props} />
      </Draggable>
    );
  }

  useEffect(() => {
    socket?.on("newVirus", data => {
      setUserMessages((prev) => [...prev, data]);
      sessionStorage.setItem('userMessages', JSON.stringify([...userMessages, data]));
    })
    socket?.on("rule", data => {
      setUserMessages((prev) => [...prev, data]);
      sessionStorage.setItem('userMessages', JSON.stringify([...userMessages, data]));
    })
    socket?.on("newRule", data => {
      setUserRules((prev) => [...prev, data]);
      sessionStorage.setItem('userRules', JSON.stringify([...userRules, data]));
    })
  }, [socket]);

  return (
    <div className={name === 'demonstrations' ? classes.logedInNavbarTesting : classes.logedInNavbar} >
      <IconButton data-testid="menuToggle" onClick={handleToggleMenu}>
        <MenuIcon />
      </IconButton>
      <Popper className={classes.MenuList} open={openMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleToggleMenu}>
                <MenuList autoFocusItem={openMenu} id="menu-list-grow" >
                  <MenuItem onClick={handleMenuSelection}>
                    <Link to="/home">Home Page</Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuSelection}>
                    <Link to="/roadPath">Road Map Page</Link>
                  </MenuItem>
                  <MenuItem onClick={handleToggleCollectionManagement} >
                    <p>Collection Management</p>
                  </MenuItem>
                  <Popper className={classes.CollectionMenuList} open={openCollectionManagement} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow {...TransitionProps}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleToggleCollectionManagement}>
                            <MenuList autoFocusItem={openMenu} id="menu-list-grow" >
                              <MenuItem onClick={handleCollectionManagementSelection}>
                                <Link to="/createCollection">Create Collection</Link>
                              </MenuItem>
                              <MenuItem onClick={handleCollectionManagementSelection}>
                                <Link to="/editCollection">Edit Collection</Link>
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                  <MenuItem onClick={handleMenuSelection}>
                    <Link to="/treatment">Treatment</Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuSelection}>
                    <Link to="/manualUpload">Manual Upload</Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuSelection}>
                    <Link to="/poolingManagement">Pooling Management</Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuSelection}>
                    <Link className={classes.logoutLink} onClick={onLogOut} to="/">Log out</Link>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <strong>
        {name === 'demonstrations' ?
          <p className={classes.headline}>&nbsp; AI-Monitoring Console Testing &nbsp; &nbsp;</p> :
          <p className={classes.headline}>&nbsp; AI-Monitoring Console &nbsp; &nbsp;</p>}
      </strong>
      <p className={classes.name}>Welcome {name}!</p>
      <IconButton className= {classes.refresh} onClick={() => setRefresh(true)}>
        <RefreshIcon />
      </IconButton>
      <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Add new rule'>
        <IconButton className={classes.newRuleIcon} onClick={addNewRule}>
          <AddIcon />
        </IconButton>
      </Tooltip>
      <IconButton onClick={handleClickNotifications} className={classes.notificationsIcon}>
        <CircleNotificationsSharpIcon />
        {userMessages && userMessages.length > 0 &&
          <div className={classes.notificationsCounter}>{userMessages.length}</div>
        }
      </IconButton>
      <IconButton onClick={handleClickRules} className={classes.rulesIcon}>
        <ViewListIcon />
      </IconButton>
      <Dialog open={openNotifications} onClose={() => setOpenNotifications(false)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
        <DialogContent>
          {userMessages && userMessages.map(n => displayNotification(n))}
        </DialogContent>
        <DialogActions className={classes.readNotificationBtn}>
          <ButtonComp name={"Mark as read"} action={handleReadNotification} />
        </DialogActions>
      </Dialog>
      <Dialog open={openRules} onClose={() => setOpenRules(false)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
        <DialogContent>
          {userRules && userRules.map(r => displayRules(r))}
          {loading &&
            <CircularProgress size={30} disableShrink />
          }
        </DialogContent>
      </Dialog>
      <InfoDialogComp message={"rule deleted successfully!"} open={deleted} handleClose={() => setDeleted(false)} />
      <NewRuleDialogComp open={openDialogNewRule} handleClose={handleCloseDialogNewRule} siteFromPopUp={siteFromPopUp} setSiteFromPopUp={setSiteFromPopUp} kindsList={kindsList} sitesId={sitesId} companyRuns={companyRuns} />
    </div >
  )
}