import React from 'react';
import { InfoWindow } from "@react-google-maps/api";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import classes from './CollectionPopUpComp.module.css';
import { IconButton, Tooltip } from "@material-ui/core";
import KindCounterTableComp from '../KindCounterTableComp/KindCounterTableComp';
import { restRequest } from '../../serverAPI/restRequests';
import ImageSearch from '@material-ui/icons/ImageSearch';
import { useHistory } from 'react-router-dom';
import DraggableDialog from '../../components/DialogComp/InfoDialogComp';
import CoronavirusIcon from '@mui/icons-material/Coronavirus';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';

export default function CollectionPopUpComp({ selectedSiteCollections, setSelectedSiteCollections, filterParams, collections, setCheckedCollections, StringToDate, setSiteFromPopUp, setSiteFromRule }) {

    const history = useHistory();
    const [coll_index, setColl_index] = React.useState(0);
    const [virus_index, setVirus_index] = React.useState(0);
    const [virus, setVirus] = React.useState(null);
    const [failViewInsect, setFailViewInsect] = React.useState(false);
    const [ConfirmationOpen, setConfirmationOpen] = React.useState(false);
    const [sitesVirus, setSitesVirus] = React.useState(null);

    const useStyles = makeStyles({
        tooltip: { fontSize: "1em" }
    });

    const toolTipClasses = useStyles();

    function prevColl() {
        setColl_index((coll_index - 1 + selectedSiteCollections.length) % selectedSiteCollections.length);
    }

    function nextColl() {
        setColl_index((coll_index + 1) % selectedSiteCollections.length);
    }

    function nextVirus() {
        setColl_index(selectedSiteCollections.findIndex(site => site._id == sitesVirus[virus_index]));
        setVirus_index((virus_index + 1) % sitesVirus.length);
    }

    async function getSiteViruses() {
        const response = await restRequest('getSiteViruses', 'post', { site_id: selectedSiteCollections[coll_index].site_id });
        setSitesVirus(response.data);
    }

    async function getCollectionVirus() {
        const response = await restRequest('getCollectionVirus', 'post', { collection_id: selectedSiteCollections[coll_index]._id });
        setVirus(response.data)
    }

    function onlyFull(collection_id) {
        const coll = collections.find(coll => coll._id === collection_id);
        if (coll.percentage !== 100 && coll.total > 0) {
            return false;
        }
        return true;
    }

    function onlyEmpty(collection_id) {
        const coll = collections.find(coll => coll._id === collection_id);
        if (coll !== undefined && coll.total > 0) {
            return false;
        }
        return true;
    }

    // redirects to identification page according to the filtered object and to the collection id
    function IdentificationClick(collection_id) {
        if ((!filterParams.insectID && onlyFull(collection_id) && filterParams.viewUnidentified) || (!filterParams.insectID && onlyEmpty(collection_id) && !filterParams.viewUnidentified)) {
            setConfirmationOpen(true);
            setTimeout(() => {
                setFailViewInsect(false)
            }, 3000);
        }
        else {
            let newchecked = [];
            newchecked.push(collection_id)
            setCheckedCollections(newchecked);
            history.replace("identification");
        }
    }

    React.useEffect(() => {
        setColl_index(0);
        getSiteViruses();
    }, [selectedSiteCollections]);

    React.useEffect(() => {
        getCollectionVirus();
    }, [selectedSiteCollections, coll_index])

    return (
        selectedSiteCollections[coll_index] ?
            <InfoWindow zIndex={100} position={{ lat: selectedSiteCollections[coll_index].site_lat, lng: selectedSiteCollections[coll_index].site_lng }} onCloseClick={() => { setSelectedSiteCollections(null); }}>
                <div className={classes.wrapper1}>
                    <div>
                        <IconButton className={classes.leftI} onClick={prevColl} disabled={coll_index === 0}>
                            <ArrowLeftIcon />
                        </IconButton>
                    </div>
                    <div className={classes.wrapper1}>
                        <div>
                            {virus &&
                                <h1 className={classes.virusInfo}>Virus detected: {virus} </h1>}
                            <h1 className={classes.insectInfo}>Site name: {selectedSiteCollections[coll_index].site_name} </h1>
                            <h1 className={classes.insectInfo}>Site id: {selectedSiteCollections[coll_index].mapVision_id ? selectedSiteCollections[coll_index].mapVision_id : selectedSiteCollections[coll_index].site_id} </h1>
                            <h1 className={classes.insectInfo}>Collection id: {selectedSiteCollections[coll_index]._id} </h1>
                            <h1 className={classes.insectInfo}>Placement date: {StringToDate(selectedSiteCollections[coll_index].placement_date)} </h1>
                            {(selectedSiteCollections[coll_index].state === 2) &&
                                // Force renderind the div everytime coll_index is changed
                                <div key={coll_index}>
                                    <h1 className={classes.insectInfo}>Pick Up date: {StringToDate(selectedSiteCollections[coll_index].pickup_date)} </h1>
                                    <KindCounterTableComp collection={selectedSiteCollections[coll_index]} />
                                </div>
                            }
                        </div>
                        <div className={classes.iconsDiv}>
                            <h1 className={classes.percentsH}>{Math.floor(selectedSiteCollections[coll_index].percentage)}%</h1>
                            {(selectedSiteCollections[coll_index].state !== 0) &&
                                <IconButton size={"small"} onClick={() => IdentificationClick(selectedSiteCollections[coll_index]._id)}>
                                    <ImageSearch />
                                </IconButton>
                            }
                            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Move to collection with a virus ' >
                                <span>
                                    <IconButton size={"small"} onClick={nextVirus} disabled={sitesVirus === null || sitesVirus.length == 0}>
                                        <CoronavirusIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Add new Rule ' >
                                <IconButton size={"small"} onClick={() => setSiteFromPopUp(selectedSiteCollections[coll_index].mapVision_id ? selectedSiteCollections[coll_index].mapVision_id : selectedSiteCollections[coll_index].site_id)}>
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                            <div className={classes.pageNumber}>
                                <label>{coll_index + 1}/{selectedSiteCollections.length} </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <IconButton className={classes.rightI} onClick={nextColl} disabled={coll_index === selectedSiteCollections.length - 1}>
                            <ArrowRightIcon />
                        </IconButton>
                    </div>
                    <DraggableDialog message={"No insects to view at the choosen collection that maches the filters"} open={ConfirmationOpen} handleClose={() => { setConfirmationOpen(false); }} />
                </div>
            </InfoWindow >
            : <div></div>
    )
}