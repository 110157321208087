
import { io } from "socket.io-client";
const axios = require('axios');
let host;
let socket;
switch (process.env.REACT_APP_ENV) {
  case 'PRODUCTION':
    host = 'https://www.senecio-robotics-app.com';
    break;
  case 'TESTING':
    host = 'http://34.123.166.247:2001';
    break;
  case 'DEVELOPMENT':
  default:
    host = 'http://localhost:3000';
    break;
}
export function restRequest(url, type, body, api = 'api') {
  return axios({
    // `url` is the server URL that will be used for the request
    url: `/${url}`,

    // `method` is the request method to be used when making the request
    method: type,

    // `baseURL` will be prepended to `url` unless `url` is absolute.
    baseURL: `${host}/${api}`,

    // `headers` are custom headers to be sent
    headers: { Authorization: "Bearer " + sessionStorage.getItem('token') },

    // `data` is the data to be sent as the request body
    // Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
    data: body,
  })
}


export const connectSocket = () => {
  if (!socket) {
    socket = io(host);
  }
  socket.on("connect", async () => {
    const storedSocketId = sessionStorage.getItem('socketId');
    if (storedSocketId !== null && storedSocketId !== socket.id) {
      sessionStorage.setItem('socketId', socket.id);
      await restRequest('updateSocket', 'post', { oldId: storedSocketId, newId: socket.id })
    }
  });
  return socket;
};

