import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import './SurveyPage.css';

const SurveyPage = () => {
    const [userId, setUserId] = useState(null);
    const [rating, setRating] = useState(1);
    const [comments, setComments] = useState('');
    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        setUserId(queryParams.get('userId'));
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!userId) {
            alert('User ID is missing');
            return;
        }
        const surveyData = {
            userId,
            rating,
            comments,
        };

        console.log(userId, rating, comments);
        let response = await restRequest('fillSurvey', 'post', surveyData);
        setSubmitted(true);
    };

    const getStarColorClass = (num) => {
        if (num <= rating) {
            if (rating <= 2) {
                return 'green';
            } else if (rating === 3) {
                return 'orange';
            } else if (rating >= 4) {
                return 'red';
            }
        }
        return '';
    };

    return (
        <div className="survey-container">
            <h1>סקר שבועי - הדברה באמצעות יתושים מעוקרים</h1>
            <h5>לקוח יקר,
                תודה שהחלטת להפסיק להיעקץ ולקחת חלק במהפכת הטיפול ביתושים של חברת איתן עמיחי הדברה מקבוצת רנטוקיל,
                מהפכה של הדברה ביולוגית ירוקה, מבוססת בינה מלאכותית ואוטומציה.
                על מנת שנוכל להעריך היטב את הצלחת הטיפול, נשלח אליכם את הסקר הזה בכל שבוע.
                חשוב! השאלות שלהלן מתייחסות אך ורק ליתוש הנמר האסייתי ולעקיצות שקורות במהלך שעות היום.
            </h5>
            <form onSubmit={handleSubmit}>
                <div className="rating-wrapper">
                    <div className="rating-label left">בקושי נעקצתי</div>
                    <div className="rating-container">
                        {[1, 2, 3, 4, 5].map((num) => (
                            <label key={num} className="rating-star">
                                <input
                                    type="radio"
                                    name="rating"
                                    value={num}
                                    checked={rating === num}
                                    onChange={() => setRating(num)}
                                />
                                <span className={`star ${getStarColorClass(num)}`}>O</span>
                            </label>
                        ))}
                    </div>
                    <div className="rating-label right">המצב בלתי נסבל</div>
                </div>
                <div className="comments-section">
                    <label htmlFor="comments" className="large-label">הערות נוספות:</label>
                    <textarea
                        id="comments"
                        value={comments}
                        onChange={(e) => setComments(e.target.value)}
                    />
                </div>
                <button type="submit">שלח</button>
            </form>
            {submitted && <p>תודה רבה לך, תגובתך נקלטה בהצלחה!</p>}
        </div>
    );
}

export default SurveyPage;
