import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { Alert } from '@material-ui/lab';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import DateComp from '../../components/DateComp/DateComp';
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import EditCollectionSitesMapComp from '../../components/MapComp/EditCollectionSitesMapComp';
import classes from '../EditCollectionPage/EditCollectionPage.module.css';

export default function EditCollectionPage() {
    // Options
    const [collections_ids, setCollections_ids] = useState([]);
    const [sites_ids, setSites_ids] = useState([]);
    const [collectors, setCollectors] = useState([]);
    const [purposes, setPurposes] = useState(["Permanent site", "Human", "Chicken", "Dead bird", "Pool"]);

    //Fields
    const [collection_id, setCollection_id] = useState(null);
    const [site_id, setSite_id] = useState(null);
    const [collector, setCollector] = useState(null);
    const [placement_date, setPlacement_date] = useState(null);
    const [pickup_date, setPickup_date] = useState(null);
    const [purpose, setPurpose] = useState(null);

    const [failAlert, setFailAlert] = useState(false);
    const [successAlert, setSuccessAlert] = useState(false);

    async function getCollectionInfo() {
        const response = await restRequest('getCollectionEditableInfo', 'post', { collection_id: collection_id });
        setSite_id(response.data.site);
        setCollector(response.data.collector);
        setPickup_date(response.data.pickup_date);
        setPlacement_date(response.data.placement_date);
        setPurpose(response.data.purpose);
    }

    async function getCollectionsIds() {
        const response = await restRequest('getCompanyCollectionsIds', 'get');
        setCollections_ids(response.data);
    }

    async function handleSaveNewCollectionInfo() {
        try {
            await restRequest('SaveCollectionChanges', 'post', { collection_id: collection_id, site_id: site_id, collector: collector, placement_date: placement_date, pickup_date: pickup_date, purpose: purpose });
            setSuccessAlert(true);
            setTimeout(() => {
                setSuccessAlert(false)
            }, 3000);
        } catch (error) {
            setFailAlert(true);
            setTimeout(() => {
                setFailAlert(false)
            }, 3000);
        }
    }

    useEffect(() => {
        getCollectionsIds();
    }, []);

    useEffect(() => {
        if (collection_id !== null) {
            getCollectionInfo();
        } else {
            setCollector(null);
            setPurpose(null);
            setPickup_date(null);
            setPlacement_date(null);
            setSite_id(null);
        }
    }, [collection_id])

    return (
        <div className={classes.wrapper}>
            <div className={classes.frame}>
                <ComboBoxComp className={classes.collection_id} name={"Collection id:"} paramsList={collections_ids} value={collection_id} setValue={setCollection_id} />
                <div className={classes.wrapper1}>
                    <ComboBoxComp className={classes.collectionInfo} name={"Site id:"} paramsList={sites_ids} value={site_id} setValue={setSite_id} />
                    <ComboBoxComp className={classes.collectionInfo} name={"Collector username:"} paramsList={collectors} value={collector} setValue={setCollector} />
                    <DateComp className={classes.collectionDate} name={"Placement Date"} value={placement_date} setValue={setPlacement_date} />
                    <DateComp className={classes.collectionDate} name={"Pickup Date"} value={pickup_date} setValue={setPickup_date} />
                    <ComboBoxComp className={classes.collectionInfo} name={"Purpose:"} paramsList={purposes} value={purpose} setValue={setPurpose} />
                    <ButtonComp className={classes.save} name={"Save"} action={handleSaveNewCollectionInfo} isDisabled={collection_id === null} />
                </div>
            </div>
            <div className={classes.mapDiv}>
                <EditCollectionSitesMapComp selectedSite={site_id} setSelectedSite={setSite_id} />
                {failAlert &&
                    <Alert severity="error">Changes weren't save</Alert>
                }
                {successAlert &&
                    <Alert severity="success">Saved successfully!</Alert>
                }
            </div>
        </div>
    )
}