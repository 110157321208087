import React from 'react';
import { useEffect, useState } from 'react';
import "@reach/combobox/styles.css";
import { restRequest } from '../../serverAPI/restRequests';
import { MarkerClusterer, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip, CircularProgress, Popover } from '@material-ui/core';
import { InfoWindow } from "@react-google-maps/api";
import { ArrowUpwardSharp, ArrowDownwardSharp } from '@material-ui/icons';
import { Info, InfoTwoTone } from '@material-ui/icons';
import MapSharpIcon from '@material-ui/icons/MapSharp';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AddIcon from '@mui/icons-material/Add';
import CenterFocusStrongSharpIcon from '@material-ui/icons/CenterFocusStrongSharp';
import SatelliteSharpIcon from '@material-ui/icons/SatelliteSharp';
import SkipNextSharpIcon from '@material-ui/icons/SkipNextSharp';
import GradeIcon from '@mui/icons-material/Grade';
import GradeTwoToneIcon from '@mui/icons-material/GradeTwoTone';
import Search from './Components/Search';
import Locate from './Components/Locate';
import Deployed from './Components/Deployed';
import Processing from './Components/Processing';
import Completed from './Components/Completed';
import CollectionPopUpComp from '../CollectionPopUpComp/CollectionPopUpComp';
import InfoDialogComp from '../../components/DialogComp/InfoDialogComp';
import NewSiteDialogComp from '../../components/DialogComp/NewSiteDialogComp';
import EditSiteDialogComp from '../../components/DialogComp/EditSiteDialogComp';
import ConfirmationDialogComp from '../../components/DialogComp/ConfirmationDialogComp';
import NewRunDialogComp from '../../components/DialogComp/NewRunDialogComp';
import mapStyles from './CollectionsMapComp.module.css';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';

const libraries = ["places"];
const mapContainerStyle = {
    width: "99%",
    marginTop: "1.15%",
    height: "100%",
};

const defaultCenter = {
    lat: 38.885086,
    lng: -121.969131,
};

const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

const useStyles = makeStyles({
    tooltip: { fontSize: "1em" }
});

export default function MapComp({ collections, companyCollections, setCompanyCollections, setCollectionsWithEmptySites, doneLoadingCollections,
    managementMode, setManagementMode, selectDeployed, setSelectDeployed, selectProcessing, zoomInFilteredCollections, setZoomInFilteredCollections,
    setSelectProcessing, selectCompleted, setSelectCompleted, checkedCollections, filteredCollections, setFilteredCollections, center, setCenter,
    setCheckedCollections, positiveCollection, filterParams, collectionOfCheckedVirus, setCollectionOfCheckedVirus, collectionOfInsect, noSitesPopUp, setNoSitesPopUp,
    setCollectionOfInsect, emptySites, setEmptySites, setEmptySiteAddedFlag, sitesNamesList, setSitesNamesList, runsList, setRunsList, StringToDate, companyId, setSiteFromPopUp, siteFromRule, setSiteFromRule }) {

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBYcFcT4RNo0gFqnYVBRRC8kGBJ2S1SKGI",
        libraries,
    });

    // A comparison function that will result in dates being sorted in DESCENDING order.
    var date_sort_desc = function (coll1, coll2) {
        if (coll1.placement_date > coll2.placement_date) return -1;
        if (coll1.placement_date < coll2.placement_date) return 1;
        return 0;
    };

    const [mapType, setMapType] = useState("roadmap");
    const [index, setIndex] = useState(0);
    const [selectedSiteCollections, setSelectedSiteCollections] = useState(null); // storing the selected element on the map the user clicked on
    const [markers, setMarkers] = useState(new Map()); // the collections showing on the map
    const [zoomSelected, setZoomSelected] = useState([]);
    const [loadingVirus, setLoadingVirus] = useState(false);
    const [loadingInsectCollection, setLoadingInsectCollection] = useState(false);
    const [marker, setMarker] = useState(null);
    const [showPopup, setShowPopup] = useState(false); // popup on marker initialize
    const [changePercentage, setChangePercentage] = useState('');
    const [showAllPopups, setShowAllPopups] = useState(false);
    const [numberOfInsectInLastCollections, setNumberOfInsectInLastCollections] = useState([]);
    const [showPercentage, setShowPrecentage] = useState(0);
    const [siteId, setSiteId] = useState('');
    const [siteName, setSiteName] = useState('');
    const [siteAddress, setSiteAddress] = useState('');
    const [siteDirections, setSiteDirections] = useState('');
    const [siteLatitude, setSiteLatitude] = useState('');
    const [siteLongitude, setSiteLongitude] = useState('');
    const [containsCollection, setContainsCollection] = useState(true);
    const [runSites, setRunSites] = useState([]);
    const [openDialogNewRun, setOpenDialogNewRun] = useState(false);
    const [openDialogEditSite, setOpenDialogEditSite] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentMarker, setCurrentMarker] = useState(null);
    const [siteAddedConfirmation, setSiteAddedConfirmation] = useState(false);
    const [siteDeletedConfirmation, setSiteDeletedConfirmation] = useState(false);
    const [removeConfirmationOpen, setRemoveConfirmationOpen] = useState(false);
    const [siteEditedConfirmation, setSiteEditedConfirmation] = useState(false);
    const [newSiteConfirmationOpen, setNewSiteConfirmationOpen] = useState(false);
    const [newSiteLong, setNewSiteLong] = useState(null);
    const [newSiteLat, setNewSiteLat] = useState(null);
    const [newSiteId, setNewSiteId] = useState(null);
    const [newSiteName, setNewSiteName] = useState(null);
    const [newMapVisionId, setNewMapVisionId] = useState(null);
    const [newSiteAddress, setNewSiteAddress] = useState(null)
    const [newSiteDirections, setNewSiteDirections] = useState(null)
    const [newSiteFlag, setNewSiteFlag] = useState(null);
    const [addConfirmationOpen, setAddConfirmationOpen] = useState(false);
    const [deleteSiteFlag, setDeleteSiteFlag] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [searchedLocation, setSearchedLocation] = useState(null);
    const [clickTimer, setClickTimer] = useState(null);
    const [isRadiusClicked, setIsRadiusClicked] = useState(false);
    const mapRef = React.useRef();
    const [clustererKey, setClustererKey] = useState(0); // Key to force re-render of MarkerClusterer


    const handleAddRadius = () => {
        setIsRadiusClicked(!isRadiusClicked);
        setCenter(mapRef.current.getBounds());
    }

    async function getRunSites() {
        const response = await restRequest(`getAllCompanyRuns`, 'get', {});
        setRunSites(response.data);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchLocation = (location) => {
        setSearchedLocation(location);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'popup-buttons' : undefined;

    async function getTotalInsectsOfFilteredCollections() {
        let collectionsArray = [];

        // get only the last 2 collections of the site
        Array.from(markers.values()).map(coll => {
            if (coll[0]._id != 'empty') {
                collectionsArray.push([coll[0], coll[1]]);
            }
        });
        const response = await restRequest(`getFilteredCollectionData`, 'post', { collections: collectionsArray, filter_params: filterParams });
        setNumberOfInsectInLastCollections(response.data)
    }

    function handleYesAdd() {
        addSiteToRun();
    }

    const addNewRun = () => {
        setOpenDialogNewRun(true);
    }

    const handleCloseDialogNewRun = () => {
        setOpenDialogNewRun(false);
    };

    async function handleNoAdd() {
        setAddConfirmationOpen(false);
    }

    function handleYesRemove() {
        removeSiteFromRun();
    }

    async function handleNoRemove() {
        setRemoveConfirmationOpen(false);
    }

    const handleCloseDialogEdit = () => {
        setOpenDialogEditSite(false);
        setSiteId('');
        setSiteName('');
        setSiteAddress('');
        setSiteDirections('');
        setSiteLongitude('');
        setSiteLatitude('');
        setContainsCollection(true);
    };

    const editSite = (marker) => {
        if (marker && marker.length > 0 && marker[0].site_id) {
            setSiteId(marker[0].site_id);
            setSiteName(marker[0].site_name ? marker[0].site_name : '');
            setSiteAddress(marker[0].site_address ? marker[0].site_address : '');
            setSiteDirections(marker[0].site_directions ? marker[0].site_directions : '');
            setSiteLongitude(marker[0].site_lng ? marker[0].site_lng : '');
            setSiteLatitude(marker[0].site_lat ? marker[0].site_lat : '');
            setContainsCollection(marker[0].containsCollection)
            setOpenDialogEditSite(true);
        }
    }

    function zoomToSelected() {
        const selected = Array.from(markers.values()).filter(marker => marker.map(m => m._id === 'empty' ? m.site_id : m._id).filter(value => checkedCollections.includes(value)).length > 0);
        const bounds = new window.google.maps.LatLngBounds();
        selected.map(x => {
            if (x[0].site_lat > -89 && x[0].site_lat < 89 && x[0].site_lng > -179 && x[0].site_lng < 179) {
                bounds.extend(new window.google.maps.LatLng(x[0].site_lat, x[0].site_lng));
            }
        });
        // for DEBUG purposes - maybe there are more combinations that need to be handled
        // let tmp1 = [bounds.getNorthEast().lat(), bounds.getNorthEast().lng()];
        // let tmp2 = [bounds.getSouthWest().lat(), bounds.getSouthWest().lng()];
        // console.log("NorthEast: "+ tmp1.toString());
        // console.log("SouthWest: "+ tmp2.toString());
        mapRef.current.fitBounds(bounds);
    }

    function zoomToFilteredCollections() {
        const filteredIds = filteredCollections.map(coll => coll.site_id);
        const selected = Array.from(markers.values()).filter(marker => marker.map(m => m.site_id).filter(value => filteredIds.includes(value)).length > 0);
        const bounds = new window.google.maps.LatLngBounds();
        selected.map(x => {
            if (x[0].site_lat > -89 && x[0].site_lat < 89 && x[0].site_lng > -179 && x[0].site_lng < 179) {
                bounds.extend(new window.google.maps.LatLng(x[0].site_lat, x[0].site_lng));
            }
        });
        mapRef.current.fitBounds(bounds);
        setZoomInFilteredCollections(false);
    }

    const toolTipClasses = useStyles();

    const handleExitManagementMode = () => {
        setManagementMode("exit");
    }

    const handleEnterManagementMode = () => {
        setShowAllPopups(false);
        setManagementMode("enter");
    }

    useEffect(() => {
        if (!doneLoadingCollections) {
            return;
        }
        if (checkedCollections.length > 0) {
            setZoomSelected([checkedCollections[index]]);
        }
        setSelectedSiteCollections(null);

        if (collections && collections.length > 0) {
            getTotalInsectsOfFilteredCollections();

            // map of every site's collections
            var collectionsBySite = new Map();
            collections.forEach((coll) => {
                if (!collectionsBySite.has(coll.site_id)) {
                    collectionsBySite = collectionsBySite.set(coll.site_id, [coll]);
                }
                else {
                    collectionsBySite = collectionsBySite.set(coll.site_id, [...collectionsBySite.get(coll.site_id), coll]);
                }
            })

            for (var key of collectionsBySite.keys()) {
                let curr_coll = collectionsBySite.get(key);
                collectionsBySite = collectionsBySite.set(key, curr_coll.sort(date_sort_desc))
            }
            setMarkers(collectionsBySite);
            setClustererKey(clustererKey + 1)
            goToLocation();
        }
        else {
            setMarkers(new Map());
            setClustererKey(clustererKey + 1);
        }
    }, [collections.length]);

    useEffect(() => {
        if (zoomInFilteredCollections) {
            zoomToFilteredCollections();
        }
    }, [zoomInFilteredCollections])

    useEffect(() => {
        if (collectionOfCheckedVirus && collectionOfCheckedVirus.length > 0) {
            setLoadingVirus(true)
        }
        if (collectionOfInsect && collectionOfInsect.length > 0) {
            setLoadingInsectCollection(true);
        }
        getRunSites();
    }, [])

    function goToLocation() {
        if (collectionOfCheckedVirus && collectionOfCheckedVirus.length === 1) {
            let selected = Array.from(markers.values()).filter(marker => marker.map(m => m._id).filter(value => value === collectionOfCheckedVirus[0]).length > 0);
            if (selected.length > 0) {
                const lat = selected[0][0].site_lat;
                const lng = selected[0][0].site_lng;
                panTo({ lat, lng });
                setCheckedCollections(collectionOfCheckedVirus);
                setLoadingVirus(false);
                setCollectionOfCheckedVirus([]);
            }
        }
        if (collectionOfInsect && collectionOfInsect.length === 1) {
            let selected = Array.from(markers.values()).filter(marker => marker.map(m => m._id).filter(value => value === collectionOfInsect[0]).length > 0);
            if (selected.length > 0) {
                const lat = selected[0][0].site_lat;
                const lng = selected[0][0].site_lng;
                panTo({ lat, lng });
                setCheckedCollections(collectionOfInsect);
                setCollectionOfInsect(null);
                setLoadingInsectCollection(false);
            }
        }
    }

    // for avoiding re-renders we pass the mapRef
    async function getCompanyLocation() {
        const response = await restRequest(`getCompanyLocation`, 'get', {});
        mapRef.current.panTo({ lat: response.data.lat, lng: response.data.lng });
    }

    function setPopUpToPercentage() {
        if (showPercentage == 0) {
            setShowPrecentage(1);
        }
        else if (showPercentage == 1) {
            setShowPrecentage(2);
        }
        else {
            setShowAllPopups(false);
            setShowPrecentage(0);
        }
    }

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
        if (!(collectionOfCheckedVirus && collectionOfCheckedVirus.length === 1) && !(collectionOfInsect && collectionOfInsect.length === 1)) {
            goToMyLocation();
        }
    }, []);

    function addNewEmptySiteMarker() {
        // add new site to markers on map
        let allMarkers = markers;
        if (!allMarkers.has(newSiteId)) {
            allMarkers.set(newSiteId, [{ site_id: newSiteId, mapVision_id: newMapVisionId, site_lat: newSiteLat, site_lng: newSiteLong, site_name: newSiteName, containsCollection: false, site_address: newSiteAddress, site_directions: newSiteDirections, _id: 'empty', }]);
            setMarkers(allMarkers);
        }

        // add site to empty sites list
        let allEmptySites = emptySites;
        if (allEmptySites && allEmptySites.length >= 0) {
            allEmptySites.push({ name: newSiteName, mapVision_id: newMapVisionId, latitude: newSiteLat, longitude: newSiteLong, address: newSiteAddress, containsCollection: false, directions: newSiteDirections, _id: newSiteId });
            setEmptySites(allEmptySites);
            setEmptySiteAddedFlag(true); //needed?
        }

        let allCollections = collections;
        if (collections && collections.length >= 0) {
            allCollections.push({ site_id: newSiteId, site_lat: newSiteLat, mapVision_id: newMapVisionId, site_lng: newSiteLong, site_name: newSiteName, containsCollection: false, site_address: newSiteAddress, site_directions: newSiteDirections, _id: 'empty', })
            setCollectionsWithEmptySites(allCollections)
        }
    }
    function deleteEmptySiteMarker(siteIdToDelete) {
        let allMarkers = new Map(markers);
        if (allMarkers.delete(siteIdToDelete)) {
            setMarkers(allMarkers);
        }

        let allEmptySites = [...emptySites];
        let indexToDelete = allEmptySites.findIndex(site => site._id === siteIdToDelete);
        if (indexToDelete !== -1) {
            allEmptySites.splice(indexToDelete, 1);
            setEmptySites(allEmptySites);
        }

        let allcollections = [...collections];
        indexToDelete = allcollections.findIndex(site => site.site_id === siteIdToDelete);
        if (indexToDelete !== -1) {
            allcollections.splice(indexToDelete, 1);
            setCollectionsWithEmptySites(allcollections);
        }
        setConfirmDelete(true);
    }

    const onMapClick = React.useCallback((event) => {
        if (managementMode) {
            setNewSiteLong(event.latLng.lng());
            setNewSiteLat(event.latLng.lat());
            setNewSiteConfirmationOpen(true);
        }
    });

    useEffect(() => {
        if (newSiteFlag && newSiteId) {
            addNewEmptySiteMarker();
            setNewSiteFlag(null);
            setNewSiteName(null);
            setNewSiteId(null);
            setSiteAddedConfirmation(true);
        }
    }, [newSiteFlag])

    useEffect(() => {
        if (deleteSiteFlag) {
            deleteEmptySiteMarker(deleteSiteFlag);
            setDeleteSiteFlag(null);
        }
    }, [deleteSiteFlag])

    useEffect(() => {
        if (siteFromRule) {
            goTositeFromRules();
        }
    }, [siteFromRule,])

    function goToMyLocation() {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                if (mapRef && mapRef.current) {
                    mapRef.current.panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
                    mapRef.current.setZoom(10);
                }
            }, () => null
        );
    }

    // pin down when searching address
    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, []);

    if (loadError) return "Error loading maps";
    if (!isLoaded) return "Loading maps";

    function getIconURL(marker) {

        let iconURL = '';

        // collection with no sites
        if (marker[0]._id === 'empty' && managementMode) {
            iconURL = '/emptysite';
            if (filterParams !== null && filterParams.run !== null && filterParams.run !== undefined && filterParams.run !== '') {
                if (checkSitesRun(marker)) {
                    iconURL += '_inrun.svg';
                    return iconURL;
                }
            }
            if (mapType === 'hybrid') {
                iconURL += '_hybrid';
            }
            return iconURL + '.svg';
        }

        // not an empty collection but state is corrupted
        if (marker[0].state === null || marker[0].state === undefined) {
            return '/question_mark.svg';
        }

        // valid state
        if (marker[0].state === 0) {
            iconURL = '/deployed';
        }
        else if (marker[0].state === 1) {
            iconURL = '/processing';
        }
        else { // state == 2
            iconURL = '/completed';
        }

        // get icon color by priority: selected(red) > inRun(purple) > positive(green) > hybrid(white)
        let type = '';
        if (marker.map(m => m._id).filter(value => positiveCollection.includes(value)).length > 0) {
            type = '_positive';
        }
        else if (mapType === 'hybrid') {
            type = '_hybrid';
        }
        if (managementMode && filterParams !== null && filterParams.run !== null && filterParams.run !== undefined && filterParams.run !== '') {
            if (checkSitesRun(marker)) {
                type = '_inrun';
            }
        }
        if (marker.map(m => m._id === 'empty' ? m.site_id : m._id).filter(value => checkedCollections.includes(value)).length > 0) {
            type = '_selected';
        }
        return iconURL + type + '.svg';
    }

    function checkSitesRun(marker) {
        let runName = filterParams.run;
        if (runSites.length > 0 && runName !== null && runName !== '') {
            let sites = (runSites.filter(run => run.name == runName));
            if (sites.length > 0) {
                let siteIds = (sites[0].sites_ids);
                if (siteIds.includes(marker[0].site_id)) {
                    return true;
                }
            }
        }
        return false;
    }

    async function addSiteToRun() {
        let data = { name: filterParams.run, siteId: currentMarker[0].site_id }
        await restRequest('addSiteToRun', 'put', data);
        setAddConfirmationOpen(false);
        setSiteAddedConfirmation(true);
        getRunSites();
    }

    async function removeSiteFromRun() {
        let data = { name: filterParams.run, siteId: currentMarker[0].site_id }
        await restRequest('removeSiteFromRun', 'put', data);
        setRemoveConfirmationOpen(false);
        setSiteDeletedConfirmation(true);
        getRunSites();
    }

    const moveBetweenSites = () => {
        const selected = Array.from(markers.values()).filter(marker => marker.map(m => m._id === 'empty' ? m.site_id : m._id).filter(value => zoomSelected.includes(value)).length > 0);
        const bounds = new window.google.maps.LatLngBounds();
        selected.map(x => {
            if (x[0].site_lat > -89 && x[0].site_lat < 89 && x[0].site_lng > -179 && x[0].site_lng < 179) {
                bounds.extend(new window.google.maps.LatLng(x[0].site_lat, x[0].site_lng));
            }
        });
        let checked_index = ((index + 1) % checkedCollections.length);
        setIndex(checked_index)
        mapRef.current.fitBounds(bounds);
        setZoomSelected([checkedCollections[checked_index]])
    }

    function changeToRoad() {
        setMapType("roadmap");
    }

    function changeToSattelite() {
        setMapType("hybrid");
    }

    // get the change in percentages of the number of insects at the latest collection vs the former filtered one of the same site
    let changeP = '';
    let changeNum = '';

    function getChangePercentages(marker_tmp) {
        let percentage = '';
        let number = '';
        if (marker_tmp !== null && numberOfInsectInLastCollections.length > 0) {
            if (markers.get(marker_tmp.site_id)[1] === undefined || markers.get(marker_tmp.site_id)[1] === null) {
                return 0;
            }
            let siteId = marker_tmp.site_id;
            const curr_num_insects = numberOfInsectInLastCollections[siteId][1];
            const prev_num_insects = numberOfInsectInLastCollections[siteId][0];
            number = Math.abs(curr_num_insects - prev_num_insects);
            if (prev_num_insects === 0) {
                if (curr_num_insects === 0) {
                    percentage = 0;
                }
                else {
                    percentage = 100;
                }
            }
            else {
                if (curr_num_insects === 0) {
                    percentage = -100;
                }
                else {
                    const change = ((curr_num_insects / prev_num_insects) - 1) * 100;
                    percentage = change.toFixed(2);
                }
            }
        }
        changeP = percentage;
        changeNum = number;
        if (showPercentage == 1) {
            return percentage;
        }
        else {
            if (showPercentage == 2) {
                return number;
            }
            else return 0;
        }
    }

    const optionsImage = {
        imagePath: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
        gridSize: 35
    };

    let markersArray = Array.from(markers.values());

    function goTositeFromRules() {
        let siteMarker = markersArray.find(marker => marker[0].site_id === siteFromRule);
        if (siteMarker) {
            setSelectedSiteCollections(siteMarker);
        }
    }

    return (
        <GoogleMap clickableIcons={!doneLoadingCollections} mapTypeId={mapType} mapContainerStyle={mapContainerStyle} zoom={10} defaultCenter={defaultCenter} options={options} onClick={onMapClick} onLoad={onMapLoad} >
            {searchedLocation && (
                <Marker
                    position={searchedLocation}
                    icon={{
                        url: '/search_pin.svg', // Path to your custom icon
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(15, 15),
                        scaledSize: new window.google.maps.Size(30, 30),
                    }}
                />
            )}
            <MarkerClusterer options={optionsImage} key={clustererKey} >
                {(clusterer) =>
                    // gets iterator return array
                    markersArray.map((marker, index) => (
                        <div>
                            <Marker
                                optimized={true}
                                key={marker.key}
                                position={{ lat: marker[0].site_lat, lng: marker[0].site_lng }}
                                clusterer={clusterer}
                                noClustererRedraw={true}
                                onDblClick={() => {
                                    // Clear the single click timer
                                    if (clickTimer) {
                                        clearTimeout(clickTimer);
                                        setClickTimer(null);
                                    }

                                    const { site_lat, site_lng } = marker[0];
                                    if (mapRef.current) {
                                        const currentMap = mapRef.current;
                                        currentMap.panTo({ lat: site_lat, lng: site_lng });
                                        currentMap.setZoom(20);
                                    }
                                }}
                                onClick={() => {
                                    // Set a timer for a single click action
                                    const timer = setTimeout(() => {
                                        if (filterParams !== null && filterParams.run !== null && filterParams.run !== undefined && filterParams.run !== '' && managementMode) {
                                            setCurrentMarker(marker);
                                            if (checkSitesRun(marker)) {
                                                setRemoveConfirmationOpen(true);
                                            } else {
                                                setAddConfirmationOpen(true);
                                            }
                                        } else if (filterParams.run === null && managementMode) {
                                            editSite(marker);
                                        } else if (marker[0]._id !== 'empty') {
                                            setSelectedSiteCollections(marker);
                                        }
                                    }, 300); // Adjust the delay to your preference (in milliseconds)

                                    // Store the timer in the state
                                    setClickTimer(timer);
                                }}

                                icon={{
                                    url: (getIconURL(marker)),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 15),
                                    scaledSize: new window.google.maps.Size(30, 30),
                                }}
                                onMouseOver={() => {
                                    if (marker[0]._id != 'empty') {
                                        setMarker(marker[0]);
                                        setChangePercentage(getChangePercentages(marker[0]));
                                        setShowPopup(true);
                                    }
                                }}
                                onMouseOut={() => {
                                    if (marker[0]._id != 'empty') {
                                        setChangePercentage('');
                                        setShowPopup(false);
                                    }
                                }}
                            />
                            {showAllPopups && marker[0]._id !== 'empty' &&
                                <InfoWindow key={index} position={{ lat: marker[0].site_lat, lng: marker[0].site_lng }} options={{ pixelOffset: new window.google.maps.Size(0, -15), disableAutoPan: true }}>
                                    <div style={{ paddingLeft: 21, fontSize: 20, display: 'flex', flexDirection: 'row' }}>
                                        {showPercentage == 0 &&
                                            <div style={{ paddingBottom: 5 }}>
                                                {marker[0].total.toString()}
                                            </div>
                                        }
                                        {getChangePercentages(marker[0]) > 0 &&
                                            <div className={mapStyles.positiveChange}>
                                                {showPercentage == 1 &&
                                                    <div style={{ paddingBottom: 5 }}>
                                                        {changeP.toString() + "%"}
                                                    </div>
                                                }
                                                {!showPercentage == 2 &&
                                                    <div style={{ paddingBottom: 5 }}>
                                                        {changeNum.toString()}
                                                    </div>
                                                }
                                                <ArrowUpwardSharp style={{ fontSize: 25 }} />
                                            </div>
                                        }
                                        {changeP < 0 &&
                                            <div className={mapStyles.negativeChange}>
                                                {showPercentage == 1 &&
                                                    <div style={{ paddingBottom: 5 }}>
                                                        {changeP.toString() + "%"}
                                                    </div>
                                                }
                                                {!showPercentage == 2 &&
                                                    <div style={{ paddingBottom: 5 }}>
                                                        {changeNum.toString()}
                                                    </div>
                                                }
                                                <ArrowDownwardSharp style={{ fontSize: 25 }} />
                                            </div>
                                        }
                                        {changeP == 0 && showPercentage == 1 &&
                                            <div className={mapStyles.positiveChange}>
                                                {"0%"}
                                            </div>
                                        }
                                        {changeP == 0 && showPercentage == 2 &&
                                            <div className={mapStyles.positiveChange}>
                                                {"0"}
                                            </div>
                                        }
                                    </div>
                                </InfoWindow>
                            }
                        </div>
                    ))
                }
            </MarkerClusterer>
            {selectedSiteCollections &&
                <CollectionPopUpComp selectedSiteCollections={selectedSiteCollections} setSelectedSiteCollections={setSelectedSiteCollections} filterParams={filterParams} setCheckedCollections={setCheckedCollections} collections={collections} StringToDate={StringToDate} setSiteFromPopUp={setSiteFromPopUp} />
            }
            {showPopup && !showAllPopups &&
                <InfoWindow position={{ lat: marker.site_lat, lng: marker.site_lng }} options={{ pixelOffset: new window.google.maps.Size(0, -15) }}>
                    <div style={{ paddingLeft: 21, fontSize: 20, display: 'flex', flexDirection: 'row' }}>
                        {marker != null && <div style={{ paddingBottom: 5 }}>
                            {marker.total.toString()}
                        </div>
                        }
                    </div>
                </InfoWindow>
            }
            <NewSiteDialogComp open={newSiteConfirmationOpen} handleClose={() => { setNewSiteConfirmationOpen(false) }} companyId={companyId} setNewMapVisionId={setNewMapVisionId} siteLong={newSiteLong} siteLat={newSiteLat} setSiteId={setNewSiteId} setNewSiteName={setNewSiteName} setNewSiteDirections={setNewSiteDirections} setNewSiteAddress={setNewSiteAddress} setNewSiteFlag={setNewSiteFlag} />
            <div className={mapStyles.mapDiv}>
                <div className={mapStyles.stateFilterButtonsDiv}>
                    <Deployed selectDeployed={selectDeployed} setSelectDeployed={setSelectDeployed} disable={managementMode || !doneLoadingCollections} />
                    <Processing selectProcessing={selectProcessing} setSelectProcessing={setSelectProcessing} disable={managementMode || !doneLoadingCollections} />
                    <Completed selectCompleted={selectCompleted} setSelectCompleted={setSelectCompleted} disable={managementMode || !doneLoadingCollections} />
                    {!doneLoadingCollections &&
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Loading map'>
                            <IconButton size='large' variant="contained" style={{ marginLeft: "10%", marginTop: "1%", backgroundColor: "whitesmoke" }} disabled={false}>
                                <CircularProgress size={24} disableShrink />
                            </IconButton>
                        </Tooltip>
                    }
                </div>
                <div className={mapStyles.locationsOfTheMapDiv}>
                    <Search panTo={panTo} handleSearchLocation={handleSearchLocation} />
                </div>
                <div className={mapStyles.location}>
                    <Locate panTo={panTo} />
                </div>
                <div className={mapStyles.jumpToCollectionsDiv}>
                    <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Add sites in current radius to map'>
                        <span className={mapStyles.buttonSpan}>
                            <IconButton style={{ backgroundColor: isRadiusClicked ? 'rgba(0, 0, 0, 0.12)' : 'transparent' }} onClick={handleAddRadius} disabled={!doneLoadingCollections}>
                                <AutoFixHighIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Run managment'>
                        <span className={mapStyles.buttonSpan}>
                            <IconButton style={{ backgroundColor: managementMode ? 'rgba(0, 0, 0, 0.12)' : 'transparent' }} onClick={handleClick} disabled={!doneLoadingCollections}>
                                <DirectionsRunIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
                        <div className={mapStyles.buttonPopOver}>
                            <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Add new run'>
                                <span className={mapStyles.buttonSpan}>
                                    <IconButton onClick={addNewRun}>
                                        <AddIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            {managementMode &&
                                <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Exit management mode'>
                                    <span className={mapStyles.buttonSpan}>
                                        <IconButton onClick={handleExitManagementMode} >
                                            <GradeIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            }
                            {!managementMode &&
                                <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Enter site and run management mode'>
                                    <span className={mapStyles.buttonSpan}>
                                        <IconButton onClick={handleEnterManagementMode}>
                                            <GradeTwoToneIcon />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            }
                        </div>
                    </Popover>
                    {showAllPopups && showPercentage == 0 &&
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Show change in percantegas of all sites in last filtered collection'>
                            <span className={mapStyles.buttonSpan}>
                                <IconButton onClick={() => { setPopUpToPercentage() }} disabled={!doneLoadingCollections} >
                                    <Info />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    {showAllPopups && showPercentage == 1 &&
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Show change in numbers of all sites in last filtered collection'>
                            <span className={mapStyles.buttonSpan}>
                                <IconButton onClick={() => { setPopUpToPercentage() }} disabled={!doneLoadingCollections} >
                                    <Info />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    {showAllPopups && showPercentage == 2 &&
                        <span className={mapStyles.buttonSpan}>
                            <IconButton onClick={() => { setPopUpToPercentage() }} disabled={!doneLoadingCollections} >
                                <Info />
                            </IconButton>
                        </span>
                    }
                    {!showAllPopups &&
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Show number of all insects in filtered collection'>
                            <span className={mapStyles.buttonSpan}>
                                <IconButton disabled={numberOfInsectInLastCollections == [] || !doneLoadingCollections} onClick={() => { setShowAllPopups(true); }} >
                                    <InfoTwoTone />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Zoom to marked collections'>
                        <span className={mapStyles.buttonSpan}>
                            <IconButton onClick={zoomToSelected} disabled={checkedCollections.length === 0 || !doneLoadingCollections} >
                                <CenterFocusStrongSharpIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Move between marked sites'>
                        <span className={mapStyles.buttonSpan}>
                            <IconButton onClick={moveBetweenSites} disabled={checkedCollections.length === 0 || !doneLoadingCollections} >
                                <SkipNextSharpIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    {mapType === "hybrid" &&
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Road map'>
                            <span className={mapStyles.buttonSpan}>
                                <IconButton onClick={changeToRoad} >
                                    <MapSharpIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                    {mapType === "roadmap" &&
                        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Satellite map'>
                            <span className={mapStyles.buttonSpan}>
                                <IconButton onClick={changeToSattelite} >
                                    <SatelliteSharpIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    }
                </div>
            </div>
            {loadingVirus || loadingInsectCollection &&
                <CircularProgress size={70} className={mapStyles.spinner} shrinkAnimation={false} />
            }
            <div className={mapStyles.buttonPopOver}>
                <EditSiteDialogComp open={openDialogEditSite} handleClose={handleCloseDialogEdit} setDeleteSiteFlag={setDeleteSiteFlag} siteId={siteId} siteName={siteName} siteAddress={siteAddress} siteDirections={siteDirections} setSiteName={setSiteName} filteredCollections={filteredCollections} sitesNamesList={sitesNamesList}
                    setSitesNamesList={setSitesNamesList} setSiteEditedConfirmation={setSiteEditedConfirmation} companyCollections={companyCollections} setFilteredCollections={setFilteredCollections} setCompanyCollections={setCompanyCollections} collections={collections}
                    setCollections={setCollectionsWithEmptySites} containsCollection={containsCollection} siteLat={siteLatitude} siteLong={siteLongitude} />
                <NewRunDialogComp open={openDialogNewRun} handleClose={handleCloseDialogNewRun} runsList={runsList} setRunsList={setRunsList} />
            </div>
            <InfoDialogComp message={`Site added successfully`} open={siteAddedConfirmation} handleClose={() => setSiteAddedConfirmation(false)} />
            <ConfirmationDialogComp message={`Are you sure you want to add site ID ${currentMarker ? (currentMarker[0].mapVision_id ? currentMarker[0].mapVision_id : currentMarker[0].site_id) : ''} to ${filterParams.run}?`} open={addConfirmationOpen} handleYes={() => handleYesAdd()} handleNo={handleNoAdd} handleClose={() => { setAddConfirmationOpen(false); }} />
            <InfoDialogComp message={`Site deleted from ${filterParams.run}`} open={siteDeletedConfirmation} handleClose={() => setSiteDeletedConfirmation(false)} />
            <ConfirmationDialogComp message={`Are you sure you want to remove site ID ${currentMarker ? (currentMarker[0].mapVision_id ? currentMarker[0].mapVision_id : currentMarker[0].site_id) : ''} from ${filterParams.run}?`} open={removeConfirmationOpen} handleYes={() => handleYesRemove()} handleNo={handleNoRemove} handleClose={() => { setSiteDeletedConfirmation(false); }} />
            <InfoDialogComp message={`the site was deleted successfully`} open={confirmDelete} handleClose={() => setConfirmDelete(false)} />
            <InfoDialogComp message={`Site edited successfully`} open={siteEditedConfirmation} handleClose={() => setSiteEditedConfirmation(false)} />
            <InfoDialogComp message={`No sites were found`} open={noSitesPopUp} handleClose={() => setNoSitesPopUp(false)} />
        </GoogleMap >
    );
}