import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
        return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: '', numeric: false, disablePadding: false, label: '' },
    { id: 'genus', numeric: false, disablePadding: false, label: 'Genus' },
    { id: 'species', numeric: false, disablePadding: false, label: 'Species' },
    { id: 'sex', numeric: false, disablePadding: false, label: 'Sex' },
    { id: 'amount', numeric: false, disablePadding: false, label: 'Amount' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'default'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: '50%',
    left: '20%', 
    bottom: 0, 
  },
  paper: {
    width: '99%',
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: 1,
  },
}));

export default function EnhancedTable({ checkedInsect, setCheckedInsect, insects }) {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('insectName');
  const [page, setPage] = useState(0);
  const rowsPerPage = 6;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, InsectId) => {
    if (checkedInsect !== null && checkedInsect._id === InsectId){
        setCheckedInsect(null);
    }
    else if (checkedInsect !== null){
        setCheckedInsect(null);
        setCheckedInsect(insects[InsectId]);
    }
    else {
        setCheckedInsect(insects[InsectId]);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const isSelected = (insectId) => (checkedInsect !== null && insectId === checkedInsect._id);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead classes={classes} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} rowCount={insects.length} />
            <TableBody>
              {stableSort(insects, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow hover onClick={(event) => handleClick(event, row._id)} role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row._id} selected={isItemSelected}>
                    <TableCell padding="checkbox">
                      <Checkbox checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                    </TableCell>
                    <TableCell className={classes.tableCell} align="left">{row.genus}</TableCell>
                    <TableCell className={classes.tableCell} align="left">{row.species}</TableCell>
                    <TableCell className={classes.tableCell} align="left">{row.sex}</TableCell>
                    <TableCell className={classes.tableCell} align="left">{row.amount}</TableCell>
                  </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{display: "flex", flexDirection: "row"}}>
          <div>
            <TablePagination labelRowsPerPage={""} rowsPerPageOptions={[]} component="div" count={insects.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} />
          </div>
        </div>
      </Paper>
    </div>
  );
}