import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { Alert, AlertTitle } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { DialogTitle } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import InfoDialogComp from '../../components/DialogComp/InfoDialogComp';

export default function NewRunDialogComp({ open, handleClose, setRunsList }) {
    const [runName, setRunName] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [runAddedConfirmation, setRunAddedConfirmation] = useState(false);

    function handleCloseDialog() {
        setRunName(null);
        setShowAlert(false);
        handleClose();
    }

    async function handleSaveDialog() {
        let filtersBasicValues = JSON.parse(window.sessionStorage.getItem('filtersBasicValues'));
        if (!filtersBasicValues || !filtersBasicValues.runsName){
            setShowAlert(true);
            setAlertText("failed to load runs");
            setTimeout(() => {
                setShowAlert(false)
            }, 3000);   
        }
        else if (filtersBasicValues.runsName.includes(runName)){
            setShowAlert(true);
            setAlertText("name is already taken");
            setTimeout(() => {
                setShowAlert(false)
            }, 3000);
        }
        else {
            const data = {old_name: null, old_type: null, new_name: runName, sites: []}
            await restRequest('putRun', 'put', data);
            setRunsList(runsList => [...runsList, runName]);
            filtersBasicValues.runsName.push(runName);
            window.sessionStorage.setItem('filtersBasicValues', JSON.stringify(filtersBasicValues));
            handleCloseDialog();
            setRunName(null);
            setRunAddedConfirmation(true);
        }
    }

    return (
        <div>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Add New Run</DialogTitle>
                <DialogContent>
                    <TextFieldComp value={runName} setValue={setRunName} label={"Run name"} typeOfTextField={"standard"} required={true}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveDialog} color="primary" disabled={runName === null || runName === ''}>
                        Save
                    </Button>
                </DialogActions>
                {showAlert &&
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {alertText}
                    </Alert>
                }
            </Dialog>
            <InfoDialogComp message={`Run created successfully`} open={runAddedConfirmation} handleClose={() => setRunAddedConfirmation(false)} />
        </div>
    );
}