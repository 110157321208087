import { AppBar, Toolbar } from "@material-ui/core";
import React from "react";
import LoggedInNav from "./LoggedInNav";
import classes from "./Nav.module.css";

export default function Nav({ loggedUser, name, socket, onLogOut, setCollectionOfCheckedVirus, setRefresh, kindsList, sitesId, companyRuns, siteFromPopUp, setSiteFromPopUp, siteFromRule, setSiteFromRule, userMessages, setUserMessages, userRules, setUserRules }) {
  return (
    <AppBar position='static'>
      <Toolbar className={name === 'demonstrations' ? classes.navbarTesting : classes.navbar}>
        {loggedUser &&
          <LoggedInNav setRefresh={setRefresh} loggedUser={loggedUser} siteFromPopUp={siteFromPopUp} setSiteFromPopUp={setSiteFromPopUp} name={name} socket={socket} onLogOut={onLogOut} setCollectionOfCheckedVirus={setCollectionOfCheckedVirus} kindsList={kindsList}
            sitesId={sitesId} companyRuns={companyRuns} siteFromRule={siteFromRule} setSiteFromRule={setSiteFromRule} userMessages={userMessages} setUserMessages={setUserMessages} userRules={userRules} setUserRules={setUserRules} />}
      </Toolbar>
    </AppBar>
  );
}