import classes from './ProgressBarComp.module.css';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

export default function ProgressBar({ current, total }) {
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 10,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        },
        [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 5,
            backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
        },
    }));

    const interval = 100 / total;
    var curr = current * interval;
    return (<div className={classes.progress}>
        <label className={classes.lable}>
            {`${current}/${total}`}
        </label>
        <BorderLinearProgress variant="determinate" value={curr} />
    </div>)
}
