import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function TrapTypeComboBox({className, name, paramsList, value, setValue, trap, traps, required}) {
    function changeTraps(event, type) {
        if (event !== undefined) {
            let tmp = [...traps];
            for (let i = 0; i < traps.length; i++) {
                if (traps[i].trap_name === trap.trap_name) {
                    tmp[i].trap_type = type;
                    setValue(tmp);
                    return;
                }
            }
        }
    }

    return (
        <Autocomplete 
            className={className}
            value={value}
            required={required}
            onChange={(event, type) => {changeTraps(event, type);}} 
            options={paramsList !== null || paramsList !== undefined ? paramsList : ['']}
            getOptionLabel={(option) => option} 
            renderInput={(params) => <TextField InputProps={{ style: { fontSize: 300} }} {...params} size='small' label={name} variant="outlined"/> }/>                
    );
}