import React, { useState, useEffect } from "react";
import classes from './PoolingManagementPage.module.css'
import { restRequest } from '../../serverAPI/restRequests';
import MultipleComboBox from '../../components/ComboBoxComp/MultipleComboBox';
import PoolingTableComp from '../../components/PoolingTableComp/PoolingTableComp';
import { Alert } from '@material-ui/lab';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from "@material-ui/core";
import InfoDialogComp from '../../components/DialogComp/InfoDialogComp';
import ProgressBarComp from "../../components/ProgressBarComp/ProgressBarComp";

export default function PoolingManagmentPage() {

  const [checkedPooling, setCheckedPooling] = useState([]);
  const [pooling, setPooling] = useState(null);
  const [companyPooling, setCompanyPooling] = useState([]);
  const [barcode, setBarcode] = useState([]);
  const [barcodesList, setBarcodesList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [allVials, setAllVials] = useState(0);
  const [currentVials, setCurrentVials] = useState(0);
  const [vialsWithNoResult, setVialsWithNoResult] = useState([]);
  const [successAlert, setSuccessAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const virusListYolo = ["WNV", "DNV", "RVF", "CHIKV"];
  const virusListTammy = ["WNV", "SLE", "EEE"];
  const [virusList, setVirusList] = useState([]);

  const [virusTested, setVirusTested] = useState([]);
  const [result, setResult] = useState(false);
  const [virusExists, setvirusExists] = useState(false);

  async function getPooling() {
    const response = await restRequest('getCompanyPooling', 'get', {});
    setPooling(response.data[0]);
    setVialsWithNoResult(response.data[1].length - response.data[2].length);
    setAllVials(response.data[2]);
    setCompanyPooling(response.data[0]);
    setBarcodesList(response.data[1]);
    setCurrentVials(0);
  }

  function filterPoolingTable() {
    let tmp_pooling = [];
    for (let i = 0; i < pooling.length; i++) {
      if (pooling[i]._id in barcode) {
        tmp_pooling.push(pooling[i]);
      }
    }
    setPooling(tmp_pooling);
  }

  async function CheckIfExists(barcode, test) {
    for (let i = 0; i < pooling.length; i++) {
      for (let j = 0; j < barcode.length; j++) {
        if (pooling[i]._id === barcode[j]) {
          for (let x = 0; x < test.length; x++) {
            if (pooling[i].virus === test[x]) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  async function handleSave() {
    setLoading(true);
    const data = {
      vial_barcode: barcode,
      test: virusTested,
      result: result === 'Positive'
    }
    let res = await CheckIfExists(data.vial_barcode, data.test);
    setvirusExists(res);
    if (!res) {
      let testadded = await restRequest('addTest', 'post', data);
      let newPoolingList = pooling;
      let vialsWitheRes = allVials;
      testadded.data.forEach(test => {
        newPoolingList.push(test);
        if (!allVials.includes(test._id)) {
          setCurrentVials(currentVials + 1);
          vialsWitheRes.push(test._id);
          setAllVials(vialsWitheRes)
        }
      });
      setPooling(newPoolingList);
    }
    setLoading(false);
    setBarcode([]);
    setResult(false);
    setVirusTested([]);
  }

  useEffect(() => {
    let companyId = window.sessionStorage.getItem('company_id');
    if (companyId === '65c89d2345878bbb080bd6b1' || companyId === "65c89b6f45878bbb080bd6b0") {
      setVirusList(virusListTammy);
    }
    else { setVirusList(virusListYolo) }
    getPooling();
    if (barcode.length > 0) {
      filterPoolingTable();
    }
    else
      setPooling(companyPooling);
  }, []);

  return (
    <div>
      <div style={{ height: '90%', width: '90%', marginTop: '5%', marginLeft: '5%' }}>
        <div className={classes.wrapper1}>
          <MultipleComboBox className={classes.vials} name={"Vial barcode:"} paramsList={barcodesList} value={barcode} setValue={setBarcode} />
          <MultipleComboBox className={classes.vials} name={"Virus tested:"} paramsList={virusList} value={virusTested} setValue={setVirusTested} />
          <ComboBoxComp className={classes.vialInfo} name={"Result"} paramsList={['Positive', 'Negative']} value={result} setValue={setResult} />
          <IconButton className={classes.save} onClick={handleSave} disabled={pooling !== null && result === null || barcode.length === 0 || virusTested.length === 0} >
            <AddIcon />
          </IconButton>
        </div>
        <div style={{ width: '90%', marginTop: '5%', marginLeft: '5%' }}>
          {pooling &&
            <PoolingTableComp setPooling={setPooling} pooling={pooling} />
          }
        </div>
      </div>
      <div className={classes.prograssBar}>
        <ProgressBarComp current={currentVials} total={vialsWithNoResult} />
      </div>
      {successAlert &&
        <Alert severity="success">Test Added successfully!</Alert>
      }
      {
        <InfoDialogComp message={"test already exists"} open={virusExists} handleClose={() => setvirusExists(!virusExists)} />
      }
    </div>
  )
}