import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { DialogTitle, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import InfoDialogComp from '../DialogComp/InfoDialogComp';
import ButtonComp from '../ButtonComp/ButtonComp';
import { IconButton } from '@material-ui/core';
import classes from './NewSiteDialogComp.module.css';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import Checkbox from '../CheckBoxComp/CheckBoxComp';

export default function EditClientDialogComp({ handleClose, landmarkId, setFlagNewLAndmarks }) {
    const [addedConfirmation, setAddedConfirmation] = useState(false);
    const [clientName, setClientName] = useState(null);
    const [numberOfRolls, setNumberOfRolls] = useState(null);
    const [notes, setNotes] = useState(null);
    const [inside, setInside] = useState(false);
    const [cellphone, setCellphone] = useState(null);
    const [email, setEmail] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);
    const [openNotes, setOpenNotes] = useState(false);
    const [editId, setEditId] = useState(null);

    const closeAll = () => {
        handleClose();
        setNumberOfRolls(null);
        setClientName(null);
        setStartTime(null);
        setEndTime(null);
        setNotes(null);
    }

    const handleCloseDialog = () => {
        closeAll();
        setAddedConfirmation(false);
    }


    async function getLandmark() {
        console.log(landmarkId);
        const response = await restRequest('getLandmarkById', 'post', { landmarkId: landmarkId });
        setClientName(response.data.clientName);
        setEmail(response.data.email);
        setNumberOfRolls(response.data.rollsNumber);
        setCellphone(String(response.data.cellPhone));
        setStartTime(response.data.startTime);
        setEndTime(response.data.endTime);
        setNotes(response.data.notes);
        setEditId(response.data._id);
    }

    async function saveChanges() {
        const response = await restRequest('editClient', 'post', { landmarkId: editId, clientName: clientName, rollsNumber: numberOfRolls, email: email, cellPhone: cellphone, notes: notes, inside: inside, startTime: startTime, endTime: endTime });
        setAddedConfirmation(true);
        setFlagNewLAndmarks(prev => (prev + 1))
    }

    useEffect(() => {
        if (landmarkId !== null) {
            getLandmark();
        }
    }, [landmarkId])

    return (
        <div>
            <Dialog
                open={clientName !== null}
                onClose={handleCloseDialog}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle>Edit Client</DialogTitle>
                <DialogContent className={classes.container}>
                    <TextFieldComp value={clientName} setValue={setClientName} label={"Client name"} />
                    <TextFieldComp value={numberOfRolls} setValue={setNumberOfRolls} label={"Number of spreads"} type={"number"} />
                    <TextFieldComp value={email} setValue={setEmail} label={"Email"} />
                    <TextFieldComp value={cellphone} setValue={setCellphone} label={"Cellphone"} />
                    <Checkbox label={"Spread inside"} checked={inside} onChange={() => setInside(!inside)} />
                    <TextFieldComp value={startTime} setValue={setStartTime} label={"Start time"} />
                    <TextFieldComp value={endTime} setValue={setEndTime} label={"End time"} />
                    {openNotes && <TextField
                        style={{ marginTop: "3px" }}
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                    />}
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={() => setOpenNotes(!openNotes)}>
                        <AddIcon />
                    </IconButton>
                    <ButtonComp name={'Save'} action={saveChanges} />
                </DialogActions>
            </Dialog >
            <InfoDialogComp message={'Client Information Saved successfully '} open={addedConfirmation} handleClose={handleCloseDialog} />
        </div >
    );
}