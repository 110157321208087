import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import classes from '../CollectionsMapComp.module.css';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tooltip: {fontSize: "1em"}
});

export default function Processing({ selectProcessing, setSelectProcessing, disable }) {
    const toolTipClasses = useStyles();

    return (
        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Processing' placement='right'>
            <IconButton
                size='medium'
                variant="contained"
                color={selectProcessing ? "primary" : "default"}
                className={classes.processing}
                disabled={disable}
                onClick={() => { setSelectProcessing(!selectProcessing) }} >
                <HourglassEmptyIcon />
            </IconButton>
        </Tooltip>
    )
}