import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

export default function DateComp({className, name, value, setValue, isDisabled }) {
  
  const handleDateChange = (date) => {
    setValue(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid>
        <KeyboardDatePicker 
          className={className}
          disabled={isDisabled} 
          id="date-picker-dialog" 
          label={name} 
          format="MM/dd/yyyy" 
          value={value} 
        onChange={handleDateChange}
          KeyboardButtonProps={{'aria-label': 'change date'}}/>
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
