import React, { useEffect, useState } from "react";
import { InfoWindow, DirectionsService } from '@react-google-maps/api';
import InfoDialogComp from '../DialogComp/InfoDialogComp';
import SkipNextSharpIcon from '@material-ui/icons/SkipNextSharp';
import { IconButton } from '@material-ui/core';
import classes from './SitePopUpComp.module.css';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { restRequest } from '../../serverAPI/restRequests';
import TextField from '@material-ui/core/TextField';
import { DialogTitle, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import SwitchComp from '../../components/SwitchComp/SwitchComp';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function DirectionsPopUp({ directionsResponse, landmarks }) {
    const [currentStep, setCurrentStep] = useState(0); // State to track current step
    const [currentStepInfo, setCurrentStepInfo] = useState(null);
    const [currentDirections, setCurrentDirections] = useState(0);
    const [currentLandmark, setCurrentLandmark] = useState(null);
    const [doneSpread, setDoneSpread] = useState(false);
    const [spreadTime, setSpreadTime] = useState(0);
    const [rollsNumber, setRollsNumber] = useState(null);
    const [clientNotes, setClientNotes] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [clientAddress, setClientAddress] = useState(null);
    const [done, setDone] = useState('Done');
    const [addNotes, setAddNotes] = useState(null);
    const [nextClientName, setNextClientName] = useState(null);
    const [nextClientAddress, setNextClientAddress] = useState(null);
    const [showNextClient, setShowNextClient] = useState(true);
    const [cellPhone, setCellPhone] = useState(null);
    const [showNumber, setShowNumber] = useState(false);
    const [openNotes, setOpenNotes] = useState(false);
    const [inside, setInside] = useState(false);
    const [directionsBetweenTwoPoints, setDirectionsBetweenTwoPoints] = useState(null);
    const [userLocation, setUserLocation] = useState(null);
    const [distanceThreshold, setDistanceThreshold] = useState(10);
    const [currentDistance, setCurrentDistance] = useState(0);
    const [reachedLandmark, setReachedLandmark] = useState(false);

    const directionsCallback = (response) => {
        if (response !== null) {
            const step = response.routes[0].legs[0].steps[0];
            setCurrentStepInfo(step);
            setDirectionsBetweenTwoPoints(response);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
        setReachedLandmark(false);
        setAddNotes(null);
    };

    const updateCurrentStep = () => {
        if (!directionsResponse || !directionsResponse.routes || !directionsResponse.routes[0] || !directionsResponse.routes[0].legs) return;
        if (currentDirections < landmarks.length) {
            // if (directionsBetweenTwoPoints !== null) {
            //     const step = directionsBetweenTwoPoints.routes[0].legs[0].steps[0];
            //     setUserLocation({ lat: step.end_location.lat(), lng: step.end_location.lng() })
            // }
            setCurrentDirections(currentDirections + 1);
        }
    };

    const showNextDirections = () => {
        setShowNextClient(false);
    }

    const calculateDistance = (point1, point2) => {
        const R = 6371e3; // Earth's radius in meters
        const lat1 = point1.lat * (Math.PI / 180);
        const lat2 = point2.lat * (Math.PI / 180);
        const dLat = (point2.lat - point1.lat) * (Math.PI / 180);
        const dLng = (point2.lng - point1.lng) * (Math.PI / 180);

        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1) * Math.cos(lat2) *
            Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const distance = R * c; // Distance in meters
        return distance;
    };

    const handleLocationChange = () => {
        if (userLocation && currentLandmark) {
            const distance = calculateDistance(userLocation, currentLandmark);
            setCurrentDistance(distance);
            if (distance <= distanceThreshold) {
                if (currentDirections + 1 < directionsResponse.routes[0].legs.length && reachedLandmark === false) {
                    setReachedLandmark(true);
                    setCurrentDirections(currentDirections + 1);
                }
            }
        }
    };

    async function doneSpreading() {
        let landmark = landmarks[directionsResponse.routes[0].waypoint_order[currentDirections - 1]]
        if (done === 'Done') {
            const dateObject = new Date();
            let data = {
                'checkedLandmarks': [landmark], 'date': dateObject.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                })
            }
        }
        if (openNotes) {
            await restRequest('addNotesToClient', 'post', { landmark: landmark, notes: addNotes });
          
        }
        handleClose();
        if (currentDirections !== landmarks.length) {
            setShowNextClient(true);
            setRollsNumber(null);
            setClientNotes(null);
            setClientAddress(null);
            setCellPhone(null);
        }
        else {
            setCurrentStepInfo(null);
            setDoneSpread(true);
        }
    }

    const calculateTime = () => {
        let time = 0;
        for (let i = 0; i < directionsResponse.routes[0].legs.length; i++) {
            for (let j = 0; j < directionsResponse.routes[0].legs[i].steps.length; j++) {
                let timeAndMin = (directionsResponse.routes[0].legs[i].steps[j].duration.text).split(" ");
                time += parseInt(timeAndMin[0]);
            }
        }
        setSpreadTime((prev) => (1 + time));
    }

    // Call updateCurrentStep when directionsResponse changes
    useEffect(() => {
        if (directionsResponse !== null) {
            calculateTime();
        }
    }, [directionsResponse]);

    useEffect(() => {
        if (spreadTime > 0 && currentStepInfo === null) {
            updateCurrentStep(0); // Start at the first step when directions change
            setCurrentDirections(0);
        }
    }, [spreadTime]);

    useEffect(() => {
        let nextLandmark = landmarks[directionsResponse.routes[0].waypoint_order[currentDirections]];
        const step = directionsResponse.routes[0].legs[currentDirections].steps[0];
        setCurrentStepInfo(step);
        if (currentDirections === 0) {
            setCurrentLandmark({ lat: nextLandmark.lat, lng: nextLandmark.lng });
            setNextClientName(nextLandmark.clientName)
            setNextClientAddress(nextLandmark.address);
        }
        else {
            let prevLandmark = landmarks[directionsResponse.routes[0].waypoint_order[currentDirections - 1]];
            if (currentDirections < landmarks.length) {
                setCurrentLandmark({ lat: nextLandmark.lat, lng: nextLandmark.lng });
                setRollsNumber(prevLandmark.rollsNumber);
                setInside(prevLandmark.inside)
                setClientAddress(prevLandmark.address);
                setCellPhone(prevLandmark.cellPhone);
                setNextClientName(nextLandmark.clientName);
                setNextClientAddress(nextLandmark.address);
                prevLandmark.notes ? setClientNotes(prevLandmark.notes) : setClientNotes('');
                setAddNotes(prevLandmark.notes)
            }
            else if (currentDirections === landmarks.length) {
                setRollsNumber(prevLandmark.rollsNumber);
                setInside(prevLandmark.inside)
                setClientAddress(prevLandmark.address);
                setCellPhone(prevLandmark.cellPhone);
                prevLandmark.notes ? setClientNotes(prevLandmark.notes) : setClientNotes('');
                setAddNotes(prevLandmark.notes)
            }
        }
    }, [currentDirections])

    useEffect(() => {
        // if (navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition(
        //         (position) => {
        //             const lat = (position.coords.latitude);
        //             const lng = (position.coords.longitude);
        //             setUserLocation({ lat: lat, lng: lng });
        //         })
        // }
        // Get user's current location
        const watchId = navigator.geolocation.watchPosition(
            position => {
                setUserLocation({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            },
            error => {
                console.error("Error getting user location:", error);
            }
        );

        // Clear watch on component unmount
        return () => {
            navigator.geolocation.clearWatch(watchId);
        };
    }, []);

    // useEffect(() => {
    //     if (userLocation && currentLandmark && !reachedLandmark) {
    //         // Call DirectionsService only when both userLocation and currentLandmark are available

    //         const directionsService = new window.google.maps.DirectionsService();
    //         directionsService.route(
    //             {
    //                 origin: userLocation,
    //                 destination: currentLandmark,
    //                 travelMode: 'DRIVING',
    //                 optimizeWaypoints: true,
    //                 language: 'he'
    //             },
    //             (result, status) => {
    //                 if (status === window.google.maps.DirectionsStatus.OK) {
    //                     directionsCallback(result);
    //                 } else {
    //                     console.error("Directions request failed with status:", status);
    //                 }
    //             }
    //         );
    //         handleLocationChange();
    //     }
    // }, [userLocation, currentLandmark, reachedLandmark]);

    return (
        <div>
            {currentStepInfo && showNextClient === false && (
                <InfoWindow position={{ lat: currentStepInfo.start_location.lat(), lng: currentStepInfo.start_location.lng() }}>
                    <div className={classes.infoWindowContent}>
                        {showNextClient === false && <div >
                            {rollsNumber ?
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <IconButton className={classes.nextDirections} onClick={() => setOpenDialog(true)} >
                                        <TaskAltIcon />
                                    </IconButton>
                                    <IconButton className={classes.cellPhone} onClick={() => setShowNumber(!showNumber)} >
                                        <LocalPhoneIcon />
                                    </IconButton>
                                </div> :
                                <IconButton className={classes.nextDirections} onClick={updateCurrentStep}>
                                    <CheckBoxIcon />
                                </IconButton>}
                        </div>}
                        {rollsNumber ?
                            <div style={{ textAlign: "right" }}>
                                <h6><strong>{rollsNumber}</strong> : מספר מבחנות לפיזור</h6>
                                <h6><strong>{clientNotes}</strong> : הערות</h6>
                                {showNumber && <h6>פלאפון :  <strong>{cellPhone}</strong></h6>}
                            </div> :
                            <div style={{ textAlign: "right" }}>
                                <h5>?הגעת לכתובת {nextClientAddress} </h5>
                            </div>}

                    </div>
                </InfoWindow>
            )}
            <Dialog open={showNextClient}>
                <DialogContent>
                    <div style={{ textAlign: "right" }}>
                        <h6> הלקוח הבא : <strong>{nextClientName}</strong></h6>
                        <h6>כתובת : <strong>{nextClientAddress}</strong></h6>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={showNextDirections} color="primary">
                        ok
                    </Button>
                </DialogActions>
            </Dialog >
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle style={{ textAlign: "right" }}><strong> הגעת ל : {clientAddress} </strong></DialogTitle>
                <DialogContent style={{ textAlign: "right" }}>
                    <div style={{ display: 'flex', flexDirection: "row-reverse", justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            {done === 'Done' ?
                                <div style={{ display: 'flex', flexDirection: "column" }}>
                                    <h7><strong>{rollsNumber}</strong> :מספר מבחנות לפיזור</h7>
                                    {inside === true ? <h7>פיזור בפנים</h7> : <h7>פיזור בחוץ</h7>}
                                </div>
                                : ''}
                        </div>
                        <div>
                            <SwitchComp label={done} setLabel={setDone} primaryLbl={"Done"} secondaryLbl={"Not Done"} />
                        </div>
                    </div>
                    {openNotes &&
                        <TextField
                            style={{ marginTop: "3px", textAlign: "right" }}
                            id="outlined-multiline-static"
                            label="הערות"
                            multiline
                            rows={4}
                            value={addNotes}
                            onChange={(event) => setAddNotes(event.target.value)}
                        />}
                </DialogContent>
                <DialogActions >
                    <IconButton onClick={() => setOpenNotes(!openNotes)}>
                        <NoteAddIcon />
                    </IconButton>
                    <Button onClick={handleClose} color="primary">
                        ביטול
                    </Button>
                    <Button onClick={doneSpreading} color="primary">
                        שמור
                    </Button>
                </DialogActions>
            </Dialog >
            <InfoDialogComp message={'!סיימת את המסלול להיום'} open={doneSpread} handleClose={() => setDoneSpread(false)} />
        </div>
    );
}