import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function ComboBox({ className, name, paramsList, value, setValue, isDisabled }) {

    return (
        <Autocomplete
            multiple={true}
            limitTags={3}
            className={className}
            disabled={isDisabled}
            value={value}
            onChange={(event, newValue) => { setValue(newValue); }}
            options={paramsList}
            getOptionLabel={(option) => option}
            renderInput={(params) => <TextField InputProps={{ style: { fontSize: 300 } }} {...params} size='small' label={name} variant="outlined" />} />
    );
}