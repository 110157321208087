import React, { useEffect, useState } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { MarkerClusterer, GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import "@reach/combobox/styles.css";
import Search from './Components/Search';
import Locate from './Components/Locate';
import SitePopUpComp from '../PopUpComp/SitePopUpComp';
import SwitchComp from '../SwitchComp/SwitchComp';
import mapStyles from './CollectionsMapComp.module.css'

const libraries = ["places"];
const mapContainerStyle = {
    width: "99%",
    marginTop: "1.15%",
    height: "100%",
};
const center = {
    lat: 38.885086,
    lng: -121.969131,
};
const options = {
    disableDefaultUI: true,
    zoomControl: true,
};

export default function MapComp({ filteredSites, setFilteredSites, checkedSites, setAddSite }) {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: "AIzaSyBYcFcT4RNo0gFqnYVBRRC8kGBJ2S1SKGI",
        libraries,
    });

    // storing the selected element on the map the user clicked on
    const [selectedSite, setSelectedSite] = useState(null);
    const [clustererKey, setClustererKey] = useState(0);
    // the collections showing on the map 
    const [markers, setMarkers] = useState(new Map());

    const [switchLabel, setSwitchLabel] = useState("Filtered");
    const [allSitesByIds, setAllSitesByIds] = useState(null);

    // for avoiding re-renders we pass the mapRef
    const mapRef = React.useRef();

    async function getCompanyLocation() {
        const response = await restRequest(`getCompanyLocation`, 'get', {});
        mapRef.current.panTo({ lat: response.data.lat, lng: response.data.lng });
    }

    const onMapLoad = React.useCallback((map) => {
        mapRef.current = map;
        getCompanyLocation();
    }, []);

    // pin down when searching address
    const panTo = React.useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(14);
    }, []);

    async function getCompanySites() {
        const response = await restRequest('getCompanySites', 'get', {});
        const allSites = response.data;
        var allSitesByIds = new Map();
        for (let i = 0; i < allSites.length; i++) {
            allSitesByIds = allSitesByIds.set(allSites[i]._id, allSites[i]);
        }
        setAllSitesByIds(allSitesByIds);
    }

    function zoomToFilteredCollections() {
        const filteredIds = filteredSites.map(coll => coll._id);
        const selected = Array.from(markers.values()).filter(value => filteredIds.includes(value._id));
        const bounds = new window.google.maps.LatLngBounds();
        selected.map(x => {
            if (x.latitude > -89 && x.latitude < 89 && x.longitude > -179 && x.longitude < 179) {
                bounds.extend(new window.google.maps.LatLng(x.latitude, x.longitude));
            }
        });
        mapRef.current.fitBounds(bounds);
    }

    useEffect(() => {
        if (switchLabel === "All") {
            setMarkers(allSitesByIds);
        }
        else if (filteredSites?.length > 0) {
            // map of every site's collections
            var sitesByIds = new Map();
            filteredSites.forEach((site) => {
                sitesByIds = sitesByIds.set(site._id, site);
            })
            setMarkers(sitesByIds);
            if (markers.size > 0) {
                zoomToFilteredCollections();
            }
        }
        else {
            setMarkers(new Map());
        }
        setClustererKey(clustererKey + 1);
    }, [filteredSites, switchLabel]);

    useEffect(() => {
        getCompanySites();
    }, [])

    if (loadError) {
        return "Error loading maps"
    }

    if (!isLoaded) {
        return "Loading maps"
    }

    const get_icon = (marker) => {
        const posix = checkedSites.includes(marker._id) ? '_selected' :
            !filteredSites.some(site => site._id === marker._id) ? '_filtered' :
                '';
        return `/deployed${posix}.svg`
    }
    const optionsImage = {
        imagePath:
            "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m"
    };

    let markersArray = Array.from(markers.values());
    return (
        <GoogleMap mapContainerStyle={mapContainerStyle} zoom={12} center={center} options={options} onLoad={onMapLoad}>
            <MarkerClusterer options={optionsImage} key={clustererKey}>
                {(clusterer) =>
                    // gets iterator return array
                    markersArray.map((marker, index) => (
                        <div>
                            <Marker
                                optimized={true}
                                key={marker._id}
                                position={{ lat: marker.latitude, lng: marker.longitude }}
                                clusterer={clusterer}
                                noClustererRedraw={true}
                                onClick={() => {
                                    setSelectedSite(marker);
                                }}
                                icon={{
                                    url: get_icon(marker),
                                    origin: new window.google.maps.Point(0, 0),
                                    anchor: new window.google.maps.Point(15, 15),
                                    scaledSize: new window.google.maps.Size(30, 30),
                                }}
                            />
                        </div>

                    ))
                }
            </MarkerClusterer>
            {selectedSite &&
                <SitePopUpComp selectedSite={selectedSite} setSelectedSite={setSelectedSite} filteredSites={filteredSites} setFilteredSites={setFilteredSites} setAddSite={setAddSite} />
            }
            <div className={mapStyles.searchSite}>
                <Search panTo={panTo} />
            </div>
            <Locate className={mapStyles.location} panTo={panTo} />
            <div className={mapStyles.filtered}>
                <SwitchComp label={switchLabel} setLabel={setSwitchLabel} primaryLbl={"Filtered"} secondaryLbl={"All"} />
            </div>
        </GoogleMap>
    );
}