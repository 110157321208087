import React, { useEffect, useState, useRef } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import Workbook from 'react-excel-workbook'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { IconButton, CircularProgress, Tooltip } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import ButtonComp from '../ButtonComp/ButtonComp';
import buttonStyles from '../ButtonComp/ButtonComp.module.css';
import classes from './CollectionTableComp.module.css';
import ImageSearch from '@material-ui/icons/ImageSearch';
import VerticalAlignBottom from '@material-ui/icons/VerticalAlignBottom';
import PersonIcon from '@mui/icons-material/Person';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: '_id', numeric: false, disablePadding: true, label: 'Collection id' },
  { id: 'site_id', numeric: false, disablePadding: false, label: 'Site id' },
  { id: 'site_name', numeric: false, disablePadding: false, label: 'Site name' },
  { id: 'placement_date', numeric: false, disablePadding: false, label: 'Placement Date' },
  { id: 'percentage', numeric: false, disablePadding: false, label: 'Percentage(SO)' },
  { id: 'total', numeric: false, disablePadding: false, label: 'Total' },
  { id: 'virus', numeric: false, disablePadding: false, label: 'Virus' },
];

function EnhancedTableHead(props) {
  const { classesStyle, onSelectAllClick, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow >
        <TableCell padding="checkbox">
          <Checkbox size={"small"} onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all collections' }} />
        </TableCell>
        <TableCell key={'icon'} align={'left'} className={classes.tableIcon} />
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'default'} sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel className={classes.root} active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    width: '100%',
    left: '16%',
    bottom: 0,
  },
  paper: {
    width: '99%',
    marginBottom: theme.spacing(1),
  },
  tableCell: {
    whiteSpace: 'nowrap',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    width: 1,
  },
  tooltip: { fontSize: "1em" }
}));

export default function EnhancedTable({ checkedCollections, setCheckedCollections, collectionsIdsInWork, setCollectionsIdsInWork, socket, collections, numberOfCollections, rowsPerPage, setRowsPerPage, filterParams, setFailViewInsect, StringToDate }) {
  const history = useHistory();
  const classesStyle = useStyles();
  const [data1, setData1] = useState([]);
  const [columnsToInclude, setColumnsToInclude] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('siteName');
  const [page, setPage] = useState(0);
  const [circTopPosition, setCircTopPosition] = useState('93%');
  const [circLeftPosition, setCircLeftPosition] = useState('55%');
  // for the insects at the report
  const [interestedKinds, setInterestedKinds] = useState([]);
  const toolTipClasses = useStyles();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = collections.map((n) => n._id);
      setCheckedCollections(newSelecteds);
      return;
    }
    setCheckedCollections([]);
  };

  const handleClick = (event, CollId) => {
    const selectedIndex = checkedCollections.indexOf(CollId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkedCollections, CollId);
    }
    else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkedCollections.slice(1));
    }
    else if (selectedIndex === checkedCollections.length - 1) {
      newSelected = newSelected.concat(checkedCollections.slice(0, -1));
    }
    else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        checkedCollections.slice(0, selectedIndex),
        checkedCollections.slice(selectedIndex + 1),
      );
    }
    setCheckedCollections(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value === 'F') {
      setRowsPerPage(15);
      setCircTopPosition('83.5%');
      setCircLeftPosition('55.5%');
    }
    else {
      setRowsPerPage(event.target.value);
      setCircTopPosition('93%');
      setCircLeftPosition('55%');
    }
  };

  const isSelected = (CollId) => checkedCollections.indexOf(CollId) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, collections.length - page * rowsPerPage);

  function onlyFull() {
    for (let i = 0; i < checkedCollections.length; i++) {
      const coll = collections.find(coll => coll._id === checkedCollections[i]);
      if (coll.percentage !== 100) {
        return false;
      }
    }
    return true;
  }

  function onlyEmpty() {
    for (let i = 0; i < checkedCollections.length; i++) {
      const coll = collections.find(coll => coll._id === checkedCollections[i]);
      if (coll !== undefined && coll.total > 0) {
        return false;
      }
    }
    return true;
  }

  function IdentificationClick() {
    if (checkedCollections.length > 0) {
      if ((!filterParams.insectID && onlyFull() && filterParams.viewUnidentified) || (!filterParams.insectID && onlyEmpty() && !filterParams.viewUnidentified)) {
        setFailViewInsect(true);
        setTimeout(() => {
          setFailViewInsect(false)
        }, 3000);
      }
      else {
        history.replace("identification");
      }
    }
  }

  const get_secondOpinion_percentages = (row) => {
    const percentages = Math.floor(row.second_opinion_percentage);
    if (percentages != 0) {
      return `(${percentages}%)`;
    }
    return "";
  }

  function getCollection(coll_id) {
    for (let i = 0; i < collections.length; i++) {
      if (collections[i]._id === coll_id) {
        return collections[i];
      }
    }
    return null;
  }

  async function getInterestedKinds() {
    const response = await restRequest('getKinds', 'get', {});

    // sort according to alpha bet
    setInterestedKinds(response.data);
  }

  async function getKindAmount(coll_id, interestedKinds) {
    const response = await restRequest('getKindsAmount', 'post', { collection_id: coll_id, interestedKinds: interestedKinds });
    return response.data;
  }


  useEffect(() => {
    getInterestedKinds();
    if (window.sessionStorage.getItem('userInWork') !== null) {
      setCollectionsIdsInWork(window.sessionStorage.getItem('userInWork'));
    }
  }, []);


  async function generateExcelData() {
    let data = await Promise.all(checkedCollections.map((collection_id) => getRowForExcel(collection_id)));
    setData1(data);
  }

  async function getRowForExcel(collection_id) {
    const collection = getCollection(collection_id);
    if (collection !== null) {
      const row = {
        _id: collection_id,
        site_id: collection.mapVision_id ? collection.mapVision_id : collection.site_id,
        placement_date: collection.placement_date,
        pickup_date: collection.pickup_date,
        total_insects: collection.total
      };

      const interestedKindsWithAmount = await getKindAmount(collection_id, interestedKinds);
      let insectsAmount = 0;
      let kindsToAdd = [];
      interestedKindsWithAmount.forEach((kind, i) => {
        if (kind.genus !== null) {
          row[kind.genus + "_" + kind.species + "_" + kind.sex] = kind.amount;
          kindsToAdd.push({ genus: kind.genus, species: kind.species, sex: kind.sex })
          insectsAmount += kind.amount;
        }
      })
      const uniqueObjects = Array.from(new Set([...columnsToInclude, ...kindsToAdd].map(obj => JSON.stringify(obj)))).map(str => JSON.parse(str));
      setColumnsToInclude(uniqueObjects);
      insectsAmount = row.total_insects - insectsAmount;
      row["other"] = insectsAmount;
      return row;
    }
  }

  function generateRequest() {
    setLoadingData(true);
    generateExcelData();
  }

  function usePrevious(value) {
    const ref = useRef();

    useEffect(() => {
      ref.current = value;
    }, [value]);

    return ref.current;
  }

  function useEffectAllDepsChange(fn, deps) {
    const prevDeps = usePrevious(deps);
    const changeTarget = useRef();

    useEffect(() => {
      if (changeTarget.current === undefined) {
        changeTarget.current = prevDeps;
      }

      if (changeTarget.current === undefined) {
        return fn();
      }

      if (changeTarget.current.every((dep, i) => dep !== deps[i])) {
        changeTarget.current = deps;

        return fn();
      }
    }, [fn, prevDeps, deps]);
  }
  // trigger excell report download
  useEffect(() => {
    if (data1?.length > 0 && data1?.length === checkedCollections.length) {
      setLoadingData(false);
      document.getElementById('download-excel').click();
    }
  }, [data1])

  useEffectAllDepsChange(() => {
    setPage(0);
  }, [filterParams, collections.length]);



  return (
    <div className={classes.root}>
      <Paper className={classesStyle.paper} site_id >
        <TableContainer stickyHeader={true}>
          <Table className={classes.table} stickyHeader={true} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
            <EnhancedTableHead classes={classes.table} numSelected={checkedCollections.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={collections.length} />
            <TableBody>
              {stableSort(collections, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row._id === 'empty' ? row.site_id : row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover onClick={(event) => { row._id === 'empty' ? handleClick(event, row.site_id) : handleClick(event, row._id) }}
                      role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row._id} selected={isItemSelected}>
                      <TableCell padding="checkbox">
                        <Checkbox checked={isItemSelected} size={"small"} inputProps={{ 'aria-labelledby': labelId }} />
                      </TableCell>
                      <TableCell className={classes.tableIcon} align="left">
                        {collectionsIdsInWork.includes(row._id) &&
                          <PersonIcon />
                        }
                      </TableCell>
                      <TableCell className={classes.tableCell} component="th" id={labelId} scope="row" padding="none">{row._id}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.mapVision_id ? row.mapVision_id : row.site_id}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.site_name}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.placement_date ? StringToDate(row.placement_date) : ''}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{typeof row.percentage === 'number' && !isNaN(row.percentage)
                        ? row.percentage < 99
                          ? `${Math.ceil(row.percentage)}% ${get_secondOpinion_percentages(row)}`
                          : `${Math.floor(row.percentage)}% ${get_secondOpinion_percentages(row)}`
                        : ''}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.total}</TableCell>
                      <TableCell className={classes.tableCell} align="left">{row.viruses ? (row.viruses.length > 0 ? row.viruses : '-') : "-"}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <TablePagination rowsPerPageOptions={[3, 5, 10]} component="div" count={collections.length} rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onRowsPerPageChange={handleChangeRowsPerPage} />
          </div>
          {checkedCollections.length > 0 && !onlyEmpty() &&
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Explore collection' placement='top'>
                <IconButton className={buttonStyles.identification} name={"Explore"} onClick={IdentificationClick} >
                  <ImageSearch />
                </IconButton>
              </Tooltip>
              <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Generate report' placement='top'>
                <IconButton className={buttonStyles.generateReport} name={"Generate Report"} onClick={generateRequest} >
                  <VerticalAlignBottom />
                </IconButton>
              </Tooltip>
              {loadingData &&
                <CircularProgress style={{ position: 'absolute', top: circTopPosition, left: circLeftPosition }} size={30} disableShrink />
              }
              <div style={{ marginLeft: "1%", marginTop: "0.7%", width: "10%", height: "90%", borderColor: "#11698E" }}>
                <Workbook filename="senecio_collections_report.xlsx" element={<button id='download-excel' type='hidden'> </button>}>
                  <Workbook.Sheet data={data1} name="Senecio Report">
                    <Workbook.Column label="Barcode" value="_id" />
                    <Workbook.Column label="Site id" value="site_id" />
                    <Workbook.Column label="Placement date" value="placement_date" />
                    <Workbook.Column label="Pickup date" value="pickup_date" />
                    <Workbook.Column label="Total insects" value="total_insects" />
                    {columnsToInclude.sort((a, b) =>
                      `${a.genus} ${a.species} ${a.sex}`.localeCompare(`${b.genus} ${b.species} ${b.sex}`)
                    ).map((row) => (
                      <Workbook.Column label={row.genus + " " + row.species + " " + row.sex} value={row.genus + "_" + row.species + "_" + row.sex} />
                    ))}
                    <Workbook.Column label="Other" value="other" />
                  </Workbook.Sheet>
                </Workbook>
              </div>
            </div>
          }
        </div>
      </Paper>
    </div>
  );
}