import React from 'react';
import Popover from '@material-ui/core/Popover';
import { LocationOn } from '@mui/icons-material';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import classes from './LocationPopoverComp.module.css'
import { makeStyles } from '@material-ui/core/styles';

export default function SimplePopover(location) {
  const lat = location.location.latitude;
  const long = location.location.longitude;
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const tooltipStyles = makeStyles({
    tooltip: {fontSize: "1em"}
  });

  const toolTipClasses = tooltipStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const src = "https://maps.googleapis.com/maps/api/staticmap?center=" + lat + "," + long + "&zoom=10&size=450x350&maptype=roadmap&key=AIzaSyDJY_AHMePpknxD_uVYwe0vJ_1fq9aLLSA";

  return (
    <div>
      <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='View location'>
        <IconButton onClick={handleClick}>
          <LocationOn />
        </IconButton>
      </Tooltip>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <Typography className={classes.typography}>
          <img style={{ transform: 'scale(1, 1)', width: '100%', height: '100%' }} src={src} />
        </Typography>
      </Popover>
    </div>
  );
}