import React, { useEffect } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

export default function SwitchLabels({className, label, setLabel, primaryLbl, secondaryLbl, isDisabled}) {
  const [state, setState] = React.useState({
    checkedA: true,
    checkedB: true,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });  
    event.target.checked? setLabel(primaryLbl): setLabel(secondaryLbl)     
  };

  useEffect(() => {
    setState({
      checkedA: true,
      checkedB: label === primaryLbl,
    })
  },[label])

  return (
    <FormGroup row>
        <FormControlLabel disabled={isDisabled} className={className} control={<Switch
            checked={state.checkedB}
            onChange={handleChange}
            name="checkedB"
            color="primary"
        />} label={label} style={{ color:  (state.checkedB? '#2320b6' :  'black')}} />
    </FormGroup>
  );
}
