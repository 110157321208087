import React, { useEffect, useState } from "react";
import { restRequest } from '../../serverAPI/restRequests';
import { Alert } from '@material-ui/lab';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import JsBarcode from 'jsbarcode';
import logo from '../../images/logo.jpg';
import { CircularProgress } from "@material-ui/core";
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import DateComp from '../../components/DateComp/DateComp';
import SitesMapComp from '../../components/MapComp/CreateCollectionSitesMapComp';
import SitesTableComp from '../../components/Tables/SitesTableComp';
import ConfirmationPopupComp from '../../components/DialogComp/ConfirmationDialogComp';
import TrapsAndLuresTable from '../../components/Tables/TrapsAndLuresTableComp'
import buttonStyles from '../../components/ButtonComp/ButtonComp.module.css';
import comboStyles from '../../components/ComboBoxComp/ComboBoxComp.module.css';
import dateStyles from '../../components/DateComp/DateComp.module.css';
import classes from './CreateCollectionPage.module.css';

export default function CreateCollectionPage() {
    const [traps, setTraps] = useState(null);
    const [run, setRun] = useState(null);
    const [purpose, setPurpose] = useState(null);
    const [placementDate, setPlacementDate] = useState(new Date());

    const [runsList, setRunsList] = useState([]);
    const [purposesList, setPurposesList] = useState(["Permanent site", "Human", "Chicken", "Dead bird", "Pool", "Training"]);
    const [pooledSpecies, setPooledSpecies] = useState([]);
    const [showAlert, setAlert] = useState(false);
    const [companySites, setCompanySites] = useState([]);
    const [filteredSites, setFilteredSites] = useState([]);
    const [addSite, setAddSite] = useState(null);
    const [checkedSites, setCheckedSites] = useState([]);
    const [loading, setLoading] = useState(false);

    function getSite(site_id) {
        for (let i = 0; i < filteredSites.length; i++) {
            if (filteredSites[i]._id === site_id) {
                return filteredSites[i];
            }
        }
    }

    async function getCollection(coll_id) {
        const response = await restRequest('getCollection', 'post', { id: coll_id });
        return response.data;
    }

    async function getRun(site_id) {
        const response = await restRequest('getRun', 'post', { site_id: site_id });
        return response.data;
    }

    async function getPurpose(coll_id) {
        const response = await restRequest('getPurpose', 'post', { coll_id: coll_id });
        return response.data;
    }

    async function generateNewCollectionPDF(collections_ids) {
        // object of pdf- page argumnets
        var doc = new jsPDF('portrait', 'px', 'a4', 'false');

        // page for each collection
        for (let i = 0; i < checkedSites.length; i++) {
            let site = getSite(checkedSites[i]);

            let location_road = "https://maps.googleapis.com/maps/api/staticmap?center=" + site.latitude + "," + site.longitude + "&zoom=13&size=450x350&maptype=roadmap&key=AIzaSyDJY_AHMePpknxD_uVYwe0vJ_1fq9aLLSA";
            let location_hybrid = "https://maps.googleapis.com/maps/api/staticmap?center=" + site.latitude + "," + site.longitude + "&zoom=13&size=450x350&maptype=hybrid&key=AIzaSyDJY_AHMePpknxD_uVYwe0vJ_1fq9aLLSA";

            // (filename, type, x, y, width, height)
            doc.addImage(logo, 'PNG', 10, 8, 70, 30);

            doc.setFont('Helvertica', 'bold');
            doc.setFontSize(19);
            doc.text(140, 30, 'Trapping Collection sheet');
            doc.text(0, 45, '__________________________________________________________________');
            doc.setFontSize(13);
            doc.text(10, 60, 'Site name:');
            doc.text(10, 83, 'Site id:');
            doc.text(112, 83, '#Run:');
            doc.text(202, 60, 'Placement date:');
            if (site['address'] !== undefined) {
                doc.text(202, 75, 'Address:');
            }
            if (site['directions'] !== undefined) {
                doc.text(202, 95, 'Directions:')
            }
            doc.addImage(location_road, 'PNG', 10, 120, 200, 195);
            doc.addImage(location_hybrid, 'PNG', 230, 120, 200, 195);
            doc.text(10, 340, 'Trapping purpose:');

            // create and add a barcode of a collection id
            var coll_barcode = document.createElement('canvas');
            JsBarcode(coll_barcode, collections_ids[i], { format: "CODE128", lineColor: "black", width: 1.5, height: 100, font: "monospace", textMargin: 3, fontSize: 28, displayValue: true });
            doc.addImage(coll_barcode, "JPEG", 318, 5, 90, 40);

            doc.setFont('Helvertica', 'normal');
            doc.text(58, 60, doc.splitTextToSize(site.name, 120));
            doc.text(45, 83, site._id);
            const run_name = await getRun(site['_id']);
            doc.text(142, 83, run_name.toString())
            const collection = await getCollection(collections_ids[i]);
            doc.text(272, 60, collection.placement_date.slice(0, 10));
            if (site['address'] !== undefined) {
                doc.text(242, 75, doc.splitTextToSize(site.address, 180));
            }
            if (site['directions'] !== undefined && site['directions'] !== null) {
                doc.text(252, 95, doc.splitTextToSize(site.directions, 180));
            }
            const purpose = await getPurpose(collections_ids[i]);
            doc.text(100, 340, purpose);
            doc.addPage(undefined, "landscape");
            const trap_events = new Map((await restRequest('getTrapsEventsIdsOfCollection', 'post', { collection_id: collections_ids[i] })).data);
            const checked_traps = traps.filter(trap => trap.trap_check);
            const head = [[''].concat(checked_traps.map(trap => { return { content: trap.trap_name, colSpan: 4, styles: { halign: 'center' } } })),
            ['Species'].concat(checked_traps.map(_ => ['M', 'F', '#Pooled', 'Vial #Pool']).flat())];

            doc.autoTable({
                theme: 'grid',
                didParseCell: function (data) {
                    if (data.row.index === 0 && data.cell.section === 'head') {
                        data.row.height = 40;
                    }
                },
                head: head,
                body: pooledSpecies,
                didDrawCell: function (data) {
                    if (data.column.index > 0 && data.row.index === 0 && data.cell.section === 'head') {
                        let trap_barcode = document.createElement('canvas');
                        JsBarcode(trap_barcode, trap_events.get(checked_traps[(data.column.index - 1) / 4].trap_name), { format: "CODE128", lineColor: "black", width: 2, height: 90, font: "monospace", textMargin: 3, fontSize: 40, displayValue: true });
                        doc.addImage(trap_barcode, "JPEG", data.cell.x + (data.cell.width + data.cell.contentWidth) / 2, data.cell.y, 90, 40);

                    }
                },
            });

            if (i + 1 < checkedSites.length) {
                doc.addPage();
            }
        }

        setLoading(false);
        setAlert(true);
        setTimeout(() => {
            setAlert(false)
        }, 6000);
        doc.save('collections_sheets.pdf');
    }

    async function generateEmptyCollectionPDF(collection_id) {
        // object of pdf- page argumnets
        var doc = new jsPDF('portrait', 'px', 'a4', 'false');

        // (filename, type, x, y, width, height)
        doc.addImage(logo, 'PNG', 10, 8, 70, 30);

        doc.setFont('Helvertica', 'bold');
        doc.setFontSize(19);
        doc.text(140, 30, 'Trapping Collection sheet');
        doc.text(0, 45, '__________________________________________________________________');
        doc.setFontSize(13);
        doc.text(10, 70, 'Site name:');
        doc.text(200, 70, 'Site code:');
        doc.text(10, 90, 'Direction notes:');
        doc.text(10, 140, 'Trapping purpose:');

        doc.setFont('Helvertica', 'normal');
        const purpose = await getPurpose(collection_id);
        doc.text(100, 140, purpose);

        // create and add a barcode of a collection id
        var coll_barcode = document.createElement('canvas');
        JsBarcode(coll_barcode, collection_id, { format: "CODE128", lineColor: "black", width: 1.5, height: 100, font: "monospace", textMargin: 3, fontSize: 28, displayValue: true });
        doc.addImage(coll_barcode, "JPEG", 318, 5, 90, 40);
        doc.addPage(undefined, "landscape");
        const trap_events = new Map((await restRequest('getTrapsEventsIdsOfCollection', 'post', { collection_id: collection_id })).data);
        const checked_traps = traps.filter(trap => trap.trap_check);
        const head = [[''].concat(checked_traps.map(trap => { return { content: trap.trap_name, colSpan: 4, styles: { halign: 'center' } } })),
        ['Species'].concat(checked_traps.map(_ => ['M', 'F', '#Pooled', 'Vial #Pool']).flat())];
        doc.autoTable({
            theme: 'grid',
            didParseCell: function (data) {
                if (data.row.index === 0 && data.cell.section === 'head') {
                    data.row.height = 40;
                }
            },
            head: head,
            body: pooledSpecies,
            didDrawCell: function (data) {
                if (data.column.index > 0 && data.row.index === 0 && data.cell.section === 'head') {
                    let trap_barcode = document.createElement('canvas');
                    JsBarcode(trap_barcode, trap_events.get(checked_traps[(data.column.index - 1) / 4].trap_name), { format: "CODE128", lineColor: "black", width: 2, height: 90, font: "monospace", textMargin: 3, fontSize: 40, displayValue: true });
                    doc.addImage(trap_barcode, "JPEG", data.cell.x + (data.cell.width + data.cell.contentWidth) / 2, data.cell.y, 90, 40);

                }
            },
        });

        setLoading(false);
        setAlert(true);
        setTimeout(() => {
            setAlert(false)
        }, 6000);

        doc.save('empty_collection.pdf');
    }

    async function handleCreate() {
        setLoading(true);
        let collections_ids = [];
        for (let i = 0; i < checkedSites.length; i++) {
            const response = await restRequest('AddCollection', 'post', { traps: traps, placement_date: placementDate, site_id: checkedSites[i], purpose: purpose });
            collections_ids.push(response.data);
        }
        generateNewCollectionPDF(collections_ids);
    }

    async function handleCreateEmptyCollection() {
        setLoading(true);
        const response = await restRequest('AddCollection', 'post', { traps: traps, placement_date: placementDate, site_id: "00000", purpose: purpose });
        generateEmptyCollectionPDF(response.data);
    }

    async function filterAccordingToRun() {
        const response = await restRequest('getRunSites', 'post', { run_name: run });
        setFilteredSites(response.data);
    }

    async function getRuns() {
        const response = await restRequest('getCompanyRuns', 'get', {});
        setRunsList(response.data);
    }

    async function getCompanySites() {
        const response = await restRequest('getCompanySites', 'get', {});
        setCompanySites(response.data);
        setFilteredSites(response.data);
        setCheckedSites([]);
    }

    function addNewSite() {
        let curr_sites = filteredSites;
        curr_sites.push(addSite);
        setFilteredSites(curr_sites);
    }

    function noTraps() {
        return (traps === null || traps.filter(trap => trap.trap_check).length === 0);
    }

    async function getTraps() {
        const response = await restRequest('getCompanyTrapsAndLures', 'get', {});
        setTraps(response.data);
    }

    useEffect(() => {
        getCompanySites();
        getRuns();
        getTraps();
        const company_id = window.sessionStorage.getItem('company_id');
        if (company_id === '65ce34d59a65f4db6b4cf9a5' || company_id === '65c89d2345878bbb080bd6b1' || company_id === "65c89b6f45878bbb080bd6b0") {
            setPooledSpecies([['Cx salinarius'], ['Cx quinquefasciatus'], ['Cx nigripalpus'], ['Cx erraticus'], ['As vexans'], ['As albopictus'], ['Ps ferox'], ['OTHER:']])
        }
        else {
            setPooledSpecies([['Cx tarsalis'], ['Cx pipiens'], ['Cx erythrothorax'], ['Cx stigmatosoma'], ['Ae melanimon'], ['An freeborni'], ['Cs incidens'], ['Cs inornata'], ['OTHER:'], ['OTHER:']]);
        }

    }, []);

    useEffect(() => {
        if (addSite !== null) {
            addNewSite();
            setAddSite(null);
        }
    }, [addSite])

    useEffect(() => {
        if (run === null) {
            setFilteredSites(companySites);
        }
        else {
            filterAccordingToRun();
        }
    }, [run])

    return (
        <div className={classes.wrapper}>
            <div style={{ width: '30%' }}>
                <div className={classes.wrapper1}>
                    <ComboBoxComp className={comboStyles.siteFilters} name={"Runs"} paramsList={runsList} value={run} setValue={setRun} />
                </div>
                <div style={{ width: '150%', marginLeft: '2%', marginTop: '3%' }}>
                    <SitesTableComp checkedSites={checkedSites} setCheckedSites={setCheckedSites} sites={filteredSites} />
                </div>
                <div className={classes.container}>
                    <h2 className={classes.headline}>New Collection information:</h2>
                    {traps !== null &&
                        <TrapsAndLuresTable traps={traps} setTraps={setTraps} />
                    }
                    <div style={{ display: 'inline-flex' }}>
                        <DateComp style="display: grid;" className={dateStyles.collection_date} name={"Placement Date"} value={placementDate} setValue={setPlacementDate} />
                        <ComboBoxComp className={classes.purpose} name={"Purpose"} paramsList={purposesList} value={purpose} setValue={setPurpose} />
                    </div>
                    <div className={classes.createSheets}>
                        <ButtonComp className={buttonStyles.createCollections} name={"Create"} action={handleCreate} isDisabled={checkedSites.length === 0 || placementDate === null || purpose === null || noTraps()} />
                        <ButtonComp className={buttonStyles.createEmptyCollection} name={"Empty Collection"} action={handleCreateEmptyCollection} isDisabled={placementDate === null || purpose === null || noTraps()} />
                        {loading &&
                            <CircularProgress variant="indeterminate" style={{ marginLeft: '5%', marginTop: '3.5%' }} shrinkAnimation={false} className={classes.spinner} size={27} />
                        }
                    </div>
                </div>
                {showAlert &&
                    <Alert severity="success">Collections created successfully</Alert>
                }
            </div>
            <div style={{ height: '85vh', width: '50%', marginTop: '0.7%', marginLeft: '17%' }}>
                <SitesMapComp filteredSites={filteredSites} setFilteredSites={setFilteredSites} checkedSites={checkedSites} setAddSite={setAddSite} />
            </div>
        </div>
    )
}