export class FilterParamsDto {
    insectID;
    viewUnidentified;
    species;
    genus;
    taggedBy;
    secondOpinion;
    collections_ids;
    prev_insects;
    viewUnverified;
    constructor({insectID, viewUnidentified, species, genus, taggedBy, secondOpinion, viewUnverified}, collections_ids, prev_insects) {
        this.insectID = insectID;
        this.viewUnidentified = viewUnidentified;
        this.species = species;
        this.genus = genus;
        this.taggedBy = taggedBy;
        this.secondOpinion = secondOpinion;
        this.collections_ids = collections_ids;
        this.prev_insects = prev_insects;
        this.viewUnverified = viewUnverified;
    }
}

export class CreateIdentificationDto {
    species;
    genus;
    sex;
    second_opinion;
    cant_identify;
    isFed;
    valid;
    head;
    thorax;
    abdomen;
    noise;
    multiple;
    tagger_username;
    timestamp;
    constructor(species, genus, sex, second_opinion, cant_identify, isFed, valid, head, thorax, abdomen, noise, multiple, tagger_username, timestamp) {
        this.species = species;
        this.genus = genus;
        this.sex = sex;
        this.second_opinion = second_opinion;
        this.cant_identify = cant_identify;
        this.isFed = isFed;
        this.valid = valid;
        this.head = head;
        this.thorax = thorax;
        this.abdomen = abdomen;
        this.noise = noise;
        this.multiple = multiple;
        this.tagger_username = tagger_username;
        this.timestamp = timestamp;
    }
}

export class LocalCroppedImage {
    path;
    focus_num;
    images_scale;
    constructor({path, focus_num, images_scale}) {
        this.path = path;
        this.focus_num = focus_num;
        this.images_scale = images_scale;
    }
}

export class GetInsectDto {
    insect_id;
    site_name;
    site_lat;
    site_long;
    placement_date;
    pickup_date;
    collection_id;
    urls;
    identifications;
    verified;
    constructor({insect_id, site_name, site_lat, site_long, placement_date, pickup_date, collection_id, urls, identifications, verified}) {
        this.insect_id = insect_id;
        this.site_name = site_name;
        this.site_lat = site_lat;
        this.site_long = site_long;
        this.placement_date = placement_date;
        this.pickup_date = pickup_date;
        this.collection_id = collection_id;
        this.urls = urls;
        this.identifications = identifications;
        this.verified = verified;
    }
}