import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { DialogTitle, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import InfoDialogComp from '../DialogComp/InfoDialogComp';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ButtonComp from '../ButtonComp/ButtonComp';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import comboStyles from '../../components/ComboBoxComp/ComboBoxComp.module.css';
import { Alert } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import classes from './NewSiteDialogComp.module.css';
import AddIcon from '@mui/icons-material/Add';
import TextField from '@mui/material/TextField';
import SwitchComp from '../../components/SwitchComp/SwitchComp';
import Checkbox from '../CheckBoxComp/CheckBoxComp';

export default function NewLandmarkComp({ open, handleClose, areaList, setFlagNewLAndmarks, users }) {
    const [address, setAddress] = useState('');
    const [newLandmark, setnewLandmark] = useState(null);
    const [areaSelected, setAreaSelected] = useState(null);
    const [failAlert, setFailAlert] = useState(null);
    const [addedConfirmation, setAddedConfirmation] = useState(false);
    const [clientName, setClientName] = useState(null);
    const [numberOfRolls, setNumberOfRolls] = useState(null);
    const [daysAvailable, setDaysAvailable] = useState([]);
    const [daySelected, setDaySelected] = useState(null);
    const [openNotes, setOpenNotes] = useState(false);
    const [notes, setNotes] = useState(null);
    const [newArea, setNewArea] = useState('New Area');
    const [userSelected, setUserSelected] = useState(null);
    const [dayList, setDayList] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    const [inside, setInside] = useState(false);
    const [cellphone, setCellphone] = useState(null);
    const [email, setEmail] = useState(null);
    const [startTime, setStartTime] = useState(null);
    const [endTime, setEndTime] = useState(null);

    const handleSelect = async (selectedAddress) => {
        setFailAlert(null);
        setAddress(selectedAddress);

        try {
            const results = await geocodeByAddress(selectedAddress);
            const { lat, lng } = await getLatLng(results[0]);

            // Call the onAddLandmark function with the latitude and longitude
            setnewLandmark({ lat: lat, lng: lng, address: selectedAddress });
        } catch (error) {
            console.error('Error geocoding address:', error);
        }
    };

    const closeAll = () => {
        handleClose();
        setAreaSelected(null);
        setFailAlert(null);
        setAddress('');
        setNumberOfRolls(null);
        setClientName(null);
        setDaySelected(null);
        setDaysAvailable([]);
        setNotes(null);
    }

    const handleCloseDialog = () => {
        closeAll();
        setAddedConfirmation(false);
    }

    async function showDays() {
        const response = await restRequest('showAllDays', 'post', { areaName: areaSelected, lat: newLandmark.lat, lng: newLandmark.lng });
        console.log(response.data)
        setDaysAvailable(response.data);
    }

    async function addLandMark() {
        setFailAlert(null);
        let response;
        if (newLandmark) {
            if (newArea === 'Add to existing area') {
                setUserSelected(null);
                response = await restRequest(`createNewLandmark`, 'post', { areaName: areaSelected, lat: newLandmark.lat, lng: newLandmark.lng, address: newLandmark.address, clientName: clientName, rollsNumber: numberOfRolls, daySelected: daySelected, email: email, cellPhone: cellphone, notes: notes, courier: null, inside: inside, startTime: startTime, endTime: endTime });
            }
            else {
                response = await restRequest(`createNewLandmark`, 'post', { areaName: areaSelected, lat: newLandmark.lat, lng: newLandmark.lng, address: newLandmark.address, clientName: clientName, rollsNumber: numberOfRolls, daySelected: daySelected, email: email, cellPhone: cellphone, notes: notes, courier: userSelected, inside: inside, startTime: startTime, endTime: endTime });
            }
            if (response.data === 'landmark already exists') {
                setFailAlert('landmark already exists');
                return;
            }
            setAddedConfirmation(true)
            setAddress('');
            setUserSelected(null);
            setEmail(null);
            setCellphone(null);
            setEndTime(null);
            setStartTime(null);
            setFlagNewLAndmarks(prev => (prev + 1));
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle>Add New landmark</DialogTitle>
                <DialogContent className={classes.container}>
                    <SwitchComp label={newArea} setLabel={setNewArea} primaryLbl={"New Area"} secondaryLbl={"Add to existing area"} />
                    {newArea === 'Add to existing area' &&
                        <ComboBoxComp name={"area"} paramsList={areaList} value={areaSelected} setValue={setAreaSelected} />}
                    {newArea === 'New Area' &&
                        <div>
                            <TextFieldComp value={areaSelected} setValue={setAreaSelected} label={"Spreading Line Name"} />
                            <ComboBoxComp name={"Couriers:"} paramsList={users} value={userSelected} setValue={setUserSelected} />
                            <ComboBoxComp name={"Spreading Day"} paramsList={dayList} value={daySelected} setValue={setDaySelected} />
                        </div>}
                    <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{ width: "100%" }}>
                                <input style={{ width: "100%", height: "30px" }}
                                    {...getInputProps({
                                        placeholder: 'Search for address...',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    <TextFieldComp value={startTime} setValue={setStartTime} label={"Start time"} />
                    <TextFieldComp value={endTime} setValue={setEndTime} label={"End time"} />
                    {daysAvailable.length > 0 &&
                        <ComboBoxComp name={"Days Availables"} paramsList={daysAvailable} value={daySelected} setValue={setDaySelected} />}
                    <TextFieldComp value={clientName} setValue={setClientName} label={"Client name"} />
                    <TextFieldComp value={numberOfRolls} setValue={setNumberOfRolls} label={"Number of spreads"} type={"number"} />
                    <TextFieldComp value={email} setValue={setEmail} label={"Email"} />
                    <TextFieldComp value={cellphone} setValue={setCellphone} label={"Cellphone"} type={"number"} />
                    <Checkbox label={"Spread inside"} checked={inside} onChange={() => setInside(!inside)} />
                    {openNotes && <TextField
                        style={{ marginTop: "3px" }}
                        id="outlined-multiline-static"
                        label="Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={(event) => setNotes(event.target.value)}
                    />}
                    {failAlert &&
                        <Alert severity="error">{failAlert}</Alert>
                    }
                </DialogContent>
                <DialogActions>
                    <IconButton onClick={() => setOpenNotes(!openNotes)}>
                        <AddIcon />
                    </IconButton>
                    {newArea === 'Add to existing area' && <ButtonComp name={'Show Days Available'} action={showDays} isDisabled={!newLandmark || !address.trim() || areaSelected === null} />}
                    <ButtonComp name={'Save'} action={addLandMark} isDisabled={!newLandmark || !address.trim() || areaSelected === null || numberOfRolls === null || clientName === null || daySelected === null} />
                </DialogActions>
            </Dialog >
            <InfoDialogComp message={'Client added successfully '} open={addedConfirmation} handleClose={handleCloseDialog} />
        </div >
    );
}