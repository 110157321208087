import ExploreIcon from '@material-ui/icons/Explore';
import IconButton from '@material-ui/core/IconButton';
import classes from '../CollectionsMapComp.module.css';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tooltip: {fontSize: "1em"}
});

export default function Locate({ panTo }) {
    const toolTipClasses = useStyles();

    return (
        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Locate'>
            <IconButton className={classes.locate} color="primary" onClick={() => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        panTo({ lat: position.coords.latitude, lng: position.coords.longitude });
                    }, () => null
                );
            }}>
                <ExploreIcon size="medium" />
            </IconButton>
        </Tooltip>
    );
}