import classes from './PictureComp.module.css';
import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import ZoomInRoundedIcon from '@mui/icons-material/ZoomInRounded';
import ZoomOutRoundedIcon from '@mui/icons-material/ZoomOutRounded';
import IconButton from '@material-ui/core/IconButton';
import ImageGallery from 'react-image-gallery';
import PrismaZoom from 'react-prismazoom';
import "react-image-gallery/styles/css/image-gallery.css";
import ScaleComp from '../../components/ScaleComp/ScaleComp';
import AddTaskSharpIcon from '@mui/icons-material/AddTaskSharp';

export default function GuideComp({ kind, images, setGenus, setSpecies, userIdentified, identificationsPtr, insectsArr, insectsPtr }) {
    const [kindPicture, setKindPicture] = useState(images.filter(value => value.insect_name === kind));
    const [imagesState, setImagesState] = useState(
        kindPicture[0].pictures.map(
            image => {
                return {
                    description: image.description,
                    base: image.url,
                    focus_num: 600,
                    cur_focus: 1,
                    cur_state: '_bright'
                }
            }));
    const [currIndex, setCurrIndex] = useState(0);
    const [fullScreen, setFullScreen] = useState(false);
    const imgWidthPixels = 2000.0; // CONST width of any image in [px]
    const firstScaleRatio = imgWidthPixels / parseFloat(700);
    const [scaleRatio, setScaleRatio] = useState(firstScaleRatio); // zoom x1 = scale ratio [px/mm]
    const [savePrismaRef, setSavePrismaRef] = useState(null);

    const useStyles = makeStyles({
        image: {
            objectFit: 'contain',
            transform: 'scale(1, 1)',
            position: 'relative',
            maxHeight: '300px',
            maxWidth: '450px',
            overflow: 'hidden',
        },
    });
    const imageClasses = useStyles();

    function imagesToItems(imagesState) {
        var output = [];
        for (var i = 0; i < imagesState.length; i++) {
            var image = imagesState[i];
            output.push({
                original: image.base.replace(new RegExp('\.jpg$'), `${image.cur_state}_f${image.cur_focus}.jpg`),
                thumbnail: image.base.replace(new RegExp('\.jpg$'), `${image.cur_state}_f${image.cur_focus}.jpg`),
                index: i
            })
        }
        return output;
    };

    function insertIdentification() {
        let insectName = kind.split(" ");
        setGenus(insectName[0]);
        setSpecies(insectName[1]);
    }

    // check if has next identification of the insect
    function hasNextIdentification() {
        if (insectsArr.length > 0 && insectsPtr < insectsArr.length && insectsPtr >= 0 && insectsArr[insectsPtr].identifications && identificationsPtr >= 0) {
            if (userIdentified) {
                return identificationsPtr < insectsArr[insectsPtr].identifications.length;
            }
            else {
                return identificationsPtr < insectsArr[insectsPtr].identifications.length - 1;
            }
        }
        return false;
    }

    useEffect(() => {
        setKindPicture(images.filter(value => value.insect_name === kind));
        let tmp = images.filter(value => value.insect_name === kind)
        if (tmp.length == 0) {
            setImagesState(kindPicture[0].pictures.map(
                image => {
                    return {
                        description: image.description,
                        base: image.url,
                        focus_num: 600,
                        cur_focus: 1,
                        cur_state: '_bright'
                    }
                }))
        }
        else {
            setImagesState(
                tmp[0].pictures.map(
                    image => {
                        return {
                            description: image.description,
                            base: image.url,
                            focus_num: 600,
                            cur_focus: 1,
                            cur_state: '_bright'
                        }
                    }));
        }
    }, [kind]);

    return (
        <div>
            <div className={classes.topDivGuide}>
                <h6 style={{ marginLeft: '20%' }}> {kind} Guide </h6>
                <IconButton onClick={insertIdentification} disabled={!userIdentified || hasNextIdentification()}>
                    <AddTaskSharpIcon />
                </IconButton>
            </div>
            <ImageGallery
                renderItem={({ original }) => {
                    const prismaRef = React.createRef();

                    const zoomIn = () => {
                        let curr_prisma = prismaRef.current;
                        // save prisma-zoom reference for later reset
                        if (curr_prisma !== null && savePrismaRef === null) {
                            setSavePrismaRef(curr_prisma);
                        }

                        // scale bar ratio. increase [mm/px];
                        const currZoom = curr_prisma.getZoom()
                        if (currZoom < 12) {
                            setScaleRatio((scaleRatio / (currZoom)) * (currZoom + 0.3));
                            // controlled zoom-in step
                            prismaRef.current.zoomIn(0.3);
                        }
                    }

                    const zoomOut = () => {
                        // scale bar update ratio decrease [px/mm]
                        const currZoom = prismaRef.current.getZoom();
                        if (currZoom > 1) {
                            setScaleRatio((scaleRatio / (currZoom)) * (currZoom - 0.3));
                            // controlled zoom-out step              
                            prismaRef.current.zoomOut(0.3);
                        }
                    }

                    const onDoubleClickOnCard = event => {
                        // scale bar reset ratio to origin (zoom x1)
                        setScaleRatio(imgWidthPixels / parseFloat(700));
                        // reset zoom to x1
                        event.preventDefault();
                        event.stopPropagation();
                        prismaRef.current.reset();
                    }

                    return (
                        <div className={classes.galleryGuide}>
                            <PrismaZoom ref={prismaRef} minZoom={1} maxZoom={12} scrollVelocity={0} >
                                <img className={imageClasses.image} src={original} onDoubleClick={onDoubleClickOnCard} />
                            </PrismaZoom>
                            <div className={classes.zoomLayoutGuide}>
                                <IconButton size="small" aria-label="zoom-in" onClick={zoomIn}>
                                    <ZoomInRoundedIcon />
                                </IconButton>
                                <IconButton size="small" aria-label="zoom-out" onClick={zoomOut}>
                                    <ZoomOutRoundedIcon />
                                </IconButton>
                            </div>
                            <div className={classes.scaleBarGuide}>
                                <ScaleComp scaleRatio={scaleRatio} imgWidthPixels={imgWidthPixels} />
                            </div>
                        </div>
                    )
                }}
                className={classes.mainImage}
                items={imagesToItems(imagesState)}
                useWindowKeyDown={false}
                showFullscreenButton={false}
                onScreenChange={() => setFullScreen(!fullScreen)}
                useBrowserFullscreen={true}
                showPlayButton={false}
                thumbnailPosition={'right'}
                startIndex={currIndex}
                showNav={false}
                onBeforeSlide={(next) => {
                    if (savePrismaRef !== null) {
                        savePrismaRef.reset();
                        setSavePrismaRef(null);
                    }
                    setCurrIndex(next);
                    setScaleRatio(imgWidthPixels / parseFloat(600));
                }}
            />
            <div className={classes.guideDescription}>
                <p className={classes.guideDescriptionText}>{imagesState[currIndex].description}</p>
            </div>
        </div>
    )
}