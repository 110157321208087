import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from "@material-ui/core";
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import buttonStyles from '../ButtonComp/ButtonComp.module.css';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ConfirmationDialogComp from '../../components/DialogComp/ConfirmationDialogComp';
// import InfoDialogComp from '../../components/DialogComp/InfoDialogComp';
import { restRequest } from '../../serverAPI/restRequests';
import NewSpreadDialogComp from '../../components/DialogComp/newSpreadDialogComp';
import CircleIcon from '@mui/icons-material/Circle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import NoteIcon from '@mui/icons-material/Note';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Draggable from 'react-draggable';
import MoveLandmarkDialog from '../DialogComp/MoveLandmarkDialog';
import EditIcon from '@mui/icons-material/Edit';
import EditClientDialogComp from '../DialogComp/EditClientDialogComp';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const headCells = [
    { id: 'address', numeric: false, disablePadding: true, label: 'Landmark Address' },
    { id: 'clientName', numeric: false, disablePadding: false, label: 'Client Name' },
    { id: 'active', numeric: false, disablePadding: false, label: 'Active' },
    { id: 'notes', numeric: false, disablePadding: false, label: 'Notes' }
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, onRequestSort, currentUserDate } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };
    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox onChange={onSelectAllClick} inputProps={{ 'aria-label': 'select all landmarks' }} />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell key={headCell.id} align={headCell.numeric ? 'right' : 'left'} padding={headCell.disablePadding ? 'none' : 'default'} sortDirection={orderBy === headCell.id ? order : false}>
                        <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {/* {currentUserDate === null &&
                    <TableCell key={"spread"} align={'left'} padding={'default'} sortDirection={orderBy === 'spread' ? order : false}>
                        <TableSortLabel active={orderBy === "spread"} direction={orderBy === "spread" ? order : 'asc'} onClick={createSortHandler("spread")}>
                            {"Spread Amount"}
                            {orderBy === "spread" ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>} */}
            </TableRow>
        </TableHead>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
        width: '100%',
        height: '90%'
    },
    paper: {
        width: '99%',
        marginBottom: theme.spacing(1),
    },
    tableCell: {
        whiteSpace: 'nowrap',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        width: 1,
    },
    tooltip: { fontSize: "0.7em" },
    container: { height: "100px", width: "200px" }
}));

export default function EnhancedTable({ landmarks, setLandmarks, checkedLandmarks, setCheckedLandmarks, areaId, rowsPerPage = 10, page, setPage, user, currentUserDate, setFlagNewLAndmarks, userList }) {
    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
        currentUserDate: currentUserDate
    };
    const classes = useStyles();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('_id');
    const [openNewSpreadDialog, setOpenNewSpreadDialog] = useState(false);
    const [openDialogSpreading, setOpenDialogSpreading] = useState(false);
    const [datesList, setDatesList] = useState([]);
    const [disActiveOpen, setDisActiveOpen] = useState(false);
    const [doneDisactive, setDoneDisactive] = useState(false);
    const [openNotes, setOpenNotes] = useState(null);
    const [openMoveClient, setOpenMoveClient] = useState(false);
    const [openEditClient, setOpenEditClient] = useState(null);
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event && event.target.checked) {
            const newSelecteds = landmarks.map((l) => l._id);
            setCheckedLandmarks(newSelecteds);
            return;
        }
        setCheckedLandmarks([]);
    };

    const handleClick = (event, _id) => {
        const selectedIndex = checkedLandmarks.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(checkedLandmarks, _id);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(checkedLandmarks.slice(1));
        }
        else if (selectedIndex === checkedLandmarks.length - 1) {
            newSelected = newSelected.concat(checkedLandmarks.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                checkedLandmarks.slice(0, selectedIndex),
                checkedLandmarks.slice(selectedIndex + 1),
            );
        }
        setCheckedLandmarks(newSelected);
    };

    function PaperComponent(props) {
        return (
            <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
                <Paper {...props} />
            </Draggable>
        );
    }

    const handleClickNotes = (notes) => {
        return (
            <div className={classes.container}>
                <span className={classes.notes}>{notes}</span>
            </div>
        );
    }
    const isSelected = (_id) => checkedLandmarks.length > 0 ? checkedLandmarks.indexOf(_id) !== -1 : false;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    function handleNewSpread() {
        setOpenNewSpreadDialog(true);
    }

    async function handleDelete() {
        const response = await restRequest('disActiveLandmarks', 'post', { areaId: areaId, landmarks: checkedLandmarks });
        setDisActiveOpen(false);
        setCheckedLandmarks([]);
        setDoneDisactive(true);
        setFlagNewLAndmarks(prev => (prev + 1));

    }

    async function addSpreadData() {
        const dateObject = new Date(currentUserDate);
        let data = {
            'checkedLandmarks': landmarks, 'date': dateObject.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            })
        }
        await restRequest('addSpreadData', 'post', data);
        setOpenDialogSpreading(false);
    }

    useEffect(() => {
        if (openNewSpreadDialog) {
            if (checkedLandmarks.length > 0) {
                let datesArray = []
                for (let i = 0; i < checkedLandmarks.length; i++) {
                    let land = landmarks.find(l => l._id === checkedLandmarks[i]);
                    datesArray = Array.from(new Set([...land.dates, ...datesArray]));
                }
                setDatesList(datesArray);
            }
        }
    }, [openNewSpreadDialog])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <TableContainer>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={'small'} aria-label="enhanced table">
                        <EnhancedTableHead classes={classes} numSelected={checkedLandmarks.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={landmarks.length} currentUserDate={currentUserDate} />
                        <TableBody>
                            {stableSort(landmarks, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row._id);
                                    const labelId = `enhanced-table-checkbox-${index}`;
                                    return (
                                        <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row._id} selected={isItemSelected}>
                                            <TableCell padding="checkbox">
                                                <Checkbox onClick={(event) => handleClick(event, row._id)} checked={isItemSelected} inputProps={{ 'aria-labelledby': labelId }} />
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">{row.address}</TableCell>
                                            <TableCell className={classes.tableCell} align="left">{row.clientName}</TableCell>
                                            <TableCell>
                                                <CircleIcon sx={{ color: row.active === true ? 'green' : 'red', fontSize: '24px' }} />
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">
                                                {row.notes ? <IconButton size='small' onClick={() => setOpenNotes(row.notes)}>
                                                    <NoteIcon />
                                                </IconButton> : '-'}
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="left">
                                                <IconButton size='small' onClick={() => setOpenEditClient(row._id)}>
                                                    <EditIcon />
                                                </IconButton>
                                            </TableCell>

                                            {/* {currentUserDate === null && <TableCell className={classes.tableCell} align="left">{row.amount}</TableCell>} */}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <TablePagination labelRowsPerPage={""} rowsPerPageOptions={[]} component="div" count={landmarks.length} rowsPerPage={rowsPerPage} page={page} onPageChange={handleChangePage} />
                    <Tooltip classes={{ tooltip: classes.tooltip }} title='Create new Spread' placement='top'>
                        <ButtonComp className={buttonStyles.createSpread} name={"New Spread"} action={handleNewSpread} isDisabled={checkedLandmarks.length < 5} />
                    </Tooltip>
                    <Tooltip classes={{ tooltip: classes.tooltip }} title='Move landmarks' placement='top'>
                        <IconButton className={buttonStyles.moveLandmarks} size='small' onClick={() => setOpenMoveClient(true)} disabled={checkedLandmarks.length === 0}>
                            <TrendingFlatIcon />
                        </IconButton>
                    </Tooltip>
                    {currentUserDate !== null &&
                        <Tooltip classes={{ tooltip: classes.tooltip }} title={'Done All Spreading'}>
                            <IconButton className={buttonStyles.doneSpreads} size='small' onClick={() => setOpenDialogSpreading(true)} disabled={checkedLandmarks.length !== landmarks.length}>
                                <TaskAltIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip classes={{ tooltip: classes.tooltip }} title='Deactivate' placement='top'>
                        <IconButton className={buttonStyles.doneSpreads} size='small' onClick={() => setDisActiveOpen(true)} disabled={checkedLandmarks.length === 0}>
                            <HighlightOffIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </Paper>
            <Dialog open={openNotes !== null} onClose={() => setOpenNotes(null)} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
                <DialogContent>
                    {handleClickNotes(openNotes)}
                </DialogContent>
            </Dialog>
            {openEditClient !== null && <EditClientDialogComp handleClose={() => setOpenEditClient(null)} landmarkId={openEditClient} setFlagNewLAndmarks={setFlagNewLAndmarks} />}
            <NewSpreadDialogComp open={openNewSpreadDialog} handleClose={() => setOpenNewSpreadDialog(false)} datesList={datesList} userList={userList} checkedLandmarks={checkedLandmarks} areaId={areaId} setFlagNewLAndmarks={setFlagNewLAndmarks} />
            <ConfirmationDialogComp message={`Are you sure you finshed all spreads?`} open={openDialogSpreading} handleYes={() => addSpreadData()} handleNo={() => setOpenDialogSpreading(false)} handleClose={() => setOpenDialogSpreading(false)} />
            <ConfirmationDialogComp message={`Are you sure you want to deactivate this landmarks?`} open={disActiveOpen} handleYes={() => handleDelete()} handleNo={() => setDisActiveOpen(false)} handleClose={() => setDisActiveOpen(false)} />
            <MoveLandmarkDialog open={openMoveClient} handleClose={() => setOpenMoveClient(false)} checkedLandmarks={checkedLandmarks} prevAreaId={areaId} />
            {/* <InfoDialogComp message={'landmarks were disactiveted successfully'} open={doneDisactive} handleClose={setDoneDisactive(false)} /> */}
        </div>
    );
}