import React, { useEffect, useState } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import RadioButtonComp from '../../components/RadioButtonComp/RadioButtonComp';
import CheckBoxComp from '../../components/CheckBoxComp/CheckBoxComp';
import TrapTypeComboBoxComp from '../../components/ComboBoxComp/TrapTypeComboBoxComp';
import classes from './TrapsAndLuresTable.module.css';
import comboStyles from '../ComboBoxComp/ComboBoxComp.module.css';

export default function TrapsAndLuresTable({traps, setTraps}) {
    const [typesList, setTypesList] = useState([]);

    function handleTrapChange(name, event) {
        if (event !== undefined) {
            let tmp = [...traps];
            for (let i = 0; i < tmp.length; i++) {
                if (tmp[i].trap_name === name) {
                    tmp[i].trap_check = event.target.checked;
                    setTraps(tmp);
                    return;
                }
            }
        }
    }

    function handleLureChange(trap_name, name, event) {
        if (event !== undefined) {
            let tmp = [...traps];
            for (let i = 0; i < tmp.length; i++) {
                if (tmp[i].trap_name === trap_name) {
                    for (let j = 0; j < tmp[i]["lures"].length; j++) {
                        if(tmp[i]["lures"][j].lure_name === name) {
                            tmp[i]["lures"][j].lure_check = !tmp[i]["lures"][j].lure_check;
                            setTraps(tmp);
                            return;
                        }
                    }
                }
            }
        }
    }

    async function getTypes() {
        const response = await restRequest('getCompanyTrapsTypes', 'get', {});
        setTypesList(response.data);
    }

    useEffect(() => {
        getTypes();
    }, []);

    return (
        <TableContainer className={classes.tableContainer}>
            <Table stickyHeader aria-label="sticky table" size="small">
                <TableBody>
                    {traps.map((trap) => {
                        return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={trap.trap_name}>
                            {[<TableCell align="left" className={classes.tableCell}>
                                <CheckBoxComp label={trap.trap_name} checked={trap.trap_check} onChange={(event) => handleTrapChange(trap.trap_name, event)} />
                            </TableCell>].concat(
                                <TableCell align="left" className={classes.tableCell}>
                                    <TrapTypeComboBoxComp className={comboStyles.typeField} name={"Type:"} paramsList={typesList} value={trap.trap_type} setValue={setTraps} trap={trap} traps={traps} required={true} />
                                </TableCell>).concat(
                                    trap["lures"].map(lure =>
                                        <TableCell align="left" className={classes.tableCell}>
                                            <RadioButtonComp label={lure.lure_name} checked={lure.lure_check} onClick={(event) => handleLureChange(trap.trap_name, lure.lure_name, event)} isDisabled={!trap.trap_check} />
                                        </TableCell>))}
                        </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
      </TableContainer>
  );
}