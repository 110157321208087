import { restRequest } from '../../serverAPI/restRequests';
import jsPDF from "jspdf";
import 'jspdf-autotable';
import logo from '../../images/logo.jpg';
import JsBarcode from 'jsbarcode';

export default async function PdfComp({ insectSiteLat, insectSiteLong, insectSiteName, insectId, insectPlacementDate, insectPickupDate, genus, species, sex, insectImages }) {
    function StringToDate(str) {
        if (str === "")
            return "";
        var d = new Date(str);
        return `${String(d.getDate()).padStart(2, '0')}/${String(d.getMonth() + 1).padStart(2, '0')}/${d.getFullYear()}`;
    }

    var doc = new jsPDF('portrait', 'px', 'a4', 'false');

    const response = await restRequest('getSiteAddressAndDirections', 'post', { name: insectSiteName });
    let siteAddress = response.data.address;
    let siteDirections = response.data.directions;

    let location_road = "https://maps.googleapis.com/maps/api/staticmap?center=" + insectSiteLat + "," + insectSiteLong + "&zoom=13&size=450x350&maptype=roadmap&key=AIzaSyDJY_AHMePpknxD_uVYwe0vJ_1fq9aLLSA";

    // (filename, type, x, y, width, height)
    doc.addImage(logo, 'PNG', 10, 8, 70, 30);

    doc.setFont('Helvertica', 'bold');
    doc.setFontSize(19);
    doc.text(140, 30, 'Insect information');
    doc.text(0, 40, '________________________________________________________________________________________');
    doc.setFontSize(13);
    doc.text(15, 60, 'Company name:');
    doc.text(15, 80, 'Username:');
    doc.text(15, 100, 'Insect id:');
    doc.text(15, 120, 'Site name:');
    doc.text(15, 140, 'Placement date:');
    doc.text(15, 160, 'Pickup date:');
    doc.text(15, 180, 'Address:');
    doc.text(15, 200, 'Directions:');
    doc.text(15, 220, 'Identification:');
    doc.text(100, 220, 'Genus:');
    doc.text(220, 220, 'Species:');
    doc.text(350, 220, 'Sex:');

    const response2 = await restRequest('getCompanyNameAndUsername', 'get', {});

    doc.setFont('Helvertica', 'normal');
    doc.text(100, 60, response2.data.companyName);
    doc.text(100, 80, response2.data.username);
    doc.text(100, 100, insectId);
    doc.text(100, 120, insectSiteName);
    doc.addImage(location_road, 'PNG', 300, 110, 100, 95);
    doc.text(100, 140, StringToDate(insectPlacementDate));
    doc.text(100, 160, StringToDate(insectPickupDate));
    if (siteAddress !== "" && siteAddress !== null) {
        doc.text(100, 180, siteAddress);
    }
    else {
        doc.text(100, 180, "N/A");
    }
    if (siteDirections !== "" && siteDirections !== null) {
        doc.text(100, 200, siteDirections);
    }
    else {
        doc.text(100, 200, "N/A");
    }
    if (genus !== "" && genus !== undefined) {
        doc.text(140, 220, genus);
    }
    else {
        doc.text(140, 220, "N/A");
    }
    if (species !== "" && species !== undefined) {
        doc.text(260, 220, species);
    }
    else {
        doc.text(260, 220, "N/A");
    }
    if (sex !== "" && sex !== undefined) {
        doc.text(375, 220, sex);
    }
    else {
        doc.text(375, 220, "N/A");
    }

    // create and add a barcode from insectId
    var qr_canvas = document.createElement('canvas');
    JsBarcode(qr_canvas, insectId, {
        format: "CODE128",
        lineColor: "black",
        width: 1.5,
        height: 100,
        font: "monospace",
        textMargin: 3,
        fontSize: 20,
        displayValue: true
    });
    doc.addImage(qr_canvas, "JPEG", 297, 50, 104, 44);

    // add images jpeg format
    var img_load_count = 0;
    var img_count = 0;
    let images_arr = [];
    for (let i = 0; i < insectImages.length; i++) {
        for (let j = 0; j < insectImages[i]['focus_num']; j++) {
            img_load_count++;
            let url = insectImages[i]['path'].slice(0, -4) + "_bright_f" + (j + 1) + ".jpg";
            url = 'https://api.codetabs.com/v1/proxy?quest=' + url;
            images_arr.push(new Image());
            images_arr[img_count].crossOrigin = 'Anonymous';
            images_arr[img_count].src = url;
            images_arr[img_count].onload = function () {
                doc.addImage(images_arr[img_count].src, 'JPEG', 35 + (125 * (img_count % 3)), 230 + (100 * parseInt(img_count / 3)), 120, 95);
                img_count++;
                img_load_count--;
            };

            // wait till image is loaded and added to pdf
            function until(condition) {
                const poll = resolve => {
                    if (condition()) resolve();
                    else setTimeout(_ => poll(resolve), 400);
                }
                return new Promise(poll);
            }
            await until(_ => img_load_count === 0);
        }
    }

    // save pdf file
    doc.save('insects-info.pdf');
}