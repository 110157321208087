import React from 'react';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import ButtonComp from '../ButtonComp/ButtonComp';
import classes from '../../pages/LoginPage/LoginPage.module.css';

const Form = ({name, password, setName, setPassword, validate}) => {
    return (
        <form className={classes.loginBox} onSubmit={(e) => console.log(e)}>
            <TextFieldComp className={classes.nameField} value={name} setValue={setName} label={"Name"} typeOfTextField={"filled"} required={true} />
            <TextFieldComp className={classes.passwordField} value={password} setValue={setPassword} label="Password" type={"password"} typeOfTextField={"filled"} required={true} />
            <ButtonComp className={classes.loginBtn} name={"LOGIN"} action={validate} />
        </form>
    )
}

export default Form;