import React, { useEffect, useState } from 'react';
import "date-fns";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";
import * as XLSX from 'xlsx';
import { restRequest } from '../../serverAPI/restRequests';

export default function DateRangeDialog({ className }) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    function generateExcel(data) {
        console.log(data);
        const wb = XLSX.utils.book_new();

        Object.keys(data).forEach((areaName) => {
            // Ensure sheet name does not exceed 31 characters
            const sheetName = areaName.length > 31 ? areaName.substring(0, 31) : areaName;

            const areaData = data[areaName];
            const wsData = [
                ['Address', 'Client Name', 'Cell Phone', 'Email', 'Active', 'Rolls Number', 'Date']
            ];

            Object.values(areaData).forEach((landmarkData) => {
                const [address, clientName, cellPhone, email, active, rollsNumber, date] = landmarkData;
                wsData.push([address, clientName, cellPhone, email, active, rollsNumber, date]);
            });

            const ws = XLSX.utils.aoa_to_sheet(wsData);

            XLSX.utils.book_append_sheet(wb, ws, sheetName);
        });

        // Create Excel file
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
            return buf;
        }

        const fileName = 'landmarks.xlsx';
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });

        // Trigger file download
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setStartDate(null);
        setEndDate(null);
    }

    async function getDataOnAllLandmarks() {
        let response = await restRequest('getDataOnAllLandmarks', 'post',
            {
                startDate: startDate.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }),
                endDate: endDate.toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                })
            });
        generateExcel(response.data);
    }

    const handleDateChange = (dates) => {
        setStartDate(dates[0]);
        setEndDate(dates[1]);
    };

    useEffect(() => {
        if (startDate !== null && endDate !== null) {
            getDataOnAllLandmarks();
        }
    }, [startDate, endDate])

    return (
        <DateRangePicker
            placement="left"
            appearance="default"
            placeholder="Pickup Date:"
            onChange={handleDateChange}
            className={className} />
    );
}