import React from 'react';
import classes from '../TreatmentPage/TreatmentPage.module.css'

export default function TreatmentPage() {

    return (
        <div>
            <h2 className={classes.description}>"Treatment" page allows management of county treatment activities and synchronize with trapping data results on the map.</h2>
            <h2 className={classes.description}>This feature is not open for the conference sandbox.</h2>
        </div>
    )
}