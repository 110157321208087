import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import Button from '@material-ui/core/Button';
import { DialogTitle, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import InfoDialogComp from './InfoDialogComp';
import ComboBoxComp from '../ComboBoxComp/ComboBoxComp';
import comboStyle from '../ComboBoxComp/ComboBoxComp.module.css';
import DateComp from '../DateComp/DateComp';
import { Alert } from '@material-ui/lab';

export default function NewSpreadDialogComp({ open, handleClose, datesList, userList, checkedLandmarks, areaId, setFlagNewLAndmarks }) {
    const [userName, setUserName] = useState(null);
    const [date, setDate] = useState(null);
    const [areaName, setAreaName] = useState(null);
    const [spreadAddedConfirmation, setSpreadAddedConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const [failAlert, setFailAlert] = useState(false);



    function handleCloseDialog() {
        setUserName(null);
        setDate(null);
        setAreaName(null);
        handleClose();
        setSpreadAddedConfirmation(false);
        setFailAlert(false);
    }

    async function handleSaveDialog() {
        let formattedDate = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
        if (datesList.includes(formattedDate)) {
            setFailAlert(true);
            return;
        }
        setLoading(true);
        const data = {
            areaId: areaId,
            date: formattedDate,
            spreadsArray: checkedLandmarks,
            areaName: areaName,
            userName:userName
        }
        console.log(data)
        await restRequest('createNewSpread', 'post', data);
        setFlagNewLAndmarks(prev => (prev + 1));
        handleCloseDialog();
        handleClose();
        setLoading(false);
    }


    return (
        <div>
            <Dialog open={open} onClose={handleCloseDialog}>
                <DialogTitle>Add New Spread</DialogTitle>
                <DialogContent>
                    <TextFieldComp value={areaName} setValue={setAreaName} label={"Area name"} />
                    <DateComp style="display: grid;" name={"Spread Date"} value={date} setValue={setDate} />
                    <ComboBoxComp name={"User:"} paramsList={userList} value={userName} setValue={setUserName} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveDialog} color="primary" disabled={date === null || areaName === null || areaName === '' || userName === null} >
                        Save
                    </Button>
                    {loading &&
                        <CircularProgress size={30} disableShrink />
                    }
                </DialogActions>
                {failAlert &&
                    <Alert severity="error">Some landmarks are already exists on this date!</Alert>
                }
            </Dialog>

            <InfoDialogComp message={'Spread created successfully'} open={spreadAddedConfirmation} handleClose={handleCloseDialog} />
        </div >
    );
}