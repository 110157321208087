import React, { useEffect, useState } from 'react';
import { Alert } from '@material-ui/lab';
import { restRequest } from '../../serverAPI/restRequests';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CollectionsTableComp from '../../components/Tables/CollectionsTableComp';
import MapComp from '../../components/MapComp/CollectionsMapComp';
import FilterComp from '../../components/FilterComp/FilterComp';
import classes from './HomePage.module.css';
import { IconButton } from 'rsuite';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function HomePage({ refresh, setRefresh, socket, collectionsIdsInWork, setCollectionsIdsInWork, checkedCollections, setCheckedCollections, setFilterParams, filterParams, collectionOfCheckedVirus, setCollectionOfCheckedVirus, collectionOfInsect, setCollectionOfInsect, StringToDate, setKindsList, setSitesId, setCompanyRuns, setSiteFromPopUp, siteFromRule, setSiteFromRule, setUserMessages, setUserRules }) {
    const [failViewInsect, setFailViewInsect] = useState(false);
    const [collections, setCollections] = useState([]);
    const [runs, setRuns] = useState([]);
    const [filteredCollections, setFilteredCollections] = useState([]);
    const [leftToIdentify, setLeftToIdentify] = useState(0);
    const [secondOpinion, setSecondOpinion] = useState(0);
    const [numberOfCollections, setNumberOfCollections] = useState(0);
    const [doneLoadingCollections, setDoneLoadingCollections] = useState(0);
    const [managementMode, setManagementMode] = useState(''); // '','enter','shows','exit','hidden'
    const [collectionsWithEmptySites, setCollectionsWithEmptySites] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(3);     // rows presented at the collections table
    const [positiveCollection, setPositiveCollection] = useState([]);
    const [selectDeployed, setSelectDeployed] = useState(true); // state for selecting deployed
    const [page, setPage] = useState(0);
    const [selectProcessing, setSelectProcessing] = useState(true);
    const [selectCompleted, setSelectCompleted] = useState(false); // state for selecting Completed
    const [showMinimizeButton, setShowMinimizeButton] = useState(false);
    const [hideFiltersDiv, setHideFiltersDiv] = useState(false);
    const [emptySites, setEmptySites] = useState(null);
    const [sitesNamesList, setSitesNamesList] = useState([]); // filtered names
    const [runsList, setRunsList] = useState([]); // filtered runs
    const [emptySiteAddedFlag, setEmptySiteAddedFlag] = useState(false);
    const [zoomInFilteredCollections, setZoomInFilteredCollections] = useState(false);
    const [isTableHidden, setIsTableHidden] = useState(true);
    const [companyId, setCompanyId] = useState('');
    const [center, setCenter] = useState(null);
    const [noSitesPopUp, setNoSitesPopUp] = useState(false);

    // returns the company collection with site info, insects ids and if there is second opinion on this collection
    async function getCompanyCollections() {

        // first time home page is rendered
        if (!window.sessionStorage.getItem('collectionsNoFilter') || refresh == true) {
            const messages = await restRequest('getUserMessages', 'post', { userName: sessionStorage.getItem('userName') });
            console.log(messages.data.messages)
            setUserMessages(messages.data.messages);
            setUserRules(messages.data.rulesName);
            sessionStorage.setItem('userRules', JSON.stringify(messages.data.rulesName));
            sessionStorage.setItem('userMessages', JSON.stringify(messages.data.messages));
            const pageSize = 30;
            const response = await restRequest('getCompanyCollections', 'post', { page: 1, pageSize: pageSize });
            const count = await restRequest('getNumberOfCompanyCollections', 'get');
            setNumberOfCollections(count.data)
            const totalCollections = response.data.companyCollections;
            const totalPages = Math.round(count.data / pageSize) + 1;
            setCollections(totalCollections);
            setFilteredCollections(totalCollections);
            setCheckedCollections([]);
            setRuns(response.data.runs);
            setCompanyRuns(response.data.runs);
            sessionStorage.setItem('companyRuns', JSON.stringify(response.data.runs))
            getEmptySites();
            setDoneLoadingCollections(1);
            sessionStorage.setItem('company_id', response.data.company_id)
            setCompanyId(response.data.company_id)

            for (let i = 2; i <= totalPages; i++) {
                const pageResponse = await restRequest('getCompanyCollections', 'post', { page: i, pageSize: pageSize });
                totalCollections.push(...pageResponse.data.companyCollections);
                setCollections(totalCollections);
                setFilteredCollections(totalCollections);
                setCheckedCollections([]);
            }
            setCheckedCollections([]);
            sessionStorage.setItem('collectionsNoFilter', JSON.stringify(totalCollections));
            setRefresh(false);
        }
        // homepage re-render so basic collections are already saved and no filters applies
        else {
            let messages = JSON.parse(window.sessionStorage.getItem('userMessages'))
            let rules = JSON.parse(window.sessionStorage.getItem('userRules'))
            setUserRules(rules);
            setUserMessages(messages);
            let collectionsNoFilter = JSON.parse(window.sessionStorage.getItem('collectionsNoFilter'));
            setNumberOfCollections(collectionsNoFilter.length);
            setCompanyRuns(JSON.parse(window.sessionStorage.getItem('companyRuns')));
            setRuns(JSON.parse(window.sessionStorage.getItem('companyRuns')));
            setCompanyId(window.sessionStorage.getItem('company_id'));
            setCollections(collectionsNoFilter);
            setFilteredCollections(collectionsNoFilter);
            setCheckedCollections([]);
            getEmptySites();
            setDoneLoadingCollections(1);
        }
    }

    // gets  all kinds
    async function getKinds() {
        const response = await restRequest('getKinds', 'get', {});
        const kindsNames = [...new Set(response.data
            .filter(kind => kind.species !== null && kind.species !== 'Unidentified')
            .map(kind => kind.genus + ' ' + kind.species)
        )];
        kindsNames.push('All');
        setKindsList(kindsNames);
    }

    const hideTable = () => {
        setIsTableHidden(true);
    };

    const showTable = () => {
        setIsTableHidden(false);
    };

    async function getLeftToIdentify() {
        const response = await restRequest('LeftToIdentify', 'get', {});
        setLeftToIdentify(response.data);
    }

    async function secondOpinions() {
        const response = await restRequest('SecondOpinions', 'get', {});
        setSecondOpinion(response.data);
    }

    async function getPositiveCollection() {
        const response = await restRequest('getPositivePooling', 'get', {});
        setPositiveCollection(response.data);
    }

    function hideFiltersDivFunc() {
        setHideFiltersDiv(!hideFiltersDiv);
    }

    async function getEmptySites() {
        const response = await restRequest(`getCompanyEmptySites`, 'get', {});
        let sites = response.data;
        if (sites.length > 0) {
            setEmptySites(sites);
        }
    }

    async function getCompanySites() {
        const response = await restRequest('getCompanySites', 'get', {});
        const ids = response.data.map(site => site.mapVision_id ? site.mapVision_id : site._id);
        setSitesId(ids);
    }

    // add all the empty sites to the map
    function addEmptySites() {
        if (collections.length + emptySites.length > collectionsWithEmptySites.length) {
            let sitesToCollections = [];
            emptySites.forEach((site) => {
                sitesToCollections.push({
                    company_id: site.company_id,
                    site_id: site._id,
                    mapVision_id: site.mapVision_id ? site.mapVision_id : null,
                    site_lat: site.latitude,
                    site_lng: site.longitude,
                    site_name: site.name,
                    site_directions: site.directions ? site.directions : '',
                    site_address: site.address ? site.address : '',
                    _id: 'empty'
                });
            });
            setCollectionsWithEmptySites([...collections, ...sitesToCollections]);
        }
    }

    const currentFilteredCollections = filteredCollections.filter((collection) => ((collection.state === 0 && selectDeployed) || (collection.state === 1 && selectProcessing) || (collection.state === 2 && selectCompleted)));
    const collectionsToShowOnMap = managementMode === 'shows' ? filteredCollections : currentFilteredCollections;
    const collectionsToShowInTable = managementMode === 'shows' ? filteredCollections : currentFilteredCollections;
    const numberOfCollectionsToShowInTable = managementMode === 'shows' ? numberOfCollections : currentFilteredCollections.length;

    useEffect(() => {
        console.log(socket)
        setFilterParams({ site_name: null, site_id: null, position: null, barcode: null, run: null, insectID: null, viewUnidentified: true, viewUnverified: false, genus: null, species: null, taggedBy: null, virus: null, secondOpinion: false });
        getCompanyCollections();
        getPositiveCollection();
        getLeftToIdentify();
        secondOpinions();
        getKinds();
        getCompanySites();
    }, []);

    useEffect(() => {
        if (managementMode === 'enter') {
            if (emptySites && emptySites.length > 0) {
                addEmptySites();
            }
            setManagementMode('shows');
        }
        else if (managementMode === 'exit') {
            setManagementMode('hidden');
        }
    }, [managementMode]);

    useEffect(() => {
        if (emptySites && emptySites.length > 0 && emptySiteAddedFlag) {
            addEmptySites();
            setEmptySiteAddedFlag(false);
        }
    }, [emptySites, emptySiteAddedFlag]);

    useEffect(() => {
        if (refresh == true) {
            getCompanyCollections();
            setDoneLoadingCollections(0);
        }
    }, [refresh]);

    return (
        <div className={classes.wrapper1}>
            <div className={classes.filters} onMouseOver={() => { setShowMinimizeButton(true) }}>
                {!hideFiltersDiv &&
                    <FilterComp refresh={refresh} setRefresh={setRefresh} setZoomInFilteredCollections={setZoomInFilteredCollections} collections={collections} runs={runs} setFilteredCollections={setFilteredCollections}
                        filterParams={filterParams} setFilterParams={setFilterParams} page={page} setPage={setPage} center={center}
                        managementMode={managementMode === 'shows'} doneLoadingCollections={doneLoadingCollections === 1} setNoSitesPopUp={setNoSitesPopUp}
                        sitesNamesList={sitesNamesList} setSitesNamesList={setSitesNamesList} runsList={runsList} setRunsList={setRunsList} collectionsWithEmptySites={collectionsWithEmptySites} />
                }
                {!hideFiltersDiv && showMinimizeButton &&
                    <div className={classes.minimizeIcon}>
                        <IconButton className={classes.minimizebutton} onClick={hideFiltersDivFunc}>
                            <ArrowBackIosIcon />
                        </IconButton>
                    </div>
                }
                {hideFiltersDiv &&
                    <div className={classes.maximizeIcon}>
                        <IconButton className={classes.minimizebutton} onClick={hideFiltersDivFunc}>
                            <ArrowForwardIosIcon />
                        </IconButton>
                    </div>
                }
            </div>
            <div className={classes.map} onMouseOver={() => setShowMinimizeButton(false)}>
                {rowsPerPage !== 15 &&
                    <MapComp collections={collectionsToShowOnMap} companyId={companyId} companyCollections={collections} setCompanyCollections={setCollections}
                        setFilteredCollections={setFilteredCollections} selectDeployed={selectDeployed} setCollectionsWithEmptySites={setCollectionsWithEmptySites} filteredCollections={filteredCollections}
                        setSelectDeployed={setSelectDeployed} selectProcessing={selectProcessing} setSelectProcessing={setSelectProcessing} selectCompleted={selectCompleted}
                        setSelectCompleted={setSelectCompleted} positiveCollection={positiveCollection} checkedCollections={checkedCollections} setCheckedCollections={setCheckedCollections} filterParams={filterParams}
                        setFailViewInsect={setFailViewInsect} collectionOfCheckedVirus={collectionOfCheckedVirus} setCollectionOfCheckedVirus={setCollectionOfCheckedVirus} collectionOfInsect={collectionOfInsect}
                        setManagementMode={setManagementMode} managementMode={managementMode === 'shows'} emptySites={emptySites} setEmptySites={setEmptySites} setEmptySiteAddedFlag={setEmptySiteAddedFlag} runsList={runsList} setRunsList={setRunsList}
                        doneLoadingCollections={doneLoadingCollections === 1} setCollectionOfInsect={setCollectionOfInsect} sitesNamesList={sitesNamesList} setSitesNamesList={setSitesNamesList} StringToDate={StringToDate} zoomInFilteredCollections={zoomInFilteredCollections}
                        setZoomInFilteredCollections={setZoomInFilteredCollections} center={center} setCenter={setCenter} noSitesPopUp={noSitesPopUp} setNoSitesPopUp={setNoSitesPopUp} setSiteFromPopUp={setSiteFromPopUp} siteFromRule={siteFromRule} setSiteFromRule={setSiteFromRule} />
                }

                <div className={classes.wrapper2}>
                    <div className={classes.leftToIdentify}>
                        <label>Number of insects left to identify: {leftToIdentify}</label>
                    </div>
                    <label>Number of second opinion: {secondOpinion}</label>
                </div>
                {failViewInsect &&
                    <Alert severity="error">No insects to view at the choosen collection that maches the filters</Alert>
                }
                <div className={classes.table}>
                    <div className={classes.parentContainer}>
                        {!isTableHidden && (
                            <CollectionsTableComp
                                socket={socket}
                                collectionsIdsInWork={collectionsIdsInWork}
                                setCollectionsIdsInWork={setCollectionsIdsInWork}
                                positiveCollection={positiveCollection}
                                checkedCollections={checkedCollections}
                                setCheckedCollections={setCheckedCollections}
                                collections={collectionsToShowInTable}
                                numberOfCollections={numberOfCollectionsToShowInTable}
                                page={page}
                                setPage={setPage}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                filterParams={filterParams}
                                setFailViewInsect={setFailViewInsect}
                                StringToDate={StringToDate}
                            />
                        )}
                        <div className={classes.tableButton}>
                            {isTableHidden ? (
                                <IconButton onClick={showTable}>
                                    <ExpandLessIcon />
                                </IconButton>
                            ) : (
                                <IconButton onClick={hideTable}>
                                    <ExpandMoreIcon />
                                </IconButton>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}