import React from 'react';
import Button from '@material-ui/core/Button';

export default function ButtonComp({className, name, action, isDisabled}) {

  return (
    <Button className={className} disabled={isDisabled} variant="outlined" size="medium" onClick={action}>
      {name}
    </Button>
  );
}