import React from 'react';
import classes from './ScaleComp.module.css';

export default function ScaleComp({ scaleRatio, imageWidth }) {
  return (
    <div>
      <div>
        <label className={classes.horizontalLabel}>{
          ((1000/scaleRatio).toFixed(0)) + ' mm'}
        </label>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
          <div>|</div>
          <div style={{ width: imageWidth, borderBottom: '2px solid black' }}></div>
          <div>|</div>
        </div>
      </div>
    </div>
  );
}