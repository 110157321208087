import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { Dialog, CircularProgress, DialogTitle, DialogContent, DialogActions, Button, IconButton, Tooltip } from '@material-ui/core';
import TextFieldComp from '../../components/TextFieldComp/TextFieldComp';
import * as XLSX from 'xlsx';
import InfoDialogComp from './InfoDialogComp'
import { useDropzone } from 'react-dropzone';
import ComboBox from '../ComboBoxComp/ComboBoxComp';
import SwitchComp from '../../components/SwitchComp/SwitchComp';
import CheckBoxComp from '../../components/CheckBoxComp/CheckBoxComp';
import MultipleComboBox from '../ComboBoxComp/MultipleComboBox';
import classes from './NewSiteDialogComp.module.css';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

export default function FileUploadComp({ open, setOpen, setFlagNewLAndmarks, areas, users }) {
    const [areaName, setAreaName] = useState('');
    const [loadingFile, setLoadingFile] = useState(false);
    const [fileConfirmation, setFileConfirmation] = useState(false);
    const [newArea, setNewArea] = useState('New Area');
    const [existingArea, setExistingArea] = useState(null);
    const [usersSelected, setUsersSelected] = useState([]);
    const [dayList, setDayList] = useState(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    const [daySelected, setDaySelected] = useState([]);
    const [divideNumber, setDivideNumber] = useState(1);

    const handleClose = () => {
        setOpen(false);
        setAreaName('');
        setDivideNumber(1);
        setExistingArea(null);
    };

    const onDrop = (acceptedFiles) => {
        handleClose();
        setLoadingFile(true);
        const file = acceptedFiles[0];
        const reader = new FileReader();
    
        reader.onload = async (e) => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            console.log(jsonData)
            const jsonWithLatLng = [];
            for (let i = 0; i < jsonData.length; i++) {
                const results = await geocodeByAddress(jsonData[i][0]);
                const { lat, lng } = await getLatLng(results[0]);
                console.log(lat,lng)
                jsonWithLatLng.push([
                    ...(jsonData[i].slice(0, 9)), // Keep the first 8 elements
                    lat,
                    lng
                ]);
            }
            console.log(jsonWithLatLng)
            const newUsersSelected = [];
            if (divideNumber > 0) {
                for (let i = 0; i < divideNumber; i++) {
                    newUsersSelected.push(usersSelected[0]);
                    console.log(newUsersSelected);
                }
                setUsersSelected(newUsersSelected);
            }
    
            const allData = {
                areaName: existingArea ? existingArea : areaName,
                users: existingArea ? ['1'] : (newUsersSelected.length > 0 ? newUsersSelected : usersSelected),
                'landmarkes': jsonWithLatLng,
                day: daySelected
            };
            console.log(allData);
    
            let response = await restRequest(`createLandmarks`, 'post', allData);
            setFileConfirmation(true);
            setFlagNewLAndmarks(prev => (prev + 1));
            setUsersSelected([]);
        };
    
        setLoadingFile(false);
        reader.readAsBinaryString(file);
    };
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
        <div>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>File Upload</DialogTitle>
                <DialogContent>
                    <SwitchComp label={newArea} setLabel={setNewArea} primaryLbl={"New Area"} secondaryLbl={"Add to existing area"} />
                    {newArea === 'Add to existing area' &&
                        <ComboBox name={"Area"} paramsList={areas} value={existingArea} setValue={setExistingArea} />}
                    {newArea === 'New Area' &&
                        <div className={classes.container}>
                            <TextFieldComp value={areaName} setValue={setAreaName} label={"Area Name"} />
                            <MultipleComboBox name={"Couriers:"} paramsList={users} value={usersSelected} setValue={setUsersSelected} />
                            {usersSelected.length === 1 && <TextFieldComp value={divideNumber} setValue={setDivideNumber} label={"Number of paths: "} type={"number"} />}
                            <MultipleComboBox name={"Spreading Day"} paramsList={dayList} value={daySelected} setValue={setDaySelected} />
                        </div>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button  {...getRootProps()} disabled={existingArea === null && areaName === '' && usersSelected.length === 0 && daySelected.length === 0}>
                        save
                        <input {...getInputProps()} />
                    </Button>
                </DialogActions>
                {loadingFile && <CircularProgress size={30} disableShrink />}
            </Dialog >
            <InfoDialogComp message={'File uploaded successfully'} open={fileConfirmation} handleClose={() => setFileConfirmation(false)} />
        </div>
    );
}