import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { restRequest } from '../../serverAPI/restRequests';

export default function DenseTable({ collection }) {

  const [rows, setRows] = useState([]);

  const useStyles = makeStyles({
    table: {
      minWidth: 250,
      border: 'ridge',
    },
  });

  async function getData() {
    const response = await restRequest('getCollectionInsectsInfo', 'post', { collection_id: collection._id });
    setRows(response.data);
  }

  const classes = useStyles();

  useEffect(() => {
    getData();
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {rows.traps && rows.traps.map(trap =>
              <TableCell  align="center" colSpan={2}>{trap}</TableCell>
            )}
          </TableRow>
          <TableRow>
            <TableCell style={{ border: 'ridge' }}></TableCell>
            {rows.traps && rows.traps.map(trap =>
              [<TableCell style={{ border: 'ridge' }} align="center">Male</TableCell>,
              <TableCell style={{ border: 'ridge' }} align="center">Female</TableCell>,
              <TableCell style={{ border: 'ridge' }} align="center">Unidentified</TableCell>]
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.data && Object.entries(rows.data).sort(function (a, b) {
            var textA = a[0].toUpperCase();
            var textB = b[0].toUpperCase();
            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          }).map(([key, val]) => (
            <TableRow>
              <TableCell style={{ border: 'ridge' }} align="center">
                {key.split(" ")[1] + " " + key.split(" ")[0]}
              </TableCell>
              {rows.traps.map(trap =>
                [<TableCell style={{ border: 'ridge' }} align="right">{val[trap] && val[trap]["Male"] ? val[trap]["Male"] : 0}</TableCell>,
                <TableCell style={{ border: 'ridge' }} align="right">{val[trap] && val[trap]["Female"] ? val[trap]["Female"] : 0}</TableCell>,
                <TableCell style={{ border: 'ridge' }} align="right">{val[trap] && val[trap]["Unidentified"] ? val[trap]["Unidentified"] : 0}</TableCell>]
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}