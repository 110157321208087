import * as React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

export default function FormControlLabelPlacement({className, label, checked, onClick, onChange, isDisabled}) {
  return (
    <FormControl>
      <RadioGroup row aria-labelledby="demo-form-control-label-placement" name="position" defaultValue="top">
        <FormControlLabel 
            value="end" 
            control={<Radio className={className} checked={checked} onChange={onChange} onClick={onClick}/>} 
            label= {label}
            disabled={isDisabled}
        />
      </RadioGroup>
    </FormControl>
  );
}