import React, { useEffect } from 'react';
import { useState } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import Grid from '@material-ui/core/Grid';
import { Dialog, CircularProgress, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@material-ui/core';
import TextFieldComp from '../../components/TextFieldComp/TextFieldComp';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationDialogComp from '../../components/DialogComp/ConfirmationDialogComp';
import classes from '../DialogComp/NewSiteDialogComp.module.css';


export default function EditSiteDialogComp({ open, siteId, containsCollection, setDeleteSiteFlag, siteName, siteAddress, siteDirections, handleClose, setSiteEditedConfirmation, companyCollections, setCompanyCollections, collections, setCollections, filteredCollections, setFilteredCollections, sitesNamesList, setSitesNamesList, siteLat, siteLong }) {
    const [originName, setOriginName] = useState(siteName);
    const [name, setName] = useState(siteName);
    const [address, setAddress] = useState(siteAddress);
    const [siteLongitude, setSiteLongitude] = useState(siteLong);
    const [siteLatitude, setSiteLatitude] = useState(siteLat);
    const [directions, setDirections] = useState(siteDirections);
    const [deleteFromSites, setDeleteFromSites] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleEnterDeleteMode = () => {
        setDeleteFromSites(true);
    }

    async function handleYesDelete() {
        const response = await restRequest('deleteSite', 'put', { site_id: siteId });
        setLoading(false);
        handleClose();
        setDeleteFromSites(false);
        setDeleteSiteFlag(siteId);

    }

    function handleNoDelete() {
        setDeleteFromSites(false);
    }

    useEffect(() => {
        if (siteName === null || siteName === '') {
            return;
        }
        setName(siteName);
        setAddress(siteAddress);
        setDirections(siteDirections);
        setOriginName(siteName);
        setSiteLatitude(siteLat);
        setSiteLongitude(siteLong);
    }, [siteName, siteAddress, siteDirections, siteLat, siteLong])


    async function handleSave() {
        // send new site information to server (to store in DB)
        const data = { site_id: siteId, new_name: name ? name : '', new_address: address ? address : '', new_directions: directions ? directions : '' }
        await restRequest('editSite', 'put', data);

        // update collections (outer level from homepage and session storage)
        const newCompanyCollections = companyCollections.map(collection => {
            if (collection.site_id && collection.site_id === siteId) {
                return { ...collection, site_name: name, site_address: address, site_directions: directions };
            }
            return collection;
        });
        setCompanyCollections(newCompanyCollections);
        sessionStorage.setItem('collectionsNoFilter', JSON.stringify(newCompanyCollections));

        // update collections with empty sites (outer level from homepage)
        const newCollectionsWithEmptySites = collections.map(collection => {
            if (collection.site_id && collection.site_id === siteId) {
                return { ...collection, site_name: name, site_address: address, site_directions: directions };
            }
            return collection;
        });
        setCollections(newCollectionsWithEmptySites);

        // update collections with empty sites (outer level from homepage)
        const newFilteredCollections = filteredCollections.map(collection => {
            if (collection.site_id && collection.site_id === siteId) {
                return { ...collection, site_name: name, site_address: address, site_directions: directions };
            }
            return collection;
        });
        setFilteredCollections(newFilteredCollections);

        // set filtered names list (outer level from homepage and session storage)
        const newSitesNamesList = sitesNamesList.map(site_name => {
            if (site_name === originName) {
                return name;
            }
            return site_name;
        });
        setSitesNamesList(newSitesNamesList);
        let filtersBasicValues = JSON.parse(window.sessionStorage.getItem('filtersBasicValues'));
        const newAllSitesNamesList = filtersBasicValues.sitesNames.map(site_name => {
            if (site_name === originName) {
                return name;
            }
            return site_name;
        });
        filtersBasicValues.sitesNames = newAllSitesNamesList;
        window.sessionStorage.setItem('filtersBasicValues', JSON.stringify(filtersBasicValues));

        setName('');
        setAddress('');
        setDirections('');
        setSiteEditedConfirmation(true);
        handleClose();
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Edit Site
            </DialogTitle>

            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <TextFieldComp value={name} setValue={setName} label={"Site name"} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextFieldComp value={address} setValue={setAddress} label={"Site address"} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextFieldComp value={siteLatitude} setValue={setSiteLatitude} label={"Site Latitude"} isDisabled={true} />
                    </Grid>
                    <Grid item xs={5}>
                        <TextFieldComp value={directions} setValue={setDirections} label={"Site Directions"} />
                    </Grid>
                    <Grid >
                        <TextFieldComp value={siteLongitude} setValue={setSiteLongitude} label={"Site Longitude"} isDisabled={true} />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {loading &&
                    <CircularProgress className={classes.spinner} size={30} disableShrink />
                }
                <IconButton onClick={handleEnterDeleteMode} disabled={containsCollection == true}>
                    <DeleteIcon />
                </IconButton>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSave} color="primary" disabled={name === null || name === ''}>
                    Save
                </Button>
                <ConfirmationDialogComp message={`Are you sure you want to delete this site?`} open={deleteFromSites} handleYes={() => handleYesDelete()} handleNo={handleNoDelete} handleClose={() => { setDeleteFromSites(false); }} />
            </DialogActions>
        </Dialog >
    )
}