import React, { useEffect, useState } from 'react';
import AppBar from "@material-ui/core/AppBar";
import { Switch, Route, Redirect, BrowserRouter as Router } from "react-router-dom";
import { restRequest, connectSocket } from './serverAPI/restRequests';
import Nav from './components/Layout/Nav/Nav';
import HomePage from './pages/HomePage/HomePage';
import LoginPage from './pages/LoginPage/LoginPage';
import CreateCollectionPage from './pages/CreateCollectionPage/CreateCollectionPage';
import EditCollectionPage from './pages/EditCollectionPage/EditCollectionPage';
import TreatmentPage from './pages/TreatmentPage/TreatmentPage';
import ManualUploadPage from './pages/ManualUploadPage/ManualUploadPage';
import PoolingManagementPage from './pages/PoolingManagementPage/PoolingManagementPage';
import IdentificationPage from './pages/IdentificationPage/IdentificationPage';
import RoadPathPage from './pages/RoadPath/RoadPathPage';
import SurveyPage from './pages/SurveyPage/SurveyPage';

export default function App() {
  const auth = window.sessionStorage.getItem('token') !== null;
  const storageName = window.sessionStorage.getItem('userName');
  const [loggedUser, setUser] = useState(auth);
  const [checkedCollections, setCheckedCollections] = useState([]);
  const [checkedLandmarks, setCheckedLandmarks] = useState([]);
  const [name, setName] = useState('');
  const [filterParams, setFilterParams] = useState({ site_name: null, site_id: null, position: null, barcode: null, run: null, insectID: null, viewUnidentified: true, viewUnverified: false, genus: null, species: null, taggedBy: null, virus: null, secondOpinion: false });
  const [socket, setSocket] = useState(null);
  const [collectionOfCheckedVirus, setCollectionOfCheckedVirus] = useState([]);
  const [collectionOfInsect, setCollectionOfInsect] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [collectionsIdsInWork, setCollectionsIdsInWork] = useState([]);
  const [kindsList, setKindsList] = useState([]);
  const [sitesId, setSitesId] = useState([]);
  const [companyRuns, setCompanyRuns] = useState([]);
  const [siteFromPopUp, setSiteFromPopUp] = useState(null);
  const [siteFromRule, setSiteFromRule] = useState(null);
  const [userMessages, setUserMessages] = useState([]);
  const [userRules, setUserRules] = useState([]);

  function numberMonthToText(month) {
    switch (month) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
    }
  }

  function StringToDate(str) {
    const date = new Date(str);
    return `${numberMonthToText(date.getMonth())}-${String(date.getDate()).padStart(2, '0')}-${date.getFullYear()}`;
  }

  async function onLogOut() {
    setUser(false);
    console.log(socket.id)
    await restRequest('logout', 'post', { socket_id: socket.id, userName: name }, 'auth');
    window.sessionStorage.clear();
  }

  useEffect(() => {
    if (storageName !== null) {
      setName(storageName);
    }
  }, [sessionStorage, storageName])

  useEffect(() => {
    if (socket === null) {
      setSocket(connectSocket());
    }
    socket?.on("userInWork", data => {
      setCollectionsIdsInWork(data.ids);
      window.sessionStorage.setItem('userInWork', JSON.stringify(data.ids));
    });
  }, [socket])

  return (
    <Router>
      <AppBar position='static'>
        <Nav setRefresh={setRefresh} loggedUser={loggedUser} kindsList={kindsList} sitesId={sitesId} companyRuns={companyRuns} name={name} socket={socket} onLogOut={onLogOut} setCollectionOfCheckedVirus={setCollectionOfCheckedVirus} siteFromPopUp={siteFromPopUp} setSiteFromPopUp={setSiteFromPopUp}
          siteFromRule={siteFromRule} setSiteFromRule={setSiteFromRule} userMessages={userMessages} setUserMessages={setUserMessages} userRules={userRules} setUserRules={setUserRules} />
      </AppBar>
      <Switch>
        <Route path="/survey">
          <SurveyPage />
        </Route>
        <PrivateRoute loggedUser={loggedUser} path="/home">
          <HomePage refresh={refresh} setRefresh={setRefresh} socket={socket} checkedCollections={checkedCollections} setCheckedCollections={setCheckedCollections} setFilterParams={setFilterParams} filterParams={filterParams} collectionOfCheckedVirus={collectionOfCheckedVirus}
            setCollectionOfCheckedVirus={setCollectionOfCheckedVirus} collectionOfInsect={collectionOfInsect} setCollectionOfInsect={setCollectionOfInsect} StringToDate={StringToDate} collectionsIdsInWork={collectionsIdsInWork} setCollectionsIdsInWork={setCollectionsIdsInWork}
            setKindsList={setKindsList} setSitesId={setSitesId} setCompanyRuns={setCompanyRuns} setSiteFromPopUp={setSiteFromPopUp} siteFromRule={siteFromRule} setSiteFromRule={setSiteFromRule} setUserMessages={setUserMessages} setUserRules={setUserRules} />
        </PrivateRoute>
        <Route path="/login">
          <div style={{ height: 'calc(100% - 64px)' }}>
            <LoginPage setUser={setUser} socket={socket} />
          </div>
        </Route>
        <PrivateRoute loggedUser={loggedUser} path="/roadPath">
          <RoadPathPage checkedLandmarks={checkedLandmarks} setCheckedLandmarks={setCheckedLandmarks} />
        </PrivateRoute>
        <PrivateRoute loggedUser={loggedUser} path="/createCollection">
          <CreateCollectionPage />
        </PrivateRoute>
        <PrivateRoute loggedUser={loggedUser} path="/editCollection">
          <EditCollectionPage />
        </PrivateRoute>
        <PrivateRoute loggedUser={loggedUser} path="/poolingManagement">
          <PoolingManagementPage />
        </PrivateRoute>
        <PrivateRoute loggedUser={loggedUser} path="/treatment">
          <TreatmentPage />
        </PrivateRoute>
        <PrivateRoute loggedUser={loggedUser} path="/manualUpload">
          <ManualUploadPage />
        </PrivateRoute>
        <PrivateRoute loggedUser={loggedUser} path="/identification">
          <IdentificationPage socket={socket} checkedCollections={checkedCollections} setCheckedCollections={setCheckedCollections} filterParams={filterParams} setFilterParams={setFilterParams} setCollectionOfInsect={setCollectionOfInsect} StringToDate={StringToDate} />
        </PrivateRoute>
        <Route path="/">
          <Redirect to={{ pathname: loggedUser ? "/home" : "/login" }} />
        </Route>
      </Switch>
    </Router>
  );
}

function PrivateRoute({ children, loggedUser }) {
  return (
    <Route
      render={({ location }) =>
        !loggedUser ?
          (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          ) :
          (children)
      }
    />
  );
}