import "date-fns";
import React from "react";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/styles/rsuite-default.css";

export default function DateRange({ className, setStartDate, setEndDate, isDisabled }) {

  const handleDateChange = (dates) => {
    setStartDate(dates[0]);
    setEndDate(dates[1]);
  };

  return (
    <DateRangePicker
      disabled={isDisabled}
      appearance="default"
      placeholder="Pickup Date:"
      onChange={handleDateChange}
      className={className} />
  );
}
