import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import Button from '@material-ui/core/Button';
import { DialogTitle, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import InfoDialogComp from '../DialogComp/InfoDialogComp';
import ComboBoxComp from '../ComboBoxComp/ComboBoxComp';
import comboStyle from '../ComboBoxComp/ComboBoxComp.module.css';

export default function NewRuleDialogComp({ open, handleClose, siteFromPopUp, setSiteFromPopUp, kindsList, sitesId, companyRuns }) {
    const [siteId, setSiteId] = useState(null);
    const [kindName, setKindName] = useState(null);
    const [amount, setAmount] = useState(null);
    const [ruleAddedConfirmation, setRuleAddedConfirmation] = useState(false);
    const [ruleAddedText, setRuleAddedTest] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [runName, setRunName] = useState(null);
    const [runsList, setRunsList] = useState([]);

    function handleCloseDialog() {
        if (ruleAddedText === `Rule created successfully` || ruleAddedText === '') {
            setSiteId(null);
            setSelectedOption(null);
            setKindName(null);
            setRunName(null);
            setAmount(null);
            setSiteFromPopUp(null);
            handleClose();
        }
        setRuleAddedTest('');
        setRuleAddedConfirmation(false)
    }

    async function handleSaveDialog() {
        setLoading(true);
        let sitesOfRun = null;
        const data = {
            userName: sessionStorage.getItem('userName'),
            kindName: kindName,
            ruleName: 'In all site with kind ' + kindName + ' over ' + amount,
            siteId: [],
            amount: amount
        }
        console.log(selectedOption)
        if (selectedOption === 'Site Id') {
            data.ruleName = 'site ' + siteId + ' kind ' + kindName + ' over ' + amount;
            data.siteId = [siteId];
            console.log(selectedOption)
        }
        if (selectedOption === 'Run') {
            data.ruleName = 'run ' + runName + ' kind ' + kindName + ' over ' + amount;
            console.log(selectedOption)
            sitesOfRun = (companyRuns.find(item => item.name === runName)).sites_ids;
            if (sitesOfRun.length > 0) {
                data.siteId = sitesOfRun;
            }
        }

        if (runName !== null && sitesOfRun.length === 0) {
            setRuleAddedTest(`no sites attached to this run`);
            setRuleAddedConfirmation(true)
        }
        else {
            const response = await restRequest('createRule', 'put', data);
            if (response.data === 'alreadyExists') {
                setRuleAddedTest('rule already exists!')
                setRuleAddedConfirmation(true);
            }
            else {
                setRuleAddedTest(`Rule created successfully`);
                setRuleAddedConfirmation(true);
            }
        }
        setLoading(false);
    }

    useEffect(() => {
        if (companyRuns.length > 0) {
            setRunsList(companyRuns.map(run => run.name));
        }
        if (siteFromPopUp !== null) {
            setSelectedOption('Site Id');
            setSiteId(siteFromPopUp);
        }
    }, [companyRuns])

    useEffect(() => {
        if (siteFromPopUp !== null) {
            setSelectedOption('Site Id');
            setSiteId(siteFromPopUp);
        }
    }, [siteFromPopUp])

    return (
        <div>
            <Dialog open={open || siteFromPopUp} onClose={handleCloseDialog}>
                <DialogTitle>Add New Rule</DialogTitle>
                <DialogContent>
                    <ComboBoxComp name={"Select Site/Run:"} paramsList={['Site Id', 'Run', 'All']} value={selectedOption} setValue={setSelectedOption} />
                </DialogContent>
                {selectedOption === 'Site Id' && (
                    <DialogContent>
                        <ComboBoxComp name={"Site Id:"} paramsList={sitesId} value={siteId} setValue={setSiteId} isDisabled={sitesId.length === 0} />
                    </DialogContent>
                )}
                {selectedOption === 'Run' && (
                    <DialogContent>
                        <ComboBoxComp name={"Run:"} paramsList={runsList} value={runName} setValue={setRunName} isDisabled={runsList.length === 0} />
                    </DialogContent>
                )}
                <DialogContent>
                    <ComboBoxComp name={"Kind:"} paramsList={kindsList} value={kindName} setValue={setKindName} isDisabled={kindsList.length == 0} />
                </DialogContent>
                <DialogContent>
                    <TextFieldComp value={amount} setValue={setAmount} label={"Amount"} type={Number} required={true} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveDialog} color="primary" disabled={kindName === null || amount === null}>
                        Save
                    </Button>
                    {loading &&
                        <CircularProgress size={30} disableShrink />
                    }
                </DialogActions>
            </Dialog>
            <InfoDialogComp message={ruleAddedText} open={ruleAddedConfirmation} handleClose={handleCloseDialog} />
        </div>
    );
}