import React, { useState, useEffect } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import { DialogTitle, CircularProgress } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextFieldComp from '../TextFieldComp/TextFieldComp';
import InfoDialogComp from '../DialogComp/InfoDialogComp';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ButtonComp from '../ButtonComp/ButtonComp';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import comboStyles from '../../components/ComboBoxComp/ComboBoxComp.module.css';
import classes from './NewSiteDialogComp.module.css';


export default function MoveLandmarkDialog({ open, handleClose, checkedLandmarks, prevAreaId }) {
    const [areaSelected, setAreaSelected] = useState(null);
    const [usersNames, setUsersNames] = useState([]);
    const [userSelected, setUserSelected] = useState(null);
    const [daySelected, setDaySelected] = useState(null);
    const [userSpreads, setUserSpreads] = useState([]);
    const [areas, setAreas] = useState([]);
    const [dayList, setDayList] = useState([]);
    const [changeConfirmation, setChangeConfirmation] = useState(false);

    const closeAll = () => {
        handleClose();
        setAreaSelected(null);
        setUsersNames([]);
        setUserSelected(null);
        setDaySelected(null);
        setUserSpreads([]);
        setAreas([]);
        setDayList([]);
    }

    const handleCloseDialog = () => {
        closeAll();
        setChangeConfirmation(false);
    }

    async function getAreas() {
        const response = await restRequest(`getAreas`, 'get', {});
        setAreas(response.data);
    }

    async function getData() {
        const response = await restRequest(`getLandmarks`, 'post', { areaName: areaSelected });
        let user_Names = [...new Set(response.data.userSpreads.map(spread => spread.userName))];
        setUsersNames(user_Names);
        setUserSpreads(response.data.userSpreads);
    }

    async function moveLandmarks() {
        const dateObject = new Date(daySelected);
        let data = {
            checkedLandmarks: checkedLandmarks, prevArea: prevAreaId, area: areaSelected, user: userSelected, date: dateObject.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            },)
        }
        const response = await restRequest(`moveLandmarks`, 'post', data);
        setChangeConfirmation(true);
    }

    function getDates() {
        let dates = (userSpreads.filter(element => element.userName === userSelected)).map(elem => elem.date);
        const formattedDates = dates.map((dateString) => {
            const dateObject = new Date(dateString);
            return dateObject.toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            }) // Adjust the locale as needed
        });
        setDayList(formattedDates);
    }

    useEffect(() => {
        if (areaSelected !== null) {
            getData();
        }
    }, [areaSelected])

    useEffect(() => {
        if (userSelected !== null) {
            getDates();
        }
    }, [userSelected])

    useEffect(() => {
        getAreas();
    }, [])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle>Move clients to new area</DialogTitle>
                <DialogContent className={classes.container}>
                    <ComboBoxComp className={comboStyles.areaCombobox} name={"Area"} paramsList={areas} value={areaSelected} setValue={setAreaSelected} />
                    {usersNames.length > 0 &&
                        <ComboBoxComp className={comboStyles.userDateComboBox} name={"users"} paramsList={usersNames} value={userSelected} setValue={setUserSelected} />}
                    {userSelected !== null && dayList.length > 0 &&
                        <ComboBoxComp className={comboStyles.userDateComboBox} name={"Date"} paramsList={dayList} value={daySelected} setValue={setDaySelected} />}
                </DialogContent>
                <DialogActions>
                    <ButtonComp name={'Save'} action={moveLandmarks} isDisabled={daySelected === null || areaSelected === null || userSelected === null} />
                </DialogActions>
            </Dialog >
            <InfoDialogComp message={'Area changed successfully '} open={changeConfirmation} handleClose={handleCloseDialog} />
        </div >
    );
}