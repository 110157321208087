import React, { useEffect, useState } from 'react';
import { restRequest } from '../../serverAPI/restRequests';
import ComboBoxComp from '../../components/ComboBoxComp/ComboBoxComp';
import SliderComp from '../../components/SliderComp/SliderComp';
import DateRangeComp from '../../components/DateRangeComp/DateRangeComp'
import ButtonComp from '../../components/ButtonComp/ButtonComp';
import CheckBoxComp from '../../components/CheckBoxComp/CheckBoxComp';
import SwitchComp from '../../components/SwitchComp/SwitchComp';
import comboStyles from '../ComboBoxComp/ComboBoxComp.module.css';
import checkStyles from '../CheckBoxComp/CheckBoxComp.module.css';
import buttonStyles from '../ButtonComp/ButtonComp.module.css';
import dateStyles from '../DateRangeComp/DateRangeComp.module.css';
import switchStyles from '../SwitchComp/SwitchComp.module.css';
import classes from '../FilterComp/FilterComp.module.css';

export default function FilterComp({ refresh, setRefresh, center, setZoomInFilteredCollections, collections, runs, setFilteredCollections, filterParams, setFilterParams, managementMode, doneLoadingCollections, sitesNamesList, setSitesNamesList, runsList, setRunsList, collectionsWithEmptySites, setNoSitesPopUp }) {
    const [placementStartDate, setPlacementStartDate] = useState(undefined);
    const [placementEndDate, setPlacementEndDate] = useState(undefined);
    const [siteName, setSiteName] = useState(null);
    const [siteId, setSiteId] = useState(null);
    const [genus, setGenus] = useState(null);
    const [species, setSpecies] = useState(null);
    const [sitePosition, setSitePosition] = useState(null);
    const [virus, setVirus] = useState(null);
    const [barcode, setBarcode] = useState(null);
    const [run, setRun] = useState(null);
    const [user, setUser] = useState(null);
    const [insect_id, setInsect_id] = useState(null);
    const [days, setDays] = useState(0);

    const [sitesIdList, setSitesIdList] = useState([]);
    const [genusesList, setGenusList] = useState([]);
    const [speciesList, setSpeciesList] = useState([]);
    const [barcodesList, setBarcodesList] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [insects_idsList, setInsects_idsList] = useState([]);
    const [secondOpinion, setSecondOpinion] = useState(false);
    const [viewUnverified, setViewUnverified] = useState(false);
    const [switchIdentified, setSwitchIdentified] = useState("View unidentified");
    const [isRadiusClicked, setIsRadiusClicked] = useState(false);
    const virusesList = ["WNV", "DNV", "RVF", "CHIKV", "all"];
    const positionsList = ["CO2", "MMT", "Gravid", "NJLT"];

    // return true if the collection's insects ids contains the chosen insect id in the filter params 
    async function hasInsect(collections) {
        if (insect_id === null) {
            return collections.map(collection => collection._id);
        }
        else {
            const response = await restRequest('insectAtCollection', 'post', { collections_ids: collections.map(col => col._id), insect_id: insect_id });
            return response.data;
        }
    }

    // return true if the collection's taggers contains the chosen tagger username in the filter params
    async function hasTagged(collections) {
        if (user === null) {
            return collections.map(collection => collection._id);
        }
        else {
            const response = await restRequest('userIdentifiedCollection', 'post', { collections_ids: collections.map(col => col._id), user: user });
            return response.data;
        }
    }

    const handleButtonClick = async () => {
        const haveCollections = await filterMapAndTable();
        if (haveCollections) {
            setZoomInFilteredCollections(true);
        }
        else { setNoSitesPopUp(true) }
    };

    async function filterMapAndTable() {
        if (placementEndDate === undefined)
            var deleteEndDate = null;
        else {
            var deleteEndDate = new Date(placementEndDate);
            deleteEndDate.setHours(23);
            deleteEndDate.setMinutes(59);
            deleteEndDate.setSeconds(59);
        }

        let hasInsectCollections = hasInsect(collections);
        let hasTagCollection = hasTagged(collections);

        // asynchronus filter function
        const asyncFilter = async (arr, predicate) => {
            const results = await Promise.all(arr.map(predicate));
            return arr.filter((_v, index) => results[index]);
        }
        let filteredCollections;
        if (managementMode) {
            filteredCollections = await asyncFilter(collectionsWithEmptySites, async (collection) =>
                (siteName === null || collection.site_name === siteName) &&
                (siteId === null || (collection.mapVision_id ? collection.mapVision_id === siteId : collection.site_id === siteId)) &&
                (run === null || checkRun(collection))
            );
            setFilteredCollections(filteredCollections);
        }
        else {
            filteredCollections = await asyncFilter(collections, async (collection) =>
                (siteName === null || collection.site_name === siteName) &&
                (siteId === null || (collection.mapVision_id ? collection.mapVision_id === siteId : collection.site_id === siteId)) &&
                (genus === null || collection.genuses.includes(genus)) &&
                (species === null || collection.species.includes(species)) &&
                (sitePosition === null || collection.site_type === sitePosition) &&
                (virus === null || checkVirus(collection)) &&
                (barcode === null || collection._id === barcode) &&
                (run == null || checkRun(collection)) &&
                (!secondOpinion || collection.second_opinion) &&
                ((placementStartDate === undefined && placementEndDate === undefined) || (new Date(collection.placement_date) <= deleteEndDate && new Date(collection.placement_date) >= placementStartDate)) &&
                ((await hasInsectCollections).includes(collection._id)) &&
                ((await hasTagCollection).includes(collection._id))
            );
            setFilteredCollections(filteredCollections);
        }
        if (filteredCollections.length > 0) {
            return true;
        }
        else return false;
    }

    function computeDistance(collection, center) {
        const newLatLng = new window.google.maps.LatLng(collection.site_lat, collection.site_lng);
        if (center.contains(newLatLng)) {
            return true
        }
        else return false;
    }

    async function filterByRadius() {
        // asynchronus filter function
        const asyncFilter = async (arr, predicate) => {
            const results = await Promise.all(arr.map(predicate));
            return arr.filter((_v, index) => results[index]);
        }
        let filteredCollections;
        if (managementMode) {
            filteredCollections = await asyncFilter(collectionsWithEmptySites, async (collection) =>
                computeDistance(collection, center)
            );
            if (filteredCollections.length > 0) {
                setFilteredCollections(filteredCollections);
            }
        }
        else {
            filteredCollections = await asyncFilter(collections, async (collection) =>
                computeDistance(collection, center)
            );
            if (filteredCollections.length > 0) {
                setFilteredCollections(filteredCollections);
            }
        }
    }


    function checkVirus(collection) {
        if (virus === 'all') {
            return collection.viruses.length > 0;
        }
        else {
            return collection.viruses.includes(virus);
        }
    }

    function checkRun(collection) {
        let collectionRun = runs.find(element => element.name == run);
        return collectionRun.sites_ids.includes(collection.site_id);
    }

    function handleSecondOpinion(event) {
        setSecondOpinion(event.target.checked);
    }

    function handleViewUnverified(event) {
        setViewUnverified(event.target.checked);
    }

    function checkEmptyFilters() {
        if (filterParams.site_name === null && filterParams.site_id === null && filterParams.position === null && filterParams.run === null
            && filterParams.barcode === null && filterParams.insectID === null && filterParams.virus === null
            && filterParams.viewUnidentified === true && filterParams.viewUnverified === false && filterParams.genus === null
            && filterParams.species === null && filterParams.taggedBy === null && filterParams.secondOpinion === false) {
            return true;
        }
        return false;
    };

    async function HomePDropdownValues() {
        let checkIfEmpty = checkEmptyFilters();
        if (!window.sessionStorage.getItem('filtersBasicValues') || !checkIfEmpty || refresh == true) {
            const pageSize = 10;
            const response = await restRequest('HomePDropdownValues', 'post', { filterParams: filterParams, page: 1, pageSize: pageSize });
            let totalSitesNames = response.data.sitesNames,
                totalGenusList = response.data.interest_genus,
                totalSitesId = response.data.sitesIds,
                totalSpeciesList = response.data.interest_species,
                totalBarcodeList = response.data.collectionsIds,
                totalUsersNames = response.data.usersNames,
                totalInsectIds = response.data.insectsIds,
                totalRunsList = response.data.runsName;
            setSitesNamesList(totalSitesNames);
            setSitesIdList(totalSitesId);
            setGenusList(totalGenusList);
            setSpeciesList(totalSpeciesList);
            setBarcodesList(totalBarcodeList);
            setUsersList(totalUsersNames);
            setInsects_idsList(totalInsectIds);
            setRunsList(totalRunsList);
            let maxData = Math.max(totalSitesNames, totalSitesId, totalGenusList, totalSpeciesList, totalBarcodeList, totalUsersNames, totalInsectIds, totalRunsList);
            const totalPages = Math.round(maxData / pageSize) + 1;

            for (let i = 2; i <= totalPages; i++) {
                const pageResponse = await restRequest('HomePDropdownValues', 'post', { filterParams: filterParams, page: 1, pageSize: pageSize });
                setSitesNamesList((totalSitesNames.push(...pageResponse.data.sitesNames)));
                setSitesIdList((totalSitesId.push(...pageResponse.data.sitesIds)));
                setGenusList((totalGenusList.push(...pageResponse.data.interest_genus)));
                setSpeciesList((totalSpeciesList.push(...pageResponse.data.interest_species)));
                setBarcodesList((totalBarcodeList.push(...pageResponse.data.collectionsIds)));
                setUsersList((totalUsersNames.push(...pageResponse.data.usersNames)));
                setInsects_idsList((totalInsectIds.push(...pageResponse.data.insectsIds)));
                setRunsList((totalRunsList.push(...pageResponse.data.runsName)));
            }
            if (checkIfEmpty) {
                const filtersBasicValues = {
                    'sitesNames': totalSitesNames, 'sitesNames': totalSitesNames, 'sitesIds': totalSitesId, 'interest_genus': totalGenusList,
                    'interest_species': totalSpeciesList, 'collectionsIds': totalBarcodeList, 'usersNames': totalUsersNames, 'insectsIds': totalInsectIds, 'runsName': totalRunsList
                }
                window.sessionStorage.setItem('filtersBasicValues', JSON.stringify(filtersBasicValues));
            }
        }
        else {
            let filtersBasicValues = JSON.parse(window.sessionStorage.getItem('filtersBasicValues'));
            setSitesNamesList(filtersBasicValues.sitesNames);
            setSitesIdList(filtersBasicValues.sitesIds);
            setGenusList(filtersBasicValues.interest_genus);
            setSpeciesList(filtersBasicValues.interest_species);
            setBarcodesList(filtersBasicValues.collectionsIds);
            setUsersList(filtersBasicValues.usersNames);
            setInsects_idsList(filtersBasicValues.insectsIds);
            setRunsList(filtersBasicValues.runsName);
        }
        setRefresh(false);
    }
    useEffect(() => {
        if (managementMode == true) {
            filterMapAndTable();
        }
    }, [managementMode])

    useEffect(() => {
        if (refresh == true)
            HomePDropdownValues();
    }, [refresh])

    useEffect(() => {
        console.log(filterParams);
        setFilterParams({ project_name: filterParams.project_name, run: filterParams.run, site_name: filterParams.site_name, virus: filterParams.virus, site_id: filterParams.site_id, position: filterParams.position, barcode: filterParams.barcode, insectID: filterParams.insectID, viewUnidentified: (switchIdentified === "View unidentified"), viewUnverified: filterParams.viewUnverified, genus: filterParams.genus, species: filterParams.species, taggedBy: filterParams.taggedBy, secondOpinion: filterParams.secondOpinion });
    }, [switchIdentified])

    useEffect(() => {
        HomePDropdownValues();
    }, [filterParams]);

    useEffect(() => {
        if (center !== null && !isRadiusClicked) {
            filterByRadius();
            setIsRadiusClicked(true);
        }
        else if (isRadiusClicked) {
            filterMapAndTable();
            setIsRadiusClicked(false);
        }
    }, [center]);

    useEffect(() => {
        setFilterParams({
            site_name: siteName, site_id: siteId, position: sitePosition, run: run, barcode: barcode, insectID: insect_id, virus: virus,
            viewUnidentified: filterParams.viewUnidentified, viewUnverified: viewUnverified, genus: genus, species: species, taggedBy: user, secondOpinion: secondOpinion
        });
    }, [secondOpinion, run, viewUnverified, siteName, siteId, genus, species, sitePosition, virus, barcode, user, insect_id]);

    return (
        <div className={classes.filters}>
            {/* <SliderComp name={"Days:"}  value={days} setValue={setDays}></SliderComp> */}
            <DateRangeComp className={dateStyles.dateRange} setStartDate={setPlacementStartDate} setEndDate={setPlacementEndDate} isDisabled={insect_id !== null || managementMode || !doneLoadingCollections || isRadiusClicked} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Site name:"} paramsList={sitesNamesList} value={siteName} setValue={setSiteName} isDisabled={insect_id !== null || !doneLoadingCollections || isRadiusClicked} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Site id:"} paramsList={sitesIdList} value={siteId} setValue={setSiteId} isDisabled={insect_id !== null || !doneLoadingCollections || isRadiusClicked} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Genus:"} paramsList={genusesList} effected={"species"} setEffectedList={setSpeciesList} value={genus} setValue={setGenus} isDisabled={isRadiusClicked || switchIdentified === "View unidentified" || insect_id !== null || managementMode || !doneLoadingCollections} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Species:"} paramsList={speciesList} effected={"genus"} setEffectedList={setGenusList} value={species} setValue={setSpecies} isDisabled={isRadiusClicked || switchIdentified === "View unidentified" || insect_id !== null || managementMode || !doneLoadingCollections} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Position type:"} paramsList={positionsList} value={sitePosition} setValue={setSitePosition} isDisabled={isRadiusClicked || insect_id !== null || managementMode || !doneLoadingCollections} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Virus:"} paramsList={virusesList} value={virus} setValue={setVirus} isDisabled={isRadiusClicked || managementMode || !doneLoadingCollections} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Runs:"} paramsList={runsList} value={run} setValue={setRun} isDisabled={!doneLoadingCollections || isRadiusClicked} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Barcode:"} paramsList={barcodesList} value={barcode} setValue={setBarcode} isDisabled={insect_id !== null || managementMode || !doneLoadingCollections || isRadiusClicked} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Tagged by:"} paramsList={usersList} value={user} setValue={setUser} isDisabled={switchIdentified === "View unidentified" || insect_id !== null || managementMode || !doneLoadingCollections || isRadiusClicked} />
            <ComboBoxComp className={comboStyles.filterFields} name={"Jump to insect:"} paramsList={insects_idsList} value={insect_id} setValue={setInsect_id} isDisabled={managementMode || !doneLoadingCollections || isRadiusClicked} />
            <CheckBoxComp className={checkStyles.secondOpinionFilter} label={"Second opinion"} checked={secondOpinion} onChange={handleSecondOpinion} isDisabled={switchIdentified === "View identified" || insect_id !== null || managementMode || !doneLoadingCollections || isRadiusClicked} />
            <div className={classes.checkVerifiedComp}>
                <CheckBoxComp className={checkStyles.unverifiedFilter} label={"View unverified"} checked={viewUnverified} onChange={handleViewUnverified} isDisabled={switchIdentified === "View unidentified" || insect_id !== null || managementMode || !doneLoadingCollections || isRadiusClicked} />
            </div>
            <SwitchComp className={switchStyles.identified} label={switchIdentified} setLabel={setSwitchIdentified} primaryLbl={"View unidentified"} secondaryLbl={"View identified"} isDisabled={insect_id !== null || managementMode || !doneLoadingCollections || isRadiusClicked} />
            <ButtonComp className={buttonStyles.filter} name={"Filter"} action={handleButtonClick} isDisabled={!doneLoadingCollections || isRadiusClicked} />
        </div>
    )
}