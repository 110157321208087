import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classes from '../CollectionsMapComp.module.css';
import { IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    tooltip: {fontSize: "1em"}
});

export default function Completed({ selectCompleted, setSelectCompleted, disable }) {
    const toolTipClasses = useStyles();

    return (
        <Tooltip classes={{ tooltip: toolTipClasses.tooltip }} title='Completed' placement='right'>
            <IconButton
                size='medium'
                variant="contained"
                color={selectCompleted ? "primary" : "default"}
                className={classes.completed}
                disabled={disable}
                onClick={() => { setSelectCompleted(!selectCompleted) }}>
                <CheckCircleIcon />
            </IconButton>
        </Tooltip>
    )
}